/* Font copied in place. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf');
}

.font-avenirNextBold {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf');}

/* Font copied in place. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./fonts/Montserrat/Montserrat-Bold.ttf');
}
.font-baskervilleBold {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf');}

/* Font copied in place. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf');
}

.font-SFUITextRegular {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf');}

/* Font copied in place. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf');
}

.font-baskerville {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf');}

/* Font copied in place. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf');
}

.font-avenirNextRegular {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf');}

/* Font copied in place. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf');
}

/* Font Montserrat in place. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf');
}
.font-avenirBook {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-Regular.ttf');}

.App { /* Base font style */
   font-family: 'Montserrat' !important;
   font-size: 1.4rem;
  color: #333333;
  background-color: #ffffff;
}

.App .baseFont * { /* Base font style override for UI framework components */
  font-family: 'Montserrat' !important;
  font-size: 15.1px;
}

.App .appBg {
  background-color: #f6f6f6;
}

.App .cardBg {
  background-color: white;
}

.App .baseTextBg { /* Base text color as background */
  background-color: rgba(0, 0, 0, 0.8500);
}

.App .secondaryTextColor {
  color: rgba(0, 0, 0, 0.5000);
}

.App .secondaryTextBg {
  background-color: rgba(0, 0, 0, 0.5000);
}

.App .primaryTextColor {
  color: #feffff;
}

.App .primaryBg {
  background-color: #feffff;
}

.App .highlightTextColor {
  color: #424242;
}

.App .highlightBg {
  background-color: #424242;
}

@media (display-mode: standalone) {
  .App {
    user-select: none;
  }
}

/* Styles to customize the UI framework plugin (MUI, https://muicss.com) */
.mui-btn {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.mui-btn--primary {
    background-color: #feffff;
}
.mui-btn--primary:active, .mui-btn--primary:focus, .mui-btn--primary:hover {
    background-color: #feffff;
}
.mui-btn--accent {
    background-color: #424242;
}
.mui-btn--accent:active, .mui-btn--accent:focus, .mui-btn--accent:hover {
    background-color: #555555;
}
.mui-textfield {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-checkbox, .mui-radio {  /* Reset UI framework styling that affects layout */
    margin-top: 0;
    margin-bottom: 0;
}
.mui-select {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-btn+.mui-btn {  /* Reset UI framework styling that affects layout */
    margin-left: 0;
}
.mui-btn--fab {  /* Reset UI framework styling that breaks compositing */
    z-index: auto;
}

@keyframes fadeIn {
    0%   { opacity: 0.0; }
    100% { opacity: 1.0; }
}
@keyframes slideIn_right {
    0%   { transform: translateX(100%); }
    100% { transform: translateX(0); }
}
@keyframes slideIn_bottom {
    0%   { transform: translateY(100%); }
    100% { transform: translateY(0); }
}

/* System font classes */

.App .systemFontRegular {
  font-family: 'Montserrat' !important;
}
.App .systemFontBold {
  font-family: 'Montserrat' !important;
  font-weight: 600;
}
.App .systemFontItalic {
  font-family: 'Montserrat' !important;
  font-style: italic;
}
.App .systemFontBoldItalic {
  font-family: 'Montserrat' !important;
  font-weight: 600;
  font-style: italic;
}

.App .actionFont {
  font-family: 'Montserrat' !important;
  font-size: 14.2px;
  font-weight: 400;
}
.App .actionFont * {
  font-size: 14.2px;
}
.App .headlineFont {
  font-family: 'Montserrat' !important;
  font-size: 19.4px;
  font-weight: 400;
}
.App .headlineFont * {
  font-size: 19.4px;
}
.App .navBar {
  background-color: #feffff;
  color: #feffff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  min-height: 50px;
  font-family: 'Montserrat' !important;
  font-size: 18px;
}

.App .navBar a {
  color: #feffff;
}

.App .navBar .title {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 4px;
}

.App .navBar .backBtn {
  position: absolute;
  padding: 4px;
  margin-left: 14px;
  cursor: pointer;
}

.App .navBar > div {
  margin-top: 7px;
}

@media (min-width: 568px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 768px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 1024px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 320px) {
  .mui-container {
    max-width: 320px;
  }
}
@media (min-width: 568px) {
  .mui-container {
    max-width: 568px;
  }
}
@media (min-width: 768px) {
  .mui-container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .mui-container {
    max-width: 1024px;
  }
}

.AppScreen {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.App .background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App .layoutFlow {

}
.App .layoutFlow:before {  /* Prevent margin collapse */
  content: "";
  display: table;
  clear: both;
}
.App .layoutFlow .flowRow {
  position: relative;
  width: 100%;
}

.App .screenFgContainer {
  /* Items in the foreground group within a screen are placed with fixed positioning */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.App .foreground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App ul {
  margin: 0;
  padding: 0;
}
.App li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}
.App .hasNestedComps {
  position: relative;
}
.App .hasNestedComps:after {
  content: "";
  display: table;
  clear: both;
}
.App .hasNestedComps .background {
  position: absolute;
}
.App .hasNestedComps .layoutFlow {
  position: relative;
}
.App .bottomAlignedContent {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App .blockInputOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  z-index: 99999999;
}

.App .marker {
  width: 0px;
  height: 0px;
}

.App .drawer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.App .drawerOpen {
  transform: translateX(0%);
  transition: 0.3s;
}

.App .drawerClosedLeft {
  transform: translateX(-100%);
  transition: 0.3s;
}

.App .drawerClosedRight {
  transform: translateX(100%);
  transition: 0.3s;
}

.App .drawerContent {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

.App .drawerContent > * {
  height: 100%;
}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .UpdateInfoScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .UpdateInfoScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elDescHeadingText {
    overflow: hidden;
    position: relative;
    margin-top: 80px;
    width: 282px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elDescHeadingText > * {
  width: 282px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elDescHeadingText {
    overflow: hidden;
    position: relative;
    margin-top: 80px;
    width: 282px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elDescHeadingText > * {
  width: 282px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elDescHeadingText {
    overflow: hidden;
    position: relative;
    margin-top: 80px;
    width: 280px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elDescHeadingText > * {
  width: 280px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elDescHeadingText {
    overflow: hidden;
    position: relative;
    margin-top: 80px;
    width: 280px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elDescHeadingText > * {
  width: 280px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elDescText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 342px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elDescText > * {
  width: 342px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elDescText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 342px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elDescText > * {
  width: 342px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elDescText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 340px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elDescText > * {
  width: 340px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elDescText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 340px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elDescText > * {
  width: 340px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elNameText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elNameText > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elNameText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elNameText > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elNameText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 236px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elNameText > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elNameText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 236px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elNameText > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elCommunityName {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    margin-left: 7.0%;
    margin-right: 11px;
    width: calc(93.0% - 11px);
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityName > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityName {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    margin-left: 5.1%;
    margin-right: 11px;
    width: calc(94.9% - 11px);
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityName > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityName {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    margin-left: 8.5%;
    margin-right: 11px;
    width: calc(91.5% - 11px);
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityName > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityName {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 6.6%;
    margin-right: 15px;
    width: calc(93.4% - 15px);
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityName > * {
    width: 100%;
  }

}

.UpdateInfoScreen .elButtonAddPhotos button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.UpdateInfoScreen .elButtonAddPhotos button:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elButtonAddPhotos {
    position: relative;
    margin-top: 70px;
    width: 100%;
  }
  .UpdateInfoScreen > .layoutFlow > .elButtonAddPhotos > * {
  margin-left: auto;
  width: 145px;
  margin-right: 11px;
  height: 35px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elButtonAddPhotos {
    position: relative;
    margin-top: 70px;
    width: 100%;
  }
  .UpdateInfoScreen > .layoutFlow > .elButtonAddPhotos > * {
  margin-left: auto;
  width: 145px;
  margin-right: 11px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elButtonAddPhotos {
    position: relative;
    margin-top: 70px;
    width: 100%;
  }
  .UpdateInfoScreen > .layoutFlow > .elButtonAddPhotos > * {
  margin-left: auto;
  width: 144px;
  margin-right: 11px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elButtonAddPhotos {
    position: relative;
    margin-top: 70px;
    width: 100%;
  }
  .UpdateInfoScreen > .layoutFlow > .elButtonAddPhotos > * {
  margin-left: auto;
  width: 144px;
  margin-right: 85px;
  height: 35px;
  }

}

.UpdateInfoScreen .elCompPhotoUpload {
  overflow: hidden;
}

.UpdateInfoScreen .elCompPhotoUpload.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elCompPhotoUpload {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCompPhotoUpload > * {
  margin-left: auto;
  width: 352px;
  margin-right: 11px;
  }

  .UpdateInfoScreen > .layoutFlow > .elCompPhotoUpload > .background > .containerMinHeight {
    min-height: 100px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elCompPhotoUpload {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCompPhotoUpload > * {
  margin-left: auto;
  width: 352px;
  margin-right: 11px;
  }

  .UpdateInfoScreen > .layoutFlow > .elCompPhotoUpload > .background > .containerMinHeight {
    min-height: 100px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elCompPhotoUpload {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCompPhotoUpload > * {
  margin-left: auto;
  width: 350px;
  margin-right: 11px;
  }

  .UpdateInfoScreen > .layoutFlow > .elCompPhotoUpload > .background > .containerMinHeight {
    min-height: 100px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elCompPhotoUpload {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCompPhotoUpload > * {
  margin-left: auto;
  width: 350px;
  margin-right: 75px;
  }

  .UpdateInfoScreen > .layoutFlow > .elCompPhotoUpload > .background > .containerMinHeight {
    min-height: 100px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elAddressText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elAddressText > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elAddressText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elAddressText > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elAddressText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elAddressText > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elAddressText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elAddressText > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elCommunityAddress {
    position: relative;
    margin-top: 0px;
    width: 350px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityAddress > * {
  width: 350px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityAddress {
    position: relative;
    margin-top: 0px;
    width: 350px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityAddress > * {
  width: 350px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityAddress {
    position: relative;
    margin-top: 0px;
    width: 348px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityAddress > * {
  width: 348px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityAddress {
    position: relative;
    margin-top: 0px;
    width: 348px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityAddress > * {
  width: 348px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elCommAddressInput {
    position: relative;
    margin-top: 0px;
    width: 226px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommAddressInput > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elCommAddressInput {
    position: relative;
    margin-top: 0px;
    width: 226px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommAddressInput > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elCommAddressInput {
    position: relative;
    margin-top: 0px;
    width: 225px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommAddressInput > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elCommAddressInput {
    position: relative;
    margin-top: 0px;
    width: 225px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommAddressInput > * {
  width: 225px;
  height: 34px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elCityText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCityText > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elCityText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCityText > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elCityText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCityText > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elCityText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCityText > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elCommunityCity {
    position: relative;
    margin-top: 1px;
    width: 350px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityCity > * {
  width: 350px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityCity {
    position: relative;
    margin-top: 1px;
    width: 350px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityCity > * {
  width: 350px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityCity {
    position: relative;
    margin-top: 1px;
    width: 348px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityCity > * {
  width: 348px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityCity {
    position: relative;
    margin-top: 1px;
    width: 348px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityCity > * {
  width: 348px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elCommCityInput {
    position: relative;
    margin-top: 0px;
    width: 226px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommCityInput > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elCommCityInput {
    position: relative;
    margin-top: 0px;
    width: 226px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommCityInput > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elCommCityInput {
    position: relative;
    margin-top: 0px;
    width: 225px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommCityInput > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elCommCityInput {
    position: relative;
    margin-top: 0px;
    width: 225px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommCityInput > * {
  width: 225px;
  height: 34px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elStateText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elStateText > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elStateText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elStateText > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elStateText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elStateText > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elStateText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elStateText > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elCommunityState {
    position: relative;
    margin-top: 1px;
    width: 350px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityState > * {
  width: 350px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityState {
    position: relative;
    margin-top: 1px;
    width: 350px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityState > * {
  width: 350px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityState {
    position: relative;
    margin-top: 1px;
    width: 348px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityState > * {
  width: 348px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityState {
    position: relative;
    margin-top: 1px;
    width: 348px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityState > * {
  width: 348px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elCommStateInput {
    position: relative;
    margin-top: 0px;
    width: 226px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommStateInput > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elCommStateInput {
    position: relative;
    margin-top: 0px;
    width: 226px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommStateInput > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elCommStateInput {
    position: relative;
    margin-top: 0px;
    width: 225px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommStateInput > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elCommStateInput {
    position: relative;
    margin-top: 0px;
    width: 225px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommStateInput > * {
  width: 225px;
  height: 34px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elZipText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elZipText > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elZipText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elZipText > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elZipText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elZipText > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elZipText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elZipText > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elCommunityZip {
    position: relative;
    margin-top: 1px;
    width: 350px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityZip > * {
  width: 350px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityZip {
    position: relative;
    margin-top: 1px;
    width: 350px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityZip > * {
  width: 350px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityZip {
    position: relative;
    margin-top: 1px;
    width: 348px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityZip > * {
  width: 348px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityZip {
    position: relative;
    margin-top: 1px;
    width: 348px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityZip > * {
  width: 348px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elCommZipInput {
    position: relative;
    margin-top: 0px;
    width: 226px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommZipInput > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elCommZipInput {
    position: relative;
    margin-top: 0px;
    width: 226px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommZipInput > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elCommZipInput {
    position: relative;
    margin-top: 0px;
    width: 225px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommZipInput > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elCommZipInput {
    position: relative;
    margin-top: 0px;
    width: 225px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommZipInput > * {
  width: 225px;
  height: 34px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elPhoneText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elPhoneText > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elPhoneText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elPhoneText > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elPhoneText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elPhoneText > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elPhoneText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elPhoneText > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elCommunityPhone {
    position: relative;
    margin-top: 1px;
    width: 350px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityPhone > * {
  width: 350px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityPhone {
    position: relative;
    margin-top: 1px;
    width: 350px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityPhone > * {
  width: 350px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityPhone {
    position: relative;
    margin-top: 1px;
    width: 348px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityPhone > * {
  width: 348px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityPhone {
    position: relative;
    margin-top: 1px;
    width: 348px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityPhone > * {
  width: 348px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elCommPhoneInput {
    position: relative;
    margin-top: 0px;
    width: 226px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommPhoneInput > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elCommPhoneInput {
    position: relative;
    margin-top: 0px;
    width: 226px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommPhoneInput > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elCommPhoneInput {
    position: relative;
    margin-top: 0px;
    width: 225px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommPhoneInput > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elCommPhoneInput {
    position: relative;
    margin-top: 0px;
    width: 225px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommPhoneInput > * {
  width: 225px;
  height: 34px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elWebsiteText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elWebsiteText > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elWebsiteText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elWebsiteText > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elWebsiteText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elWebsiteText > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elWebsiteText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elWebsiteText > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elCommunityWeb {
    position: relative;
    margin-top: 1px;
    width: 350px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityWeb > * {
  width: 350px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityWeb {
    position: relative;
    margin-top: 1px;
    width: 350px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityWeb > * {
  width: 350px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityWeb {
    position: relative;
    margin-top: 1px;
    width: 348px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityWeb > * {
  width: 348px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityWeb {
    position: relative;
    margin-top: 1px;
    width: 348px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityWeb > * {
  width: 348px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elCommWebInput {
    position: relative;
    margin-top: 0px;
    width: 226px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommWebInput > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elCommWebInput {
    position: relative;
    margin-top: 0px;
    width: 226px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommWebInput > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elCommWebInput {
    position: relative;
    margin-top: 0px;
    width: 225px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommWebInput > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elCommWebInput {
    position: relative;
    margin-top: 0px;
    width: 225px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommWebInput > * {
  width: 225px;
  height: 34px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elTextBasicInfo {
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elTextBasicInfo > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elTextBasicInfo {
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elTextBasicInfo > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elTextBasicInfo {
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elTextBasicInfo > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elTextBasicInfo {
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elTextBasicInfo > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elTextLicenseTypes {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elTextLicenseTypes > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elTextLicenseTypes {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elTextLicenseTypes > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elTextLicenseTypes {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elTextLicenseTypes > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elTextLicenseTypes {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 149px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elTextLicenseTypes > * {
  width: 149px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elTextLicensedBeds {
    overflow: hidden;
    position: relative;
    margin-top: -216px;
    width: 238px;
    margin-left: 230px;
  }
  .UpdateInfoScreen > .layoutFlow > .elTextLicensedBeds > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elTextLicensedBeds {
    overflow: hidden;
    position: relative;
    margin-top: -216px;
    width: 238px;
    margin-left: 262px;
  }
  .UpdateInfoScreen > .layoutFlow > .elTextLicensedBeds > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elTextLicensedBeds {
    overflow: hidden;
    position: relative;
    margin-top: -216px;
    width: 236px;
    margin-left: 260px;
  }
  .UpdateInfoScreen > .layoutFlow > .elTextLicensedBeds > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elTextLicensedBeds {
    overflow: hidden;
    position: relative;
    margin-top: -216px;
    width: 119px;
    margin-left: 260px;
  }
  .UpdateInfoScreen > .layoutFlow > .elTextLicensedBeds > * {
  width: 119px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elIndeLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 44px;
    width: 44px;
    margin-left: 292px;
  }
  .UpdateInfoScreen > .layoutFlow > .elIndeLivingBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elIndeLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 44px;
    width: 44px;
    margin-left: 292px;
  }
  .UpdateInfoScreen > .layoutFlow > .elIndeLivingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elIndeLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 44px;
    width: 44px;
    margin-left: 290px;
  }
  .UpdateInfoScreen > .layoutFlow > .elIndeLivingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elIndeLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 44px;
    width: 44px;
    margin-left: 290px;
  }
  .UpdateInfoScreen > .layoutFlow > .elIndeLivingBedCount > * {
  width: 44px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elAssistedLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .UpdateInfoScreen > .layoutFlow > .elAssistedLivingBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elAssistedLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .UpdateInfoScreen > .layoutFlow > .elAssistedLivingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elAssistedLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .UpdateInfoScreen > .layoutFlow > .elAssistedLivingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elAssistedLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .UpdateInfoScreen > .layoutFlow > .elAssistedLivingBedCount > * {
  width: 44px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elMemoryCareBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .UpdateInfoScreen > .layoutFlow > .elMemoryCareBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elMemoryCareBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .UpdateInfoScreen > .layoutFlow > .elMemoryCareBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elMemoryCareBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .UpdateInfoScreen > .layoutFlow > .elMemoryCareBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elMemoryCareBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .UpdateInfoScreen > .layoutFlow > .elMemoryCareBedCount > * {
  width: 44px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elSkilledNursingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .UpdateInfoScreen > .layoutFlow > .elSkilledNursingBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elSkilledNursingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .UpdateInfoScreen > .layoutFlow > .elSkilledNursingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elSkilledNursingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .UpdateInfoScreen > .layoutFlow > .elSkilledNursingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elSkilledNursingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .UpdateInfoScreen > .layoutFlow > .elSkilledNursingBedCount > * {
  width: 44px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elOtherBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .UpdateInfoScreen > .layoutFlow > .elOtherBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elOtherBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .UpdateInfoScreen > .layoutFlow > .elOtherBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elOtherBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .UpdateInfoScreen > .layoutFlow > .elOtherBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elOtherBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .UpdateInfoScreen > .layoutFlow > .elOtherBedCount > * {
  width: 44px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elLine1 {
    position: relative;
    margin-top: 10px;
    width: 297px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elLine1 > * {
  width: 297px;
  height: 2px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elLine1 {
    position: relative;
    margin-top: 10px;
    width: 297px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elLine1 > * {
  width: 297px;
  height: 2px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elLine1 {
    position: relative;
    margin-top: 10px;
    width: 295px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elLine1 > * {
  width: 295px;
  height: 2px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elLine1 {
    position: relative;
    margin-top: 10px;
    width: 295px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elLine1 > * {
  width: 295px;
  height: 2px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elTextTotalBed {
    overflow: hidden;
    position: relative;
    margin-top: 17px;
    width: 199px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elTextTotalBed > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elTextTotalBed {
    overflow: hidden;
    position: relative;
    margin-top: 17px;
    width: 199px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elTextTotalBed > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elTextTotalBed {
    overflow: hidden;
    position: relative;
    margin-top: 17px;
    width: 197px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elTextTotalBed > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elTextTotalBed {
    overflow: hidden;
    position: relative;
    margin-top: 17px;
    width: 197px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elTextTotalBed > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elTotalBedCount {
    overflow: hidden;
    position: relative;
    margin-top: -24px;
    width: 44px;
    margin-left: 292px;
  }
  .UpdateInfoScreen > .layoutFlow > .elTotalBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elTotalBedCount {
    overflow: hidden;
    position: relative;
    margin-top: -24px;
    width: 44px;
    margin-left: 292px;
  }
  .UpdateInfoScreen > .layoutFlow > .elTotalBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elTotalBedCount {
    overflow: hidden;
    position: relative;
    margin-top: -24px;
    width: 44px;
    margin-left: 290px;
  }
  .UpdateInfoScreen > .layoutFlow > .elTotalBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elTotalBedCount {
    overflow: hidden;
    position: relative;
    margin-top: -24px;
    width: 44px;
    margin-left: 290px;
  }
  .UpdateInfoScreen > .layoutFlow > .elTotalBedCount > * {
  width: 44px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 287px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elValidator > * {
  width: 287px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 287px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elValidator > * {
  width: 287px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 285px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elValidator > * {
  width: 285px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 285px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elValidator > * {
  width: 285px;
  }

}

.UpdateInfoScreen .elButtonSubmitChanges button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.UpdateInfoScreen .elButtonSubmitChanges button:focus {
  box-shadow: 0 0 5px rgba(255, 54, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elButtonSubmitChanges {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 145px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elButtonSubmitChanges > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elButtonSubmitChanges {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 145px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elButtonSubmitChanges > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elButtonSubmitChanges {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 144px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elButtonSubmitChanges > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elButtonSubmitChanges {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 144px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elButtonSubmitChanges > * {
  width: 144px;
  height: 35px;
  }

}

.UpdateInfoScreen .elButtonBack button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.UpdateInfoScreen .elButtonBack button:focus {
  box-shadow: 0 0 5px rgba(56, 83, 128, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elButtonBack {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 145px;
    margin-left: 7.0%;
  }
  .UpdateInfoScreen > .layoutFlow > .elButtonBack > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elButtonBack {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 145px;
    margin-left: 5.1%;
  }
  .UpdateInfoScreen > .layoutFlow > .elButtonBack > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elButtonBack {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 144px;
    margin-left: 8.5%;
  }
  .UpdateInfoScreen > .layoutFlow > .elButtonBack > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elButtonBack {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 144px;
    margin-left: 6.6%;
  }
  .UpdateInfoScreen > .layoutFlow > .elButtonBack > * {
  width: 144px;
  height: 35px;
  }

}

@keyframes fadeIn_UpdateInfoScreen_elCommunityCoverImage {
  from { opacity: 0.0; }
  to   { opacity: 1.0; }
}
.UpdateInfoScreen > .layoutFlow > .elCommunityCoverImage {
  animation: 0.25s fadeIn_UpdateInfoScreen_elCommunityCoverImage;
}

/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elCommunityCoverImage {
    position: relative;
    margin-top: 50px;
    width: 100%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityCoverImage > * {
  margin-left: auto;
  width: 475px;
  margin-right: calc(8.0% + 0px);
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityCoverImage {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityCoverImage > * {
  margin-left: auto;
  width: 320px;
  margin-right: calc(8.0% + 0px);
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityCoverImage {
    position: relative;
    margin-top: -280px;
    width: 100%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityCoverImage > * {
  margin-left: auto;
  width: 318px;
  margin-right: calc(8.0% + 0px);
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elCommunityCoverImage {
    position: relative;
    margin-top: -280px;
    width: 100%;
  }
  .UpdateInfoScreen > .layoutFlow > .elCommunityCoverImage > * {
  margin-left: auto;
  width: 475px;
  margin-right: calc(8.0% + 0px);
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 202px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .UpdateInfoScreen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 201px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .UpdateInfoScreen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 200px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .UpdateInfoScreen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 200px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .UpdateInfoScreen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .flowRow > .elField {
    position: absolute;
    margin-top: 0px;
    width: 226px;
    margin-left: 330px;
  }
  .UpdateInfoScreen > .layoutFlow > .flowRow > .elField > * {
  width: 226px;
  height: 34px;
  }

  .flowRow_UpdateInfoScreen_elField_578562240 {
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .flowRow > .elField {
    position: absolute;
    margin-top: 0px;
    width: 226px;
    margin-left: 330px;
  }
  .UpdateInfoScreen > .layoutFlow > .flowRow > .elField > * {
  width: 226px;
  height: 34px;
  }

  .flowRow_UpdateInfoScreen_elField_578562240 {
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .flowRow > .elField {
    position: absolute;
    margin-top: 0px;
    width: 225px;
    margin-left: 328px;
  }
  .UpdateInfoScreen > .layoutFlow > .flowRow > .elField > * {
  width: 225px;
  height: 34px;
  }

  .flowRow_UpdateInfoScreen_elField_578562240 {
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .flowRow > .elField {
    position: absolute;
    margin-top: 0px;
    width: 225px;
    margin-left: 328px;
  }
  .UpdateInfoScreen > .layoutFlow > .flowRow > .elField > * {
  width: 225px;
  height: 34px;
  }

  .flowRow_UpdateInfoScreen_elField_578562240 {
  }

}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
  }
  .UpdateInfoScreen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

@media (min-width: 568px) {
  .UpdateInfoScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
  }
  .UpdateInfoScreen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

}
@media (min-width: 768px) {
  .UpdateInfoScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
  }
  .UpdateInfoScreen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
  }
  .UpdateInfoScreen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

}

.UpdateInfoScreen .elBkgdOutlineOverall {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.UpdateInfoScreen .elBkgdOutlineOverall:focus {
  box-shadow: 0 0 5px rgba(56, 83, 128, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
@media (min-width: 568px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}

.UpdateInfoScreen .elFab {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.UpdateInfoScreen .elFab:focus {
  box-shadow: 0 0 6px rgba(66, 66, 66, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    position: fixed;
    right: 23px;
    bottom: 23px;
    width: 68px;
    height: 68px;
  }
@media (min-width: 568px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    right: 23px;
    bottom: 23px;
    width: 68px;
    height: 68px;
  }
}
@media (min-width: 768px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    right: 22px;
    bottom: 22px;
    width: 68px;
    height: 68px;
  }
}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    right: 22px;
    bottom: 22px;
    width: 68px;
    height: 68px;
  }
}

.UpdateInfoScreen .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.UpdateInfoScreen .elIconButton:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elIconButton {
    position: absolute;
    left: 5px;
    top: 4px;
    width: 41px;
    height: 36px;
  }
@media (min-width: 568px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}
@media (min-width: 768px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elTwittercircle {
    position: fixed;
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elTwitter {
    position: fixed;
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    position: fixed;
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elIg {
    position: fixed;
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    position: fixed;
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elFb {
    position: fixed;
    right: 101px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elFb {
    right: 101px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elFb {
    right: 100px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elFb {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}

.UpdateInfoScreen .elButtonLearnMore {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.UpdateInfoScreen .elButtonLearnMore:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    position: fixed;
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}

.UpdateInfoScreen .elButtonAboutUs {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.UpdateInfoScreen .elButtonAboutUs:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    position: fixed;
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elLogo {
    position: absolute;
    left: 189px;
    top: 7px;
    width: 121px;
    height: 32px;
  }
@media (min-width: 568px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elLogo {
    left: 50px;
    top: 7px;
    width: 121px;
    height: 32px;
  }
}
@media (min-width: 768px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elLogo {
    left: 188px;
    top: 7px;
    width: 120px;
    height: 32px;
  }
}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elLogo {
    left: 188px;
    top: 7px;
    width: 120px;
    height: 32px;
  }
}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    position: absolute;
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
@media (min-width: 568px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
}
@media (min-width: 768px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}


/* Narrowest size for this element's responsive layout */
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elBeta {
    position: absolute;
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
@media (min-width: 568px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elBeta {
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 768px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 1024px) {
  .UpdateInfoScreen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .CommunityDetails2Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.CommunityDetails2Screen .elButtonBackTop button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elButtonBackTop button:focus {
  box-shadow: 0 0 5px rgba(56, 83, 128, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elButtonBackTop {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    width: 145px;
    margin-left: 7.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elButtonBackTop > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elButtonBackTop {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    width: 145px;
    margin-left: 5.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elButtonBackTop > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elButtonBackTop {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    width: 144px;
    margin-left: 7.3%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elButtonBackTop > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elButtonBackTop {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    width: 144px;
    margin-left: 6.7%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elButtonBackTop > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elCommunityName {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    margin-left: 7.0%;
    margin-right: 11px;
    width: calc(93.0% - 11px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityName > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elCommunityName {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    margin-left: 5.0%;
    margin-right: 11px;
    width: calc(95.0% - 11px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityName > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elCommunityName {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    margin-left: 7.3%;
    margin-right: 11px;
    width: calc(92.7% - 11px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityName > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elCommunityName {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 6.7%;
    margin-right: 15px;
    width: calc(93.3% - 15px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityName > * {
    width: 100%;
  }

}

.CommunityDetails2Screen .elButtonAddPhotos button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.CommunityDetails2Screen .elButtonAddPhotos button:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elButtonAddPhotos {
    position: relative;
    margin-top: 70px;
    width: 100%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elButtonAddPhotos > * {
  margin-left: auto;
  width: 145px;
  margin-right: 11px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elButtonAddPhotos {
    position: relative;
    margin-top: 70px;
    width: 100%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elButtonAddPhotos > * {
  margin-left: auto;
  width: 145px;
  margin-right: 11px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elButtonAddPhotos {
    position: relative;
    margin-top: 70px;
    width: 100%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elButtonAddPhotos > * {
  margin-left: auto;
  width: 144px;
  margin-right: 11px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elButtonAddPhotos {
    position: relative;
    margin-top: 70px;
    width: 100%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elButtonAddPhotos > * {
  margin-left: auto;
  width: 144px;
  margin-right: 85px;
  height: 35px;
  }

}

.CommunityDetails2Screen .elCompPhotoUpload {
  overflow: hidden;
}

.CommunityDetails2Screen .elCompPhotoUpload.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elCompPhotoUpload {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elCompPhotoUpload > * {
  margin-left: auto;
  width: 352px;
  margin-right: 11px;
  }

  .CommunityDetails2Screen > .layoutFlow > .elCompPhotoUpload > .background > .containerMinHeight {
    min-height: 100px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elCompPhotoUpload {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elCompPhotoUpload > * {
  margin-left: auto;
  width: 352px;
  margin-right: 11px;
  }

  .CommunityDetails2Screen > .layoutFlow > .elCompPhotoUpload > .background > .containerMinHeight {
    min-height: 100px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elCompPhotoUpload {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elCompPhotoUpload > * {
  margin-left: auto;
  width: 350px;
  margin-right: 11px;
  }

  .CommunityDetails2Screen > .layoutFlow > .elCompPhotoUpload > .background > .containerMinHeight {
    min-height: 100px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elCompPhotoUpload {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elCompPhotoUpload > * {
  margin-left: auto;
  width: 350px;
  margin-right: 75px;
  }

  .CommunityDetails2Screen > .layoutFlow > .elCompPhotoUpload > .background > .containerMinHeight {
    min-height: 100px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elImageIL {
    position: relative;
    margin-top: -100px;
    width: 36px;
    margin-left: 7.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elImageIL > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elImageIL {
    position: relative;
    margin-top: -100px;
    width: 36px;
    margin-left: 5.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elImageIL > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elImageIL {
    position: relative;
    margin-top: -100px;
    width: 36px;
    margin-left: 7.3%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elImageIL > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elImageIL {
    position: relative;
    margin-top: -100px;
    width: 36px;
    margin-left: 6.7%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elImageIL > * {
  width: 36px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageAL {
    position: absolute;
    margin-top: -36px;
    width: 36px;
    margin-left: calc(7.0% + 50px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageAL > * {
  width: 36px;
  }

  .flowRow_CommunityDetails2Screen_elImageAL_1199380299 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageAL {
    position: absolute;
    margin-top: -36px;
    width: 36px;
    margin-left: calc(5.0% + 50px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageAL > * {
  width: 36px;
  }

  .flowRow_CommunityDetails2Screen_elImageAL_1199380299 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageAL {
    position: absolute;
    margin-top: -36px;
    width: 36px;
    margin-left: calc(7.3% + 50px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageAL > * {
  width: 36px;
  }

  .flowRow_CommunityDetails2Screen_elImageAL_1199380299 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageAL {
    position: absolute;
    margin-top: -36px;
    width: 36px;
    margin-left: calc(6.7% + 50px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageAL > * {
  width: 36px;
  }

  .flowRow_CommunityDetails2Screen_elImageAL_1199380299 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageMC {
    position: absolute;
    top: -42px;
    width: 36px;
    margin-left: calc(7.0% + 101px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageMC > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageMC {
    position: absolute;
    top: -42px;
    width: 36px;
    margin-left: calc(5.0% + 101px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageMC > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageMC {
    position: absolute;
    top: -41px;
    width: 36px;
    margin-left: calc(7.3% + 100px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageMC > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageMC {
    position: absolute;
    top: -41px;
    width: 36px;
    margin-left: calc(6.7% + 100px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageMC > * {
  width: 36px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageSN {
    position: absolute;
    top: -42px;
    width: 36px;
    margin-left: calc(7.0% + 151px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageSN > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageSN {
    position: absolute;
    top: -42px;
    width: 36px;
    margin-left: calc(5.0% + 151px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageSN > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageSN {
    position: absolute;
    top: -41px;
    width: 36px;
    margin-left: calc(7.3% + 150px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageSN > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageSN {
    position: absolute;
    top: -41px;
    width: 36px;
    margin-left: calc(6.7% + 150px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageSN > * {
  width: 36px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elCommunityAddress {
    position: relative;
    margin-top: 10px;
    margin-left: 7.0%;
    margin-right: 11px;
    width: calc(93.0% - 11px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityAddress > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elCommunityAddress {
    position: relative;
    margin-top: 10px;
    margin-left: 5.0%;
    margin-right: 11px;
    width: calc(95.0% - 11px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityAddress > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elCommunityAddress {
    position: relative;
    margin-top: 10px;
    margin-left: 7.3%;
    margin-right: 11px;
    width: calc(92.7% - 11px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityAddress > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elCommunityAddress {
    position: relative;
    margin-top: 10px;
    margin-left: 6.7%;
    margin-right: 11px;
    width: calc(93.3% - 11px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityAddress > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elCommunityCity {
    position: relative;
    margin-top: 1px;
    width: 238px;
    margin-left: 7.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityCity > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elCommunityCity {
    position: relative;
    margin-top: 1px;
    width: 238px;
    margin-left: 5.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityCity > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elCommunityCity {
    position: relative;
    margin-top: 1px;
    width: 236px;
    margin-left: 7.3%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityCity > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elCommunityCity {
    position: relative;
    margin-top: 1px;
    width: 236px;
    margin-left: 6.7%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityCity > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elCommunityPhone {
    position: relative;
    margin-top: 1px;
    width: 238px;
    margin-left: 7.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityPhone > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elCommunityPhone {
    position: relative;
    margin-top: 1px;
    width: 238px;
    margin-left: 5.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityPhone > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elCommunityPhone {
    position: relative;
    margin-top: 1px;
    width: 236px;
    margin-left: 7.3%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityPhone > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elCommunityPhone {
    position: relative;
    margin-top: 1px;
    width: 236px;
    margin-left: 6.7%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityPhone > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextBasicInfo {
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 7.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextBasicInfo > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextBasicInfo {
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 5.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextBasicInfo > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextBasicInfo {
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 7.3%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextBasicInfo > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextBasicInfo {
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 6.7%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextBasicInfo > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elEditButton {
    overflow: hidden;
    position: absolute;
    margin-top: -26px;
    width: 74px;
    margin-left: 265px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elEditButton > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elEditButton_290220116 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elEditButton {
    overflow: hidden;
    position: absolute;
    margin-top: -26px;
    width: 74px;
    margin-left: 265px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elEditButton > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elEditButton_290220116 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elEditButton {
    overflow: hidden;
    position: absolute;
    margin-top: -26px;
    width: 73px;
    margin-left: 260px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elEditButton > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elEditButton_290220116 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elEditButton {
    overflow: hidden;
    position: absolute;
    margin-top: -26px;
    width: 73px;
    margin-left: 260px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elEditButton > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elEditButton_290220116 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextLicenseTypes {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 7.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextLicenseTypes > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextLicenseTypes {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 5.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextLicenseTypes > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextLicenseTypes {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 7.3%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextLicenseTypes > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextLicenseTypes {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 149px;
    margin-left: 6.7%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextLicenseTypes > * {
  width: 149px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextLicensedBeds {
    overflow: hidden;
    position: relative;
    margin-top: -216px;
    width: 238px;
    margin-left: 262px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextLicensedBeds > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextLicensedBeds {
    overflow: hidden;
    position: relative;
    margin-top: -216px;
    width: 238px;
    margin-left: 262px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextLicensedBeds > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextLicensedBeds {
    overflow: hidden;
    position: relative;
    margin-top: -216px;
    width: 236px;
    margin-left: 260px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextLicensedBeds > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextLicensedBeds {
    overflow: hidden;
    position: relative;
    margin-top: -216px;
    width: 119px;
    margin-left: 260px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextLicensedBeds > * {
  width: 119px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elIndeLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 44px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elIndeLivingBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elIndeLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 44px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elIndeLivingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elIndeLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 44px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elIndeLivingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elIndeLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 44px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elIndeLivingBedCount > * {
  width: 44px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elAssistedLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elAssistedLivingBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elAssistedLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elAssistedLivingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elAssistedLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elAssistedLivingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elAssistedLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elAssistedLivingBedCount > * {
  width: 44px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elMemoryCareBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elMemoryCareBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elMemoryCareBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elMemoryCareBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elMemoryCareBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elMemoryCareBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elMemoryCareBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elMemoryCareBedCount > * {
  width: 44px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elSkilledNursingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elSkilledNursingBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elSkilledNursingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elSkilledNursingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elSkilledNursingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elSkilledNursingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elSkilledNursingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elSkilledNursingBedCount > * {
  width: 44px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elOtherBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elOtherBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elOtherBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elOtherBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elOtherBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elOtherBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elOtherBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elOtherBedCount > * {
  width: 44px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elLine1 {
    position: relative;
    margin-top: 10px;
    width: 297px;
    margin-left: 7.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elLine1 > * {
  width: 297px;
  height: 2px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elLine1 {
    position: relative;
    margin-top: 10px;
    width: 297px;
    margin-left: 5.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elLine1 > * {
  width: 297px;
  height: 2px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elLine1 {
    position: relative;
    margin-top: 10px;
    width: 295px;
    margin-left: 7.3%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elLine1 > * {
  width: 295px;
  height: 2px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elLine1 {
    position: relative;
    margin-top: 10px;
    width: 295px;
    margin-left: 6.7%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elLine1 > * {
  width: 295px;
  height: 2px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextTotalBed {
    overflow: hidden;
    position: relative;
    margin-top: 7px;
    width: 238px;
    margin-left: 7.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextTotalBed > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextTotalBed {
    overflow: hidden;
    position: relative;
    margin-top: 7px;
    width: 238px;
    margin-left: 5.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextTotalBed > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextTotalBed {
    overflow: hidden;
    position: relative;
    margin-top: 7px;
    width: 236px;
    margin-left: 7.3%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextTotalBed > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextTotalBed {
    overflow: hidden;
    position: relative;
    margin-top: 7px;
    width: 197px;
    margin-left: 6.7%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextTotalBed > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTotalBedCount {
    overflow: hidden;
    position: relative;
    margin-top: -24px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTotalBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTotalBedCount {
    overflow: hidden;
    position: relative;
    margin-top: -24px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTotalBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTotalBedCount {
    overflow: hidden;
    position: relative;
    margin-top: -24px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTotalBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTotalBedCount {
    overflow: hidden;
    position: relative;
    margin-top: -24px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTotalBedCount > * {
  width: 44px;
  }

}

@keyframes fadeIn_CommunityDetails2Screen_elCommunityCoverImage {
  from { opacity: 0.0; }
  to   { opacity: 1.0; }
}
.CommunityDetails2Screen > .layoutFlow > .elCommunityCoverImage {
  animation: 0.25s fadeIn_CommunityDetails2Screen_elCommunityCoverImage;
}

/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elCommunityCoverImage {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityCoverImage > * {
  margin-left: auto;
  width: 320px;
  margin-right: calc(8.0% + 0px);
  height: 200px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elCommunityCoverImage {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityCoverImage > * {
  margin-left: auto;
  width: 320px;
  margin-right: calc(8.0% + 0px);
  height: 200px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elCommunityCoverImage {
    position: relative;
    margin-top: 200px;
    width: 100%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityCoverImage > * {
  margin-left: auto;
  width: 318px;
  margin-right: calc(8.0% + 0px);
  height: 199px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elCommunityCoverImage {
    position: relative;
    margin-top: -280px;
    width: 100%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elCommunityCoverImage > * {
  margin-left: auto;
  width: 475px;
  margin-right: calc(8.0% + 0px);
  height: 276px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextPricing {
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 7.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextPricing > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextPricing {
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 5.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextPricing > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextPricing {
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 7.3%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextPricing > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextPricing {
    position: relative;
    margin-top: 50px;
    width: 236px;
    margin-left: 6.7%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextPricing > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextClickInstruction {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 357px;
    margin-left: 7.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextClickInstruction > * {
  width: 357px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextClickInstruction {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 357px;
    margin-left: 5.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextClickInstruction > * {
  width: 357px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextClickInstruction {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 354px;
    margin-left: 7.3%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextClickInstruction > * {
  width: 354px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextClickInstruction {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 354px;
    margin-left: 6.7%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextClickInstruction > * {
  width: 354px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextPricingHeading1 {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: 7.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextPricingHeading1 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextPricingHeading1 {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: 5.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextPricingHeading1 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextPricingHeading1 {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 236px;
    margin-left: 7.3%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextPricingHeading1 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextPricingHeading1 {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 102px;
    margin-left: 6.7%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextPricingHeading1 > * {
  width: 102px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elTextPricingHeading2 {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 103px;
    margin-left: 282px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elTextPricingHeading2 > * {
  width: 103px;
  }

  .flowRow_CommunityDetails2Screen_elTextPricingHeading2_815102 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elTextPricingHeading2 {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 103px;
    margin-left: 282px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elTextPricingHeading2 > * {
  width: 103px;
  }

  .flowRow_CommunityDetails2Screen_elTextPricingHeading2_815102 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elTextPricingHeading2 {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 102px;
    margin-left: 280px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elTextPricingHeading2 > * {
  width: 102px;
  }

  .flowRow_CommunityDetails2Screen_elTextPricingHeading2_815102 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elTextPricingHeading2 {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 102px;
    margin-left: 280px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elTextPricingHeading2 > * {
  width: 102px;
  }

  .flowRow_CommunityDetails2Screen_elTextPricingHeading2_815102 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elTextPricingHeading3 {
    overflow: hidden;
    position: absolute;
    top: -24px;
    width: 147px;
    margin-left: 432px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elTextPricingHeading3 > * {
  width: 147px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elTextPricingHeading3 {
    overflow: hidden;
    position: absolute;
    top: -24px;
    width: 147px;
    margin-left: 432px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elTextPricingHeading3 > * {
  width: 147px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elTextPricingHeading3 {
    overflow: hidden;
    position: absolute;
    top: -24px;
    width: 146px;
    margin-left: 430px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elTextPricingHeading3 > * {
  width: 146px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elTextPricingHeading3 {
    overflow: hidden;
    position: absolute;
    top: -24px;
    width: 146px;
    margin-left: 430px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elTextPricingHeading3 > * {
  width: 146px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elCommunityFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elCommunityFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elCommunityFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elCommunityFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elCommunityFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elCommunityFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elCommunityFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elCommunityFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elCommunityFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elCommunityFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elCommunityFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elCommunityFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elCommunityFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elCommunityFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elCommunityFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elCommunityFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elCommunityFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elCommunityFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageILCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(7.0% - 18px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageILCopy > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageILCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(5.0% - 18px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageILCopy > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageILCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(7.3% - 18px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageILCopy > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageILCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(6.7% - 18px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageILCopy > * {
  width: 36px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLivingCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLivingCommFee > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLivingCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLivingCommFee > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLivingCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLivingCommFee > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLivingCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLivingCommFee > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elIndeLivingCommFee_936441 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elIndeLivingCommFee_936441 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elIndeLivingCommFee_936441 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elIndeLivingCommFee_936441 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: 126px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elIndeLivingCommFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elIndeLivingCommFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingCommFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLivingStudio {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLivingStudio > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLivingStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLivingStudio > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLivingStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLivingStudio > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLivingStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLivingStudio > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elIndeLivingStudioFee_44047 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elIndeLivingStudioFee_44047 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elIndeLivingStudioFee_44047 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elIndeLivingStudioFee_44047 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elIndeLivingStudioFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elIndeLivingStudioFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLivingStudioFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving1bdrm > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving1bdrm > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving1bdrm > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving1bdrm > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elIndeLiving1bdrmFee_998465 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elIndeLiving1bdrmFee_998465 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elIndeLiving1bdrmFee_998465 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elIndeLiving1bdrmFee_998465 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elIndeLiving1bdrmFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elIndeLiving1bdrmFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving2bdrm > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving2bdrm > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving2bdrm > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextIndeLiving2bdrm > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elIndeLiving2bdrmFee_263807 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elIndeLiving2bdrmFee_263807 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elIndeLiving2bdrmFee_263807 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elIndeLiving2bdrmFee_263807 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 443px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elIndeLiving2bdrmFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elIndeLiving2bdrmFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageALCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(7.0% - 18px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageALCopy > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageALCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(5.0% - 18px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageALCopy > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageALCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(7.3% - 18px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageALCopy > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageALCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(6.7% - 18px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageALCopy > * {
  width: 36px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingCommFee > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingCommFee > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingCommFee > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingCommFee > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLivingCommFee_2112582816 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLivingCommFee_2112582816 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLivingCommFee_2112582816 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLivingCommFee_2112582816 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUserPic {
    position: absolute;
    top: -36px;
    width: 48px;
    margin-left: 443px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUserPic > * {
  width: 48px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUserPic {
    position: absolute;
    top: -36px;
    width: 48px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUserPic > * {
  width: 48px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUserPic {
    position: absolute;
    top: -36px;
    width: 48px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUserPic > * {
  width: 48px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUserPic {
    position: absolute;
    top: -36px;
    width: 48px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUserPic > * {
  width: 48px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elAssistedLivingCommFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elAssistedLivingCommFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingStudio > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingStudio > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingStudio > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingStudio > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLivingStudioFee_1689423990 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLivingStudioFee_1689423990 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLivingStudioFee_1689423990 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLivingStudioFee_1689423990 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 443px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elAssistedLivingStudioFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elAssistedLivingStudioFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingStudioFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving1bdrm > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving1bdrm > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving1bdrm > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving1bdrm > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLiving1bdrmFee_1814949896 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLiving1bdrmFee_1814949896 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLiving1bdrmFee_1814949896 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLiving1bdrmFee_1814949896 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elAssistedLiving1bdrmFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elAssistedLiving1bdrmFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2bdrm > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2bdrm > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2bdrm > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2bdrm > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLiving2bdrmFee_654952705 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLiving2bdrmFee_654952705 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLiving2bdrmFee_654952705 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLiving2bdrmFee_654952705 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 443px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elAssistedLiving2bdrmFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elAssistedLiving2bdrmFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2bdrmShared {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2bdrmShared > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2bdrmShared {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2bdrmShared > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2bdrmShared {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2bdrmShared > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2bdrmShared {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2bdrmShared > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLiving2bdrmSharedFee_1971454872 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLiving2bdrmSharedFee_1971454872 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLiving2bdrmSharedFee_1971454872 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLiving2bdrmSharedFee_1971454872 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 443px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elAssistedLiving2bdrmSharedFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elAssistedLiving2bdrmSharedFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2ndOcc {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2ndOcc > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2ndOcc {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2ndOcc > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2ndOcc {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2ndOcc > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2ndOcc {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLiving2ndOcc > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLiving2ndOccFee_1466441808 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLiving2ndOccFee_1466441808 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLiving2ndOccFee_1466441808 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLiving2ndOccFee_1466441808 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 443px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elAssistedLiving2ndOccFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elAssistedLiving2ndOccFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingCareFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingCareFee > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingCareFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingCareFee > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingCareFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingCareFee > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingCareFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextAssistedLivingCareFee > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLivingCareFee_347883993 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLivingCareFee_347883993 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLivingCareFee_347883993 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elAssistedLivingCareFee_347883993 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 443px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elAssistedLivingCareFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elAssistedLivingCareFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageMCCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(7.0% - 18px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageMCCopy > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageMCCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(5.0% - 18px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageMCCopy > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageMCCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(7.3% - 18px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageMCCopy > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageMCCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(6.7% - 18px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageMCCopy > * {
  width: 36px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareCommFee > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareCommFee > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareCommFee > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareCommFee > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCareCommFee_394976249 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCareCommFee_394976249 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCareCommFee_394976249 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCareCommFee_394976249 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUserPic {
    position: absolute;
    top: -36px;
    width: 48px;
    margin-left: 443px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUserPic > * {
  width: 48px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUserPic {
    position: absolute;
    top: -36px;
    width: 48px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUserPic > * {
  width: 48px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUserPic {
    position: absolute;
    top: -36px;
    width: 48px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUserPic > * {
  width: 48px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUserPic {
    position: absolute;
    top: -36px;
    width: 48px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUserPic > * {
  width: 48px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elMemoryCareCommFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elMemoryCareCommFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCommFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareStudio > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareStudio > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareStudio > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareStudio > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCareStudioFee_261199248 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCareStudioFee_261199248 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCareStudioFee_261199248 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCareStudioFee_261199248 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 443px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elMemoryCareStudioFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elMemoryCareStudioFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareStudioFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare1bdrm > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare1bdrm > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare1bdrm > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare1bdrm > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCare1bdrmFee_470708175 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCare1bdrmFee_470708175 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCare1bdrmFee_470708175 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCare1bdrmFee_470708175 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 443px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elMemoryCare1bdrmFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elMemoryCare1bdrmFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2bdrm > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2bdrm > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2bdrm > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2bdrm > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCare2bdrmFee_1285984411 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCare2bdrmFee_1285984411 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCare2bdrmFee_1285984411 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCare2bdrmFee_1285984411 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 443px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elMemoryCare2bdrmFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elMemoryCare2bdrmFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2bdrmShared {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2bdrmShared > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2bdrmShared {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2bdrmShared > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2bdrmShared {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2bdrmShared > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2bdrmShared {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2bdrmShared > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCare2bdrmSharedFee_1071274713 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCare2bdrmSharedFee_1071274713 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCare2bdrmSharedFee_1071274713 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCare2bdrmSharedFee_1071274713 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 443px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elMemoryCare2bdrmSharedFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elMemoryCare2bdrmSharedFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2ndOcc {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2ndOcc > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2ndOcc {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2ndOcc > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2ndOcc {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2ndOcc > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2ndOcc {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCare2ndOcc > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCare2ndOccFee_1886496135 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCare2ndOccFee_1886496135 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCare2ndOccFee_1886496135 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCare2ndOccFee_1886496135 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 443px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elMemoryCare2ndOccFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elMemoryCare2ndOccFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareCareFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareCareFee > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareCareFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareCareFee > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareCareFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareCareFee > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareCareFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextMemoryCareCareFee > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCareCareFee_309549157 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCareCareFee_309549157 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCareCareFee_309549157 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elMemoryCareCareFee_309549157 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 443px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elMemoryCareCareFeeSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elMemoryCareCareFeeSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elMemoryCareCareFeeSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageSNCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(7.0% - 18px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageSNCopy > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageSNCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(5.0% - 18px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageSNCopy > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageSNCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(7.3% - 18px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageSNCopy > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageSNCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(6.7% - 18px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elImageSNCopy > * {
  width: 36px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursing {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursing > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursing {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursing > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursing {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursing > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursing {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursing > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursingPrivate {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursingPrivate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursingPrivate {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursingPrivate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursingPrivate {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursingPrivate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursingPrivate {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursingPrivate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elSkilledNursingPrivateFee_345308005 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elSkilledNursingPrivateFee_345308005 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elSkilledNursingPrivateFee_345308005 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elSkilledNursingPrivateFee_345308005 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 443px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elSkilledNursingPrivateSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elSkilledNursingPrivateSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingPrivateSubmit > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursingSemi {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursingSemi > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursingSemi {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.0% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursingSemi > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursingSemi {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(7.3% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursingSemi > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursingSemi {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.7% + 25px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextSkilledNursingSemi > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elSkilledNursingSemiFee_312059804 {
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetails2Screen_elSkilledNursingSemiFee_312059804 {
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elSkilledNursingSemiFee_312059804 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetails2Screen_elSkilledNursingSemiFee_312059804 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 443px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.0% + 584px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(7.3% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.7% + 580px);
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiEditable > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiEditable {
    position: absolute;
    top: 0px;
    width: 226px;
    margin-left: 256px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiEditable > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiEditable {
    position: absolute;
    top: 0px;
    width: 225px;
    margin-left: 254px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiEditable > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiEditable {
    position: absolute;
    top: -4px;
    width: 87px;
    margin-left: 290px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiEditable > * {
  width: 87px;
  height: 34px;
  }

}

.CommunityDetails2Screen .elSkilledNursingSemiSubmit button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elSkilledNursingSemiSubmit button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiSubmit > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 145px;
    margin-left: 402px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiSubmit > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiSubmit {
    line-height: 35px;
    position: absolute;
    top: 0px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiSubmit > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiSubmit {
    line-height: 35px;
    position: absolute;
    top: -4px;
    width: 144px;
    margin-left: 400px;
  }
  .CommunityDetails2Screen > .layoutFlow > .flowRow > .elSkilledNursingSemiSubmit > * {
  width: 144px;
  height: 35px;
  }

}

.CommunityDetails2Screen .elButtonBackBottom button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elButtonBackBottom button:focus {
  box-shadow: 0 0 5px rgba(56, 83, 128, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elButtonBackBottom {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 145px;
    margin-left: 7.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elButtonBackBottom > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elButtonBackBottom {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 145px;
    margin-left: 5.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elButtonBackBottom > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elButtonBackBottom {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 144px;
    margin-left: 7.3%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elButtonBackBottom > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elButtonBackBottom {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 144px;
    margin-left: 6.7%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elButtonBackBottom > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 202px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 201px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 200px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 200px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elTextPhotos {
    position: relative;
    margin-top: 50px;
    width: 238px;
    margin-left: 7.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextPhotos > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextPhotos {
    position: relative;
    margin-top: 50px;
    width: 238px;
    margin-left: 5.0%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextPhotos > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextPhotos {
    position: relative;
    margin-top: 50px;
    width: 236px;
    margin-left: 7.3%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextPhotos > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elTextPhotos {
    position: relative;
    margin-top: 50px;
    width: 236px;
    margin-left: 6.7%;
  }
  .CommunityDetails2Screen > .layoutFlow > .elTextPhotos > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elLineCopy {
    position: relative;
    margin-top: 202px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elLineCopy > * {
    width: 100%;
  height: 2px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elLineCopy {
    position: relative;
    margin-top: 201px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elLineCopy > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elLineCopy {
    position: relative;
    margin-top: 200px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elLineCopy > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elLineCopy {
    position: relative;
    margin-top: 200px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elLineCopy > * {
    width: 100%;
  height: 2px;
  }

}

.CommunityDetails2Screen > .layoutFlow > .elPhotoList > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  pointer-events: auto; /* horiz list must accept events for scrolling */
  -webkit-overflow-scrolling: touch;
}

.CommunityDetails2Screen > .layoutFlow > .elPhotoList > div > * {
  display: inline-block;
  position: relative;
  min-width: 219px;
}

  @media (min-width: 1024px) {
    .CommunityDetails2Screen > .layoutFlow > .elPhotoList > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      pointer-events: auto; /* horiz list must accept events for scrolling */
      -webkit-overflow-scrolling: touch;
    }

  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .CommunityDetails2Screen > .layoutFlow > .elPhotoList > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      pointer-events: auto; /* horiz list must accept events for scrolling */
      -webkit-overflow-scrolling: touch;
    }

  }

  @media (min-width: 568px) and (max-width: 767px) {
    .CommunityDetails2Screen > .layoutFlow > .elPhotoList > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      pointer-events: auto; /* horiz list must accept events for scrolling */
      -webkit-overflow-scrolling: touch;
    }

  }

  @media (max-width: 567px) {
    .CommunityDetails2Screen > .layoutFlow > .elPhotoList > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      pointer-events: auto; /* horiz list must accept events for scrolling */
      -webkit-overflow-scrolling: touch;
    }

  }



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elPhotoList {
    position: relative;
    margin-top: 0px;
    margin-left: 7.0%;
    margin-right: 0px;
    width: calc(93.0% - 0px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elPhotoList > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elPhotoList {
    position: relative;
    margin-top: 0px;
    margin-left: 5.0%;
    margin-right: 0px;
    width: calc(95.0% - 0px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elPhotoList > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elPhotoList {
    position: relative;
    margin-top: 0px;
    margin-left: 7.3%;
    margin-right: 0px;
    width: calc(92.7% - 0px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elPhotoList > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elPhotoList {
    position: relative;
    margin-top: 30px;
    margin-left: 6.7%;
    margin-right: 75px;
    width: calc(93.3% - 75px);
  }
  .CommunityDetails2Screen > .layoutFlow > .elPhotoList > * {
    width: 100%;
  }

}

.CommunityDetails2Screen .elPhotoComp {
  overflow: hidden;
}

.CommunityDetails2Screen .elPhotoComp.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elPhotoComp {
    position: relative;
    margin-top: 0px;
    width: 250px;
    margin-left: 78px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elPhotoComp > * {
  width: 250px;
  }

  .CommunityDetails2Screen > .layoutFlow > .elPhotoComp > .background > .containerMinHeight {
    min-height: 115px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elPhotoComp {
    position: relative;
    margin-top: 0px;
    width: 250px;
    margin-left: 78px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elPhotoComp > * {
  width: 250px;
  }

  .CommunityDetails2Screen > .layoutFlow > .elPhotoComp > .background > .containerMinHeight {
    min-height: 115px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elPhotoComp {
    position: relative;
    margin-top: 0px;
    width: 248px;
    margin-left: 78px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elPhotoComp > * {
  width: 248px;
  }

  .CommunityDetails2Screen > .layoutFlow > .elPhotoComp > .background > .containerMinHeight {
    min-height: 115px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elPhotoComp {
    position: relative;
    margin-top: -228px;
    width: 248px;
    margin-left: 164px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elPhotoComp > * {
  width: 248px;
  }

  .CommunityDetails2Screen > .layoutFlow > .elPhotoComp > .background > .containerMinHeight {
    min-height: 115px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

@media (min-width: 568px) {
  .CommunityDetails2Screen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
  }
  .CommunityDetails2Screen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

}

.CommunityDetails2Screen .elBkgdOutlineOverall {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetails2Screen .elBkgdOutlineOverall:focus {
  box-shadow: 0 0 5px rgba(56, 83, 128, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
@media (min-width: 568px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}

.CommunityDetails2Screen .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.CommunityDetails2Screen .elIconButton:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elIconButton {
    position: absolute;
    left: 5px;
    top: 4px;
    width: 41px;
    height: 36px;
  }
@media (min-width: 568px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elTwittercircle {
    position: fixed;
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elTwitter {
    position: fixed;
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elInstagramcircle {
    position: fixed;
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elIg {
    position: fixed;
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elFacebookcircle {
    position: fixed;
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elFb {
    position: fixed;
    right: 101px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elFb {
    right: 101px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elFb {
    right: 100px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elFb {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}

.CommunityDetails2Screen .elButtonLearnMore {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.CommunityDetails2Screen .elButtonLearnMore:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elButtonLearnMore {
    position: fixed;
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}

.CommunityDetails2Screen .elButtonAboutUs {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.CommunityDetails2Screen .elButtonAboutUs:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elButtonAboutUs {
    position: fixed;
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elLogo {
    position: absolute;
    left: 189px;
    top: 7px;
    width: 121px;
    height: 32px;
  }
@media (min-width: 568px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elLogo {
    left: 50px;
    top: 7px;
    width: 121px;
    height: 32px;
  }
}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elLogo {
    left: 188px;
    top: 7px;
    width: 120px;
    height: 32px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elLogo {
    left: 188px;
    top: 7px;
    width: 120px;
    height: 32px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    position: absolute;
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
@media (min-width: 568px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elBeta {
    position: absolute;
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
@media (min-width: 568px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elBeta {
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 768px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetails2Screen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .LoginScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .LoginScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .LoginScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 121px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginScreen > .layoutFlow > .elText > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .LoginScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 120px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginScreen > .layoutFlow > .elText > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .LoginScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 120px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginScreen > .layoutFlow > .elText > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .LoginScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 120px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginScreen > .layoutFlow > .elText > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .layoutFlow > .elTextCopy2 {
    position: relative;
    margin-top: 20px;
    width: 282px;
    margin-left: calc(50.0% - 141px);
  }
  .LoginScreen > .layoutFlow > .elTextCopy2 > * {
  width: 282px;
  }

@media (min-width: 568px) {
  .LoginScreen > .layoutFlow > .elTextCopy2 {
    position: relative;
    margin-top: 20px;
    width: 282px;
    margin-left: calc(50.0% - 141px);
  }
  .LoginScreen > .layoutFlow > .elTextCopy2 > * {
  width: 282px;
  }

}
@media (min-width: 768px) {
  .LoginScreen > .layoutFlow > .elTextCopy2 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LoginScreen > .layoutFlow > .elTextCopy2 > * {
  width: 280px;
  }

}
@media (min-width: 1024px) {
  .LoginScreen > .layoutFlow > .elTextCopy2 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LoginScreen > .layoutFlow > .elTextCopy2 > * {
  width: 280px;
  }

}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .layoutFlow > .elTextCopy3 {
    position: relative;
    margin-top: 20px;
    width: 282px;
    margin-left: calc(50.0% - 141px);
  }
  .LoginScreen > .layoutFlow > .elTextCopy3 > * {
  width: 282px;
  }

@media (min-width: 568px) {
  .LoginScreen > .layoutFlow > .elTextCopy3 {
    position: relative;
    margin-top: 20px;
    width: 282px;
    margin-left: calc(50.0% - 141px);
  }
  .LoginScreen > .layoutFlow > .elTextCopy3 > * {
  width: 282px;
  }

}
@media (min-width: 768px) {
  .LoginScreen > .layoutFlow > .elTextCopy3 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LoginScreen > .layoutFlow > .elTextCopy3 > * {
  width: 280px;
  }

}
@media (min-width: 1024px) {
  .LoginScreen > .layoutFlow > .elTextCopy3 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LoginScreen > .layoutFlow > .elTextCopy3 > * {
  width: 280px;
  }

}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .layoutFlow > .elText2 {
    position: relative;
    margin-top: 20px;
    width: 282px;
    margin-left: calc(50.0% - 141px);
  }
  .LoginScreen > .layoutFlow > .elText2 > * {
  width: 282px;
  }

@media (min-width: 568px) {
  .LoginScreen > .layoutFlow > .elText2 {
    position: relative;
    margin-top: 20px;
    width: 282px;
    margin-left: calc(50.0% - 141px);
  }
  .LoginScreen > .layoutFlow > .elText2 > * {
  width: 282px;
  }

}
@media (min-width: 768px) {
  .LoginScreen > .layoutFlow > .elText2 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LoginScreen > .layoutFlow > .elText2 > * {
  width: 280px;
  }

}
@media (min-width: 1024px) {
  .LoginScreen > .layoutFlow > .elText2 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LoginScreen > .layoutFlow > .elText2 > * {
  width: 280px;
  }

}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .layoutFlow > .elTextCopy {
    position: relative;
    margin-top: 5px;
    width: 282px;
    margin-left: calc(50.0% - 141px);
  }
  .LoginScreen > .layoutFlow > .elTextCopy > * {
  width: 282px;
  }

@media (min-width: 568px) {
  .LoginScreen > .layoutFlow > .elTextCopy {
    position: relative;
    margin-top: 5px;
    width: 282px;
    margin-left: calc(50.0% - 141px);
  }
  .LoginScreen > .layoutFlow > .elTextCopy > * {
  width: 282px;
  }

}
@media (min-width: 768px) {
  .LoginScreen > .layoutFlow > .elTextCopy {
    position: relative;
    margin-top: 5px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LoginScreen > .layoutFlow > .elTextCopy > * {
  width: 280px;
  }

}
@media (min-width: 1024px) {
  .LoginScreen > .layoutFlow > .elTextCopy {
    position: relative;
    margin-top: 5px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LoginScreen > .layoutFlow > .elTextCopy > * {
  width: 280px;
  }

}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 50px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginScreen > .layoutFlow > .elFirebaseLogin > * {
    width: 100%;
  height: 83px;
  }

@media (min-width: 568px) {
  .LoginScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 50px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginScreen > .layoutFlow > .elFirebaseLogin > * {
    width: 100%;
  height: 83px;
  }

}
@media (min-width: 768px) {
  .LoginScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 50px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginScreen > .layoutFlow > .elFirebaseLogin > * {
    width: 100%;
  height: 82px;
  }

}
@media (min-width: 1024px) {
  .LoginScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 50px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginScreen > .layoutFlow > .elFirebaseLogin > * {
    width: 100%;
  height: 82px;
  }

}

.LoginScreen .elBkgdOutlineOverall {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.LoginScreen .elBkgdOutlineOverall:focus {
  box-shadow: 0 0 5px rgba(56, 83, 128, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .LoginScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
@media (min-width: 568px) {
  .LoginScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .LoginScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 1024px) {
  .LoginScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    position: absolute;
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
@media (min-width: 568px) {
  .LoginScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
}
@media (min-width: 768px) {
  .LoginScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}
@media (min-width: 1024px) {
  .LoginScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .screenFgContainer > .foreground > .elBeta {
    position: absolute;
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
@media (min-width: 568px) {
  .LoginScreen > .screenFgContainer > .foreground > .elBeta {
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 768px) {
  .LoginScreen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 1024px) {
  .LoginScreen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .screenFgContainer > .foreground > .elTwittercircle {
    position: fixed;
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .LoginScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .LoginScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .LoginScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    position: fixed;
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .LoginScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .LoginScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .LoginScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    position: fixed;
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .LoginScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .LoginScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .LoginScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}

.LoginScreen .elButtonAboutUs {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.LoginScreen .elButtonAboutUs:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .LoginScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    position: fixed;
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .LoginScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .LoginScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .LoginScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}

.LoginScreen .elButtonLearnMore {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.LoginScreen .elButtonLearnMore:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .LoginScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    position: fixed;
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .LoginScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .LoginScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .LoginScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}

/* This component has a fixed-size layout */
  .ScrollingPhotos {
    width: 100%; /* This component was designed using a width of 219px */
    height: 100%;
  }


  .ScrollingPhotos > .layoutFlow > .elComp {
    position: relative;
    margin-top: 130px;
    width: 248px;
    margin-left: 0px;
  }
  .ScrollingPhotos > .layoutFlow > .elComp > * {
  width: 248px;
  }



  .ScrollingPhotos > .layoutFlow > .elImage {
    position: relative;
    margin-top: -129px;
    width: 199px;
    margin-left: 10px;
  }
  .ScrollingPhotos > .layoutFlow > .elImage > * {
  width: 199px;
  height: 112px;
  }


/* This component has a fixed-size layout */
  .UploadPhotos {
    width: 100%; /* This component was designed using a width of 164px */
    height: 100%;
  }


  .UploadPhotos > .layoutFlow > .elFirebaseUpload {
    position: relative;
    margin-top: 10px;
    width: 163px;
    margin-left: 0px;
  }
  .UploadPhotos > .layoutFlow > .elFirebaseUpload > * {
  width: 163px;
  height: 162px;
  }


.UploadPhotos .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.UploadPhotos .elButton button:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



  .UploadPhotos > .layoutFlow > .elButton {
    position: relative;
    margin-top: -110px;
    width: 144px;
    margin-left: 15px;
  }
  .UploadPhotos > .layoutFlow > .elButton > * {
  width: 144px;
  height: 35px;
  }



  .UploadPhotos > .layoutFlow > .elFileUploadedText {
    overflow: hidden;
    position: relative;
    margin-top: -35px;
    width: 144px;
    margin-left: 165px;
  }
  .UploadPhotos > .layoutFlow > .elFileUploadedText > * {
  width: 144px;
  }



/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .AboutUsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .AboutUsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .AboutUsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.AboutUsScreen .elBkgdOutlineOverall {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.AboutUsScreen .elBkgdOutlineOverall:focus {
  box-shadow: 0 0 5px rgba(56, 83, 128, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
@media (min-width: 568px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 1024px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}

.AboutUsScreen .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.AboutUsScreen .elIconButton:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .screenFgContainer > .foreground > .elIconButton {
    position: absolute;
    left: 5px;
    top: 4px;
    width: 41px;
    height: 36px;
  }
@media (min-width: 568px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}
@media (min-width: 768px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}
@media (min-width: 1024px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .screenFgContainer > .foreground > .elTwittercircle {
    position: fixed;
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    position: fixed;
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    position: fixed;
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}

.AboutUsScreen .elButtonLearnMore {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.AboutUsScreen .elButtonLearnMore:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    position: fixed;
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}

.AboutUsScreen .elButtonAboutUs {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.AboutUsScreen .elButtonAboutUs:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    position: fixed;
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    position: absolute;
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
@media (min-width: 568px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
}
@media (min-width: 768px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}
@media (min-width: 1024px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}


/* Narrowest size for this element's responsive layout */
  .AboutUsScreen > .screenFgContainer > .foreground > .elBeta {
    position: absolute;
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
@media (min-width: 568px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elBeta {
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 768px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 1024px) {
  .AboutUsScreen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .CommunityDetailsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.CommunityDetailsScreen .elButtonBackTop button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetailsScreen .elButtonBackTop button:focus {
  box-shadow: 0 0 5px rgba(56, 83, 128, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elButtonBackTop {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    width: 145px;
    margin-left: 7.0%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elButtonBackTop > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elButtonBackTop {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    width: 145px;
    margin-left: 5.1%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elButtonBackTop > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elButtonBackTop {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    width: 144px;
    margin-left: 8.5%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elButtonBackTop > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elButtonBackTop {
    line-height: 35px;
    position: relative;
    margin-top: 70px;
    width: 144px;
    margin-left: 6.6%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elButtonBackTop > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elCommunityName {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    margin-left: 7.0%;
    margin-right: 11px;
    width: calc(93.0% - 11px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityName > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elCommunityName {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    margin-left: 5.1%;
    margin-right: 11px;
    width: calc(94.9% - 11px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityName > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elCommunityName {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    margin-left: 8.5%;
    margin-right: 11px;
    width: calc(91.5% - 11px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityName > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elCommunityName {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 6.6%;
    margin-right: 15px;
    width: calc(93.4% - 15px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityName > * {
    width: 100%;
  }

}

.CommunityDetailsScreen .elButtonAddPhotos button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.CommunityDetailsScreen .elButtonAddPhotos button:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elButtonAddPhotos {
    position: relative;
    margin-top: 70px;
    width: 100%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elButtonAddPhotos > * {
  margin-left: auto;
  width: 145px;
  margin-right: 11px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elButtonAddPhotos {
    position: relative;
    margin-top: 70px;
    width: 100%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elButtonAddPhotos > * {
  margin-left: auto;
  width: 145px;
  margin-right: 11px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elButtonAddPhotos {
    position: relative;
    margin-top: 70px;
    width: 100%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elButtonAddPhotos > * {
  margin-left: auto;
  width: 144px;
  margin-right: 11px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elButtonAddPhotos {
    position: relative;
    margin-top: 70px;
    width: 100%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elButtonAddPhotos > * {
  margin-left: auto;
  width: 144px;
  margin-right: 85px;
  height: 35px;
  }

}

.CommunityDetailsScreen .elCompPhotoUpload {
  overflow: hidden;
}

.CommunityDetailsScreen .elCompPhotoUpload.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elCompPhotoUpload {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elCompPhotoUpload > * {
  margin-left: auto;
  width: 352px;
  margin-right: 11px;
  }

  .CommunityDetailsScreen > .layoutFlow > .elCompPhotoUpload > .background > .containerMinHeight {
    min-height: 100px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elCompPhotoUpload {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elCompPhotoUpload > * {
  margin-left: auto;
  width: 352px;
  margin-right: 11px;
  }

  .CommunityDetailsScreen > .layoutFlow > .elCompPhotoUpload > .background > .containerMinHeight {
    min-height: 100px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elCompPhotoUpload {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elCompPhotoUpload > * {
  margin-left: auto;
  width: 350px;
  margin-right: 11px;
  }

  .CommunityDetailsScreen > .layoutFlow > .elCompPhotoUpload > .background > .containerMinHeight {
    min-height: 100px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elCompPhotoUpload {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elCompPhotoUpload > * {
  margin-left: auto;
  width: 350px;
  margin-right: 75px;
  }

  .CommunityDetailsScreen > .layoutFlow > .elCompPhotoUpload > .background > .containerMinHeight {
    min-height: 100px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elImageIL {
    position: relative;
    margin-top: -100px;
    width: 36px;
    margin-left: 7.0%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elImageIL > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elImageIL {
    position: relative;
    margin-top: -100px;
    width: 36px;
    margin-left: 5.1%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elImageIL > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elImageIL {
    position: relative;
    margin-top: -100px;
    width: 36px;
    margin-left: 8.5%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elImageIL > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elImageIL {
    position: relative;
    margin-top: -100px;
    width: 36px;
    margin-left: 6.6%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elImageIL > * {
  width: 36px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageAL {
    position: absolute;
    margin-top: -36px;
    width: 36px;
    margin-left: calc(7.0% + 50px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageAL > * {
  width: 36px;
  }

  .flowRow_CommunityDetailsScreen_elImageAL_938037118 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageAL {
    position: absolute;
    margin-top: -36px;
    width: 36px;
    margin-left: calc(5.1% + 50px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageAL > * {
  width: 36px;
  }

  .flowRow_CommunityDetailsScreen_elImageAL_938037118 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageAL {
    position: absolute;
    margin-top: -36px;
    width: 36px;
    margin-left: calc(8.5% + 50px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageAL > * {
  width: 36px;
  }

  .flowRow_CommunityDetailsScreen_elImageAL_938037118 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageAL {
    position: absolute;
    margin-top: -36px;
    width: 36px;
    margin-left: calc(6.6% + 50px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageAL > * {
  width: 36px;
  }

  .flowRow_CommunityDetailsScreen_elImageAL_938037118 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageMC {
    position: absolute;
    top: -42px;
    width: 36px;
    margin-left: calc(7.0% + 101px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageMC > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageMC {
    position: absolute;
    top: -42px;
    width: 36px;
    margin-left: calc(5.1% + 101px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageMC > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageMC {
    position: absolute;
    top: -41px;
    width: 36px;
    margin-left: calc(8.5% + 100px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageMC > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageMC {
    position: absolute;
    top: -41px;
    width: 36px;
    margin-left: calc(6.6% + 100px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageMC > * {
  width: 36px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageSN {
    position: absolute;
    top: -42px;
    width: 36px;
    margin-left: calc(7.0% + 151px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageSN > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageSN {
    position: absolute;
    top: -42px;
    width: 36px;
    margin-left: calc(5.1% + 151px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageSN > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageSN {
    position: absolute;
    top: -41px;
    width: 36px;
    margin-left: calc(8.5% + 150px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageSN > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageSN {
    position: absolute;
    top: -41px;
    width: 36px;
    margin-left: calc(6.6% + 150px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageSN > * {
  width: 36px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elCommunityAddress {
    position: relative;
    margin-top: 10px;
    margin-left: 7.0%;
    margin-right: 11px;
    width: calc(93.0% - 11px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityAddress > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elCommunityAddress {
    position: relative;
    margin-top: 10px;
    margin-left: 5.1%;
    margin-right: 11px;
    width: calc(94.9% - 11px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityAddress > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elCommunityAddress {
    position: relative;
    margin-top: 10px;
    margin-left: 8.5%;
    margin-right: 11px;
    width: calc(91.5% - 11px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityAddress > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elCommunityAddress {
    position: relative;
    margin-top: 10px;
    margin-left: 6.6%;
    margin-right: 11px;
    width: calc(93.4% - 11px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityAddress > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elCommunityCity {
    position: relative;
    margin-top: 1px;
    width: 238px;
    margin-left: 7.0%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityCity > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elCommunityCity {
    position: relative;
    margin-top: 1px;
    width: 238px;
    margin-left: 5.1%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityCity > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elCommunityCity {
    position: relative;
    margin-top: 1px;
    width: 236px;
    margin-left: 8.5%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityCity > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elCommunityCity {
    position: relative;
    margin-top: 1px;
    width: 236px;
    margin-left: 6.6%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityCity > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elCommunityPhone {
    position: relative;
    margin-top: 1px;
    width: 238px;
    margin-left: 7.0%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityPhone > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elCommunityPhone {
    position: relative;
    margin-top: 1px;
    width: 238px;
    margin-left: 5.1%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityPhone > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elCommunityPhone {
    position: relative;
    margin-top: 1px;
    width: 236px;
    margin-left: 8.5%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityPhone > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elCommunityPhone {
    position: relative;
    margin-top: 1px;
    width: 236px;
    margin-left: 6.6%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityPhone > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextBasicInfo {
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 7.0%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextBasicInfo > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextBasicInfo {
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 5.1%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextBasicInfo > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextBasicInfo {
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 8.5%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextBasicInfo > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextBasicInfo {
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 6.6%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextBasicInfo > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elEditButton {
    overflow: hidden;
    position: absolute;
    margin-top: -26px;
    width: 74px;
    margin-left: 265px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elEditButton > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elEditButton_1373363033 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elEditButton {
    overflow: hidden;
    position: absolute;
    margin-top: -26px;
    width: 74px;
    margin-left: 265px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elEditButton > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elEditButton_1373363033 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elEditButton {
    overflow: hidden;
    position: absolute;
    margin-top: -26px;
    width: 73px;
    margin-left: 260px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elEditButton > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elEditButton_1373363033 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elEditButton {
    overflow: hidden;
    position: absolute;
    margin-top: -26px;
    width: 73px;
    margin-left: 260px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elEditButton > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elEditButton_1373363033 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextLicenseTypes {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 7.0%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextLicenseTypes > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextLicenseTypes {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 5.1%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextLicenseTypes > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextLicenseTypes {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 8.5%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextLicenseTypes > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextLicenseTypes {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 149px;
    margin-left: 6.6%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextLicenseTypes > * {
  width: 149px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextLicensedBeds {
    overflow: hidden;
    position: relative;
    margin-top: -216px;
    width: 238px;
    margin-left: 230px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextLicensedBeds > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextLicensedBeds {
    overflow: hidden;
    position: relative;
    margin-top: -216px;
    width: 238px;
    margin-left: 262px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextLicensedBeds > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextLicensedBeds {
    overflow: hidden;
    position: relative;
    margin-top: -216px;
    width: 236px;
    margin-left: 260px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextLicensedBeds > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextLicensedBeds {
    overflow: hidden;
    position: relative;
    margin-top: -216px;
    width: 119px;
    margin-left: 260px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextLicensedBeds > * {
  width: 119px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elIndeLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 44px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elIndeLivingBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elIndeLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 44px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elIndeLivingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elIndeLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 44px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elIndeLivingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elIndeLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 44px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elIndeLivingBedCount > * {
  width: 44px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elAssistedLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elAssistedLivingBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elAssistedLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elAssistedLivingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elAssistedLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elAssistedLivingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elAssistedLivingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elAssistedLivingBedCount > * {
  width: 44px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elMemoryCareBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elMemoryCareBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elMemoryCareBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elMemoryCareBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elMemoryCareBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elMemoryCareBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elMemoryCareBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elMemoryCareBedCount > * {
  width: 44px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elSkilledNursingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elSkilledNursingBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elSkilledNursingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elSkilledNursingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elSkilledNursingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elSkilledNursingBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elSkilledNursingBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elSkilledNursingBedCount > * {
  width: 44px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elOtherBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elOtherBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elOtherBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elOtherBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elOtherBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elOtherBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elOtherBedCount {
    overflow: hidden;
    position: relative;
    margin-top: 21px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elOtherBedCount > * {
  width: 44px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elLine1 {
    position: relative;
    margin-top: 10px;
    width: 297px;
    margin-left: 7.0%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elLine1 > * {
  width: 297px;
  height: 2px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elLine1 {
    position: relative;
    margin-top: 10px;
    width: 297px;
    margin-left: 5.1%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elLine1 > * {
  width: 297px;
  height: 2px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elLine1 {
    position: relative;
    margin-top: 10px;
    width: 295px;
    margin-left: 8.5%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elLine1 > * {
  width: 295px;
  height: 2px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elLine1 {
    position: relative;
    margin-top: 10px;
    width: 295px;
    margin-left: 6.6%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elLine1 > * {
  width: 295px;
  height: 2px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextTotalBed {
    overflow: hidden;
    position: relative;
    margin-top: 17px;
    width: 199px;
    margin-left: 7.0%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextTotalBed > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextTotalBed {
    overflow: hidden;
    position: relative;
    margin-top: 17px;
    width: 199px;
    margin-left: 5.1%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextTotalBed > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextTotalBed {
    overflow: hidden;
    position: relative;
    margin-top: 17px;
    width: 197px;
    margin-left: 8.5%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextTotalBed > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextTotalBed {
    overflow: hidden;
    position: relative;
    margin-top: 17px;
    width: 197px;
    margin-left: 6.6%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextTotalBed > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTotalBedCount {
    overflow: hidden;
    position: relative;
    margin-top: -24px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTotalBedCount > * {
  width: 44px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTotalBedCount {
    overflow: hidden;
    position: relative;
    margin-top: -24px;
    width: 44px;
    margin-left: 292px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTotalBedCount > * {
  width: 44px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTotalBedCount {
    overflow: hidden;
    position: relative;
    margin-top: -24px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTotalBedCount > * {
  width: 44px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTotalBedCount {
    overflow: hidden;
    position: relative;
    margin-top: -24px;
    width: 44px;
    margin-left: 290px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTotalBedCount > * {
  width: 44px;
  }

}

@keyframes fadeIn_CommunityDetailsScreen_elCommunityCoverImage {
  from { opacity: 0.0; }
  to   { opacity: 1.0; }
}
.CommunityDetailsScreen > .layoutFlow > .elCommunityCoverImage {
  animation: 0.25s fadeIn_CommunityDetailsScreen_elCommunityCoverImage;
}

/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elCommunityCoverImage {
    position: relative;
    margin-top: 50px;
    width: 100%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityCoverImage > * {
  margin-left: auto;
  width: 475px;
  margin-right: calc(8.0% + 0px);
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elCommunityCoverImage {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityCoverImage > * {
  margin-left: auto;
  width: 320px;
  margin-right: calc(8.0% + 0px);
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elCommunityCoverImage {
    position: relative;
    margin-top: -280px;
    width: 100%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityCoverImage > * {
  margin-left: auto;
  width: 318px;
  margin-right: calc(8.0% + 0px);
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elCommunityCoverImage {
    position: relative;
    margin-top: -280px;
    width: 100%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elCommunityCoverImage > * {
  margin-left: auto;
  width: 475px;
  margin-right: calc(8.0% + 0px);
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextPricing {
    position: relative;
    margin-top: 50px;
    width: 100px;
    margin-left: 7.0%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextPricing > * {
  width: 100px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextPricing {
    position: relative;
    margin-top: 151px;
    width: 238px;
    margin-left: 5.1%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextPricing > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextPricing {
    position: relative;
    margin-top: 150px;
    width: 236px;
    margin-left: 8.5%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextPricing > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextPricing {
    position: relative;
    margin-top: 50px;
    width: 236px;
    margin-left: 6.6%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextPricing > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextClickInstruction {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 357px;
    margin-left: 7.0%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextClickInstruction > * {
  width: 357px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextClickInstruction {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 357px;
    margin-left: 5.1%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextClickInstruction > * {
  width: 357px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextClickInstruction {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 354px;
    margin-left: 8.5%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextClickInstruction > * {
  width: 354px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextClickInstruction {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 354px;
    margin-left: 6.6%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextClickInstruction > * {
  width: 354px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextPricingHeading1 {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: 7.0%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextPricingHeading1 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextPricingHeading1 {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: 5.1%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextPricingHeading1 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextPricingHeading1 {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 236px;
    margin-left: 8.5%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextPricingHeading1 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextPricingHeading1 {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 102px;
    margin-left: 6.6%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextPricingHeading1 > * {
  width: 102px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elTextPricingHeading2 {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 103px;
    margin-left: 282px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elTextPricingHeading2 > * {
  width: 103px;
  }

  .flowRow_CommunityDetailsScreen_elTextPricingHeading2_942212 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elTextPricingHeading2 {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 103px;
    margin-left: 282px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elTextPricingHeading2 > * {
  width: 103px;
  }

  .flowRow_CommunityDetailsScreen_elTextPricingHeading2_942212 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elTextPricingHeading2 {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 102px;
    margin-left: 280px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elTextPricingHeading2 > * {
  width: 102px;
  }

  .flowRow_CommunityDetailsScreen_elTextPricingHeading2_942212 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elTextPricingHeading2 {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 102px;
    margin-left: 280px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elTextPricingHeading2 > * {
  width: 102px;
  }

  .flowRow_CommunityDetailsScreen_elTextPricingHeading2_942212 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elTextPricingHeading3 {
    overflow: hidden;
    position: absolute;
    top: -24px;
    width: 147px;
    margin-left: 20px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elTextPricingHeading3 > * {
  width: 147px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elTextPricingHeading3 {
    overflow: hidden;
    position: absolute;
    top: -24px;
    width: 147px;
    margin-left: 432px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elTextPricingHeading3 > * {
  width: 147px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elTextPricingHeading3 {
    overflow: hidden;
    position: absolute;
    top: -24px;
    width: 146px;
    margin-left: 430px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elTextPricingHeading3 > * {
  width: 146px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elTextPricingHeading3 {
    overflow: hidden;
    position: absolute;
    top: -24px;
    width: 146px;
    margin-left: 430px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elTextPricingHeading3 > * {
  width: 146px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageILCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(7.0% - 18px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageILCopy > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageILCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(5.1% - 18px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageILCopy > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageILCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(8.5% - 18px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageILCopy > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageILCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(6.6% - 18px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageILCopy > * {
  width: 36px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLivingCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLivingCommFee > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLivingCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLivingCommFee > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLivingCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLivingCommFee > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLivingCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLivingCommFee > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elIndeLivingCommFee_680417 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elIndeLivingCommFee_680417 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elIndeLivingCommFee_680417 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elIndeLivingCommFee_680417 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingCommFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLivingStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLivingStudio > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLivingStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLivingStudio > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLivingStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLivingStudio > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLivingStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLivingStudio > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elIndeLivingStudioFee_957778 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elIndeLivingStudioFee_957778 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elIndeLivingStudioFee_957778 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elIndeLivingStudioFee_957778 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLivingStudioDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving1bdrm > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving1bdrm > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving1bdrm > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving1bdrm > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elIndeLiving1bdrmFee_309532 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elIndeLiving1bdrmFee_309532 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elIndeLiving1bdrmFee_309532 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elIndeLiving1bdrmFee_309532 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving1bdrmDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving2bdrm > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving2bdrm > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving2bdrm > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextIndeLiving2bdrm > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elIndeLiving2bdrmFee_975704 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elIndeLiving2bdrmFee_975704 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elIndeLiving2bdrmFee_975704 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elIndeLiving2bdrmFee_975704 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elIndeLiving2bdrmDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageALCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(7.0% - 18px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageALCopy > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageALCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(5.1% - 18px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageALCopy > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageALCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(8.5% - 18px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageALCopy > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageALCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(6.6% - 18px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageALCopy > * {
  width: 36px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingCommFee > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingCommFee > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingCommFee > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingCommFee > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLivingCommFee_299187 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLivingCommFee_299187 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLivingCommFee_299187 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLivingCommFee_299187 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUserPic {
    position: absolute;
    top: -36px;
    width: 48px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUserPic > * {
  width: 48px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUserPic {
    position: absolute;
    top: -36px;
    width: 48px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUserPic > * {
  width: 48px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUserPic {
    position: absolute;
    top: -36px;
    width: 48px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUserPic > * {
  width: 48px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCommFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingStudio > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingStudio > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingStudio > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingStudio > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLivingStudioFee_286123 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLivingStudioFee_286123 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLivingStudioFee_286123 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLivingStudioFee_286123 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingStudioDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving1bdrm > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving1bdrm > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving1bdrm > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving1bdrm > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLiving1bdrmFee_915030 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLiving1bdrmFee_915030 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLiving1bdrmFee_915030 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLiving1bdrmFee_915030 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving1bdrmDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2bdrm > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2bdrm > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2bdrm > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2bdrm > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLiving2bdrmFee_372917 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLiving2bdrmFee_372917 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLiving2bdrmFee_372917 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLiving2bdrmFee_372917 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2bdrmShared {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2bdrmShared > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2bdrmShared {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2bdrmShared > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2bdrmShared {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2bdrmShared > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2bdrmShared {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2bdrmShared > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLiving2bdrmSharedFee_325042 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLiving2bdrmSharedFee_325042 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLiving2bdrmSharedFee_325042 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLiving2bdrmSharedFee_325042 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2bdrmSharedDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2ndOcc {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2ndOcc > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2ndOcc {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2ndOcc > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2ndOcc {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2ndOcc > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2ndOcc {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLiving2ndOcc > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLiving2ndOccFee_838170 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLiving2ndOccFee_838170 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLiving2ndOccFee_838170 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLiving2ndOccFee_838170 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLiving2ndOccDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingCareFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingCareFee > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingCareFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingCareFee > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingCareFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingCareFee > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingCareFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextAssistedLivingCareFee > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLivingCareFee_65281 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLivingCareFee_65281 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLivingCareFee_65281 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elAssistedLivingCareFee_65281 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elAssistedLivingCareFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageMCCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(7.0% - 18px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageMCCopy > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageMCCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(5.1% - 18px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageMCCopy > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageMCCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(8.5% - 18px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageMCCopy > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageMCCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(6.6% - 18px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageMCCopy > * {
  width: 36px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareCommFee > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareCommFee > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareCommFee > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareCommFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareCommFee > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCareCommFee_512119 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCareCommFee_512119 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCareCommFee_512119 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCareCommFee_512119 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCommFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareStudio > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareStudio > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareStudio > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareStudio {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareStudio > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCareStudioFee_1011000 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCareStudioFee_1011000 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCareStudioFee_1011000 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCareStudioFee_1011000 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareStudioDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare1bdrm > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare1bdrm > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare1bdrm > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare1bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare1bdrm > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCare1bdrmFee_312056 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCare1bdrmFee_312056 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCare1bdrmFee_312056 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCare1bdrmFee_312056 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare1bdrmDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2bdrm > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2bdrm > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2bdrm > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2bdrm {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2bdrm > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCare2bdrmFee_422387 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCare2bdrmFee_422387 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCare2bdrmFee_422387 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCare2bdrmFee_422387 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2bdrmShared {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2bdrmShared > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2bdrmShared {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2bdrmShared > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2bdrmShared {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2bdrmShared > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2bdrmShared {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2bdrmShared > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCare2bdrmSharedFee_756506 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCare2bdrmSharedFee_756506 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCare2bdrmSharedFee_756506 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCare2bdrmSharedFee_756506 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2bdrmSharedDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2ndOcc {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2ndOcc > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2ndOcc {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2ndOcc > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2ndOcc {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2ndOcc > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2ndOcc {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCare2ndOcc > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCare2ndOccFee_551430 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCare2ndOccFee_551430 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCare2ndOccFee_551430 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCare2ndOccFee_551430 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCare2ndOccDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareCareFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareCareFee > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareCareFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareCareFee > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareCareFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareCareFee > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareCareFee {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextMemoryCareCareFee > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCareCareFee_611838 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCareCareFee_611838 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCareCareFee_611838 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elMemoryCareCareFee_611838 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elMemoryCareCareFeeDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageSNCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(7.0% - 18px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageSNCopy > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageSNCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(5.1% - 18px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageSNCopy > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageSNCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(8.5% - 18px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageSNCopy > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageSNCopy {
    position: absolute;
    top: 20px;
    width: 36px;
    margin-left: calc(6.6% - 18px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elImageSNCopy > * {
  width: 36px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursing {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursing > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursing {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursing > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursing {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursing > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursing {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursing > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursingPrivate {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursingPrivate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursingPrivate {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursingPrivate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursingPrivate {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursingPrivate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursingPrivate {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursingPrivate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elSkilledNursingPrivateFee_25414791 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elSkilledNursingPrivateFee_25414791 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elSkilledNursingPrivateFee_25414791 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elSkilledNursingPrivateFee_25414791 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingPrivateDate > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursingSemi {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(7.0% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursingSemi > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursingSemi {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 199px;
    margin-left: calc(5.1% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursingSemi > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursingSemi {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(8.5% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursingSemi > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursingSemi {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 197px;
    margin-left: calc(6.6% + 25px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextSkilledNursingSemi > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elSkilledNursingSemiFee_1146281762 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 74px;
    margin-left: 272px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiFee > * {
  width: 74px;
  }

  .flowRow_CommunityDetailsScreen_elSkilledNursingSemiFee_1146281762 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiFee {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elSkilledNursingSemiFee_1146281762 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiFee {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 73px;
    margin-left: 270px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiFee > * {
  width: 73px;
  }

  .flowRow_CommunityDetailsScreen_elSkilledNursingSemiFee_1146281762 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiUserPic {
    position: absolute;
    top: -2px;
    width: 24px;
    margin-left: 90px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiUserPic > * {
  width: 24px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 442px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiUserPic > * {
  width: 24px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiUserPic > * {
  width: 24px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiUserPic {
    position: absolute;
    top: -24px;
    width: 24px;
    margin-left: 440px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiUserPic > * {
  width: 24px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiUser {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: 126px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiUser > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: 509px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiUser > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiUser > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiUser {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: 506px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiUser > * {
  width: 197px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiDate {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 199px;
    margin-left: calc(7.0% + 260px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiDate > * {
  width: 199px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 199px;
    margin-left: calc(5.1% + 584px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiDate > * {
  width: 199px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(8.5% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiDate > * {
  width: 197px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiDate {
    overflow: hidden;
    position: absolute;
    top: -21px;
    width: 197px;
    margin-left: calc(6.6% + 580px);
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elSkilledNursingSemiDate > * {
  width: 197px;
  }

}

.CommunityDetailsScreen .elButtonBackBottom button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetailsScreen .elButtonBackBottom button:focus {
  box-shadow: 0 0 5px rgba(56, 83, 128, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elButtonBackBottom {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 145px;
    margin-left: 7.0%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elButtonBackBottom > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elButtonBackBottom {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 145px;
    margin-left: 5.1%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elButtonBackBottom > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elButtonBackBottom {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 144px;
    margin-left: 8.5%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elButtonBackBottom > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elButtonBackBottom {
    line-height: 35px;
    position: relative;
    margin-top: 40px;
    width: 144px;
    margin-left: 6.6%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elButtonBackBottom > * {
  width: 144px;
  height: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 202px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 201px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 200px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 200px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elField {
    position: absolute;
    margin-top: 0px;
    width: 226px;
    margin-left: 330px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elField > * {
  width: 226px;
  height: 34px;
  }

  .flowRow_CommunityDetailsScreen_elField_1859237862 {
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elField {
    position: absolute;
    margin-top: 0px;
    width: 226px;
    margin-left: 330px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elField > * {
  width: 226px;
  height: 34px;
  }

  .flowRow_CommunityDetailsScreen_elField_1859237862 {
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elField {
    position: absolute;
    margin-top: 0px;
    width: 225px;
    margin-left: 328px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elField > * {
  width: 225px;
  height: 34px;
  }

  .flowRow_CommunityDetailsScreen_elField_1859237862 {
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elField {
    position: absolute;
    margin-top: 0px;
    width: 225px;
    margin-left: 328px;
  }
  .CommunityDetailsScreen > .layoutFlow > .flowRow > .elField > * {
  width: 225px;
  height: 34px;
  }

  .flowRow_CommunityDetailsScreen_elField_1859237862 {
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextReviewsHeader {
    position: relative;
    margin-top: 11px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextReviewsHeader > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextReviewsHeader {
    position: relative;
    margin-top: 11px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextReviewsHeader > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextReviewsHeader {
    position: relative;
    margin-top: 11px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextReviewsHeader > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextReviewsHeader {
    position: relative;
    margin-top: 11px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextReviewsHeader > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elTextPhotos {
    position: relative;
    margin-top: 50px;
    width: 238px;
    margin-left: 7.0%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextPhotos > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextPhotos {
    position: relative;
    margin-top: 50px;
    width: 238px;
    margin-left: 5.1%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextPhotos > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextPhotos {
    position: relative;
    margin-top: 50px;
    width: 236px;
    margin-left: 8.5%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextPhotos > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elTextPhotos {
    position: relative;
    margin-top: 50px;
    width: 236px;
    margin-left: 6.6%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elTextPhotos > * {
  width: 236px;
  }

}

.CommunityDetailsScreen > .layoutFlow > .elPhotoList > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  pointer-events: auto; /* horiz list must accept events for scrolling */
  -webkit-overflow-scrolling: touch;
}

.CommunityDetailsScreen > .layoutFlow > .elPhotoList > div > * {
  display: inline-block;
  position: relative;
  min-width: 219px;
}

  @media (min-width: 1024px) {
    .CommunityDetailsScreen > .layoutFlow > .elPhotoList > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      pointer-events: auto; /* horiz list must accept events for scrolling */
      -webkit-overflow-scrolling: touch;
    }

  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .CommunityDetailsScreen > .layoutFlow > .elPhotoList > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      pointer-events: auto; /* horiz list must accept events for scrolling */
      -webkit-overflow-scrolling: touch;
    }

  }

  @media (min-width: 568px) and (max-width: 767px) {
    .CommunityDetailsScreen > .layoutFlow > .elPhotoList > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      pointer-events: auto; /* horiz list must accept events for scrolling */
      -webkit-overflow-scrolling: touch;
    }

  }

  @media (max-width: 567px) {
    .CommunityDetailsScreen > .layoutFlow > .elPhotoList > div {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      pointer-events: auto; /* horiz list must accept events for scrolling */
      -webkit-overflow-scrolling: touch;
    }

  }



/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elPhotoList {
    position: relative;
    margin-top: 0px;
    margin-left: 7.0%;
    margin-right: 0px;
    width: calc(93.0% - 0px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elPhotoList > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elPhotoList {
    position: relative;
    margin-top: 0px;
    margin-left: 5.1%;
    margin-right: 0px;
    width: calc(94.9% - 0px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elPhotoList > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elPhotoList {
    position: relative;
    margin-top: 30px;
    margin-left: 8.5%;
    margin-right: 75px;
    width: calc(91.5% - 75px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elPhotoList > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elPhotoList {
    position: relative;
    margin-top: 30px;
    margin-left: 6.6%;
    margin-right: 75px;
    width: calc(93.4% - 75px);
  }
  .CommunityDetailsScreen > .layoutFlow > .elPhotoList > * {
    width: 100%;
  }

}

.CommunityDetailsScreen .elPhotoComp {
  overflow: hidden;
}

.CommunityDetailsScreen .elPhotoComp.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elPhotoComp {
    position: relative;
    margin-top: 0px;
    width: 250px;
    margin-left: 78px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elPhotoComp > * {
  width: 250px;
  }

  .CommunityDetailsScreen > .layoutFlow > .elPhotoComp > .background > .containerMinHeight {
    min-height: 115px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elPhotoComp {
    position: relative;
    margin-top: 0px;
    width: 250px;
    margin-left: 78px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elPhotoComp > * {
  width: 250px;
  }

  .CommunityDetailsScreen > .layoutFlow > .elPhotoComp > .background > .containerMinHeight {
    min-height: 115px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elPhotoComp {
    position: relative;
    margin-top: -228px;
    width: 248px;
    margin-left: 164px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elPhotoComp > * {
  width: 248px;
  }

  .CommunityDetailsScreen > .layoutFlow > .elPhotoComp > .background > .containerMinHeight {
    min-height: 115px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elPhotoComp {
    position: relative;
    margin-top: -228px;
    width: 248px;
    margin-left: 164px;
  }
  .CommunityDetailsScreen > .layoutFlow > .elPhotoComp > * {
  width: 248px;
  }

  .CommunityDetailsScreen > .layoutFlow > .elPhotoComp > .background > .containerMinHeight {
    min-height: 115px;
  }

}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
    margin-left: 7.0%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

@media (min-width: 568px) {
  .CommunityDetailsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
    margin-left: 5.1%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
    margin-left: 8.5%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
    margin-left: 6.6%;
  }
  .CommunityDetailsScreen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

}

.CommunityDetailsScreen .elBkgdOutlineOverall {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityDetailsScreen .elBkgdOutlineOverall:focus {
  box-shadow: 0 0 5px rgba(56, 83, 128, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
@media (min-width: 568px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}

.CommunityDetailsScreen .elFab {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.CommunityDetailsScreen .elFab:focus {
  box-shadow: 0 0 6px rgba(66, 66, 66, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    position: fixed;
    right: 23px;
    bottom: 23px;
    width: 68px;
    height: 68px;
  }
@media (min-width: 568px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    right: 23px;
    bottom: 23px;
    width: 68px;
    height: 68px;
  }
}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    right: 22px;
    bottom: 22px;
    width: 68px;
    height: 68px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    right: 22px;
    bottom: 22px;
    width: 68px;
    height: 68px;
  }
}

.CommunityDetailsScreen .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.CommunityDetailsScreen .elIconButton:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elIconButton {
    position: absolute;
    left: 5px;
    top: 4px;
    width: 41px;
    height: 36px;
  }
@media (min-width: 568px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elTwittercircle {
    position: fixed;
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elTwitter {
    position: fixed;
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    position: fixed;
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elIg {
    position: fixed;
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    position: fixed;
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elFb {
    position: fixed;
    right: 101px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elFb {
    right: 101px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elFb {
    right: 100px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elFb {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}

.CommunityDetailsScreen .elButtonLearnMore {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.CommunityDetailsScreen .elButtonLearnMore:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    position: fixed;
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}

.CommunityDetailsScreen .elButtonAboutUs {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.CommunityDetailsScreen .elButtonAboutUs:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    position: fixed;
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elLogo {
    position: absolute;
    left: 189px;
    top: 7px;
    width: 121px;
    height: 32px;
  }
@media (min-width: 568px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elLogo {
    left: 50px;
    top: 7px;
    width: 121px;
    height: 32px;
  }
}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elLogo {
    left: 188px;
    top: 7px;
    width: 120px;
    height: 32px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elLogo {
    left: 188px;
    top: 7px;
    width: 120px;
    height: 32px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    position: absolute;
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
@media (min-width: 568px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elBeta {
    position: absolute;
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
@media (min-width: 568px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elBeta {
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 768px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 1024px) {
  .CommunityDetailsScreen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}

/* This component has a fixed-size layout */
  .CommunityItem {
    width: 100%; /* This component was designed using a width of 442px */
    height: 100%;
  }


  .CommunityItem > .background > .elBackgroundShape {
    position: absolute;
    left: 0px;
    right: -200px;
    top: 0px;
    width: calc(100.0% - 0px - -200px);
    height: 80px;
  }


  .CommunityItem > .layoutFlow > .elImage {
    position: relative;
    margin-top: 9px;
    width: 88px;
    margin-left: 10.1%;
  }
  .CommunityItem > .layoutFlow > .elImage > * {
  width: 88px;
  height: 62px;
  }



  .CommunityItem > .layoutFlow > .elCommunityIndex {
    overflow: hidden;
    position: relative;
    margin-top: -60px;
    margin-left: calc(10.1% + 100px);
    margin-right: 11px;
    width: calc(89.9% - 111px);
  }
  .CommunityItem > .layoutFlow > .elCommunityIndex > * {
    width: 100%;
  }



  .CommunityItem > .layoutFlow > .flowRow > .elCommunityName {
    overflow: hidden;
    position: absolute;
    margin-top: -21px;
    margin-left: calc(10.1% + 118px);
    margin-right: 11px;
    width: calc(89.9% - 129px);
  }
  .CommunityItem > .layoutFlow > .flowRow > .elCommunityName > * {
    width: 100%;
  }

  .flowRow_CommunityItem_elCommunityName_495180 {
  }



  .CommunityItem > .layoutFlow > .flowRow > .elCommunityType {
    overflow: hidden;
    position: absolute;
    top: 0px;
    margin-left: calc(10.1% + 100px);
    margin-right: 11px;
    width: calc(89.9% - 111px);
  }
  .CommunityItem > .layoutFlow > .flowRow > .elCommunityType > * {
    width: 100%;
  }



  .CommunityItem > .layoutFlow > .flowRow > .elCommunityAddress {
    position: absolute;
    top: -10px;
    width: 196px;
    margin-left: 99.9%;
  }
  .CommunityItem > .layoutFlow > .flowRow > .elCommunityAddress > * {
  width: 196px;
  }



  .CommunityItem > .layoutFlow > .elCommunityCityStateZip {
    position: relative;
    margin-top: 5px;
    width: 196px;
    margin-left: 99.9%;
  }
  .CommunityItem > .layoutFlow > .elCommunityCityStateZip > * {
  width: 196px;
  }



  .CommunityItem > .layoutFlow > .elCommunityPhone {
    position: relative;
    margin-top: 0px;
    width: 196px;
    margin-left: 99.9%;
  }
  .CommunityItem > .layoutFlow > .elCommunityPhone > * {
  width: 196px;
  }


.CommunityItem .elButtonReviews button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}



  .CommunityItem > .layoutFlow > .elButtonReviews {
    position: relative;
    margin-top: -60px;
    margin-left: -4px;
    margin-right: -45.2%;
    width: calc(145.2% - -4px);
  }
  .CommunityItem > .layoutFlow > .elButtonReviews > * {
    width: 100%;
  height: 67px;
  }



  .CommunityItem > .layoutFlow > .elRectangleSpacer {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .CommunityItem > .layoutFlow > .elRectangleSpacer > * {
    width: 100%;
  height: 6px;
  }



  .CommunityItem > .foreground > .elTextBlock {
    overflow: hidden;
    position: absolute;
    left: -106px;
    top: 355px;
    width: 0px;
    height: 22px;
  }


  .CommunityItem > .foreground > .elTextBlock2 {
    overflow: hidden;
    position: absolute;
    left: 117px;
    top: 314px;
    width: 0px;
    height: 22px;
  }


/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
@media (min-width: 568px) {
  .CommunityScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
}
@media (min-width: 768px) {
  .CommunityScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}
@media (min-width: 1024px) {
  .CommunityScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .layoutFlow > .elEmbed {
    position: relative;
    margin-top: 0px;
    width: 272px;
    margin-left: 534px;
  }
  .CommunityScreen > .layoutFlow > .elEmbed > * {
  width: 272px;
  }

@media (min-width: 568px) {
  .CommunityScreen > .layoutFlow > .elEmbed {
    position: relative;
    margin-top: 0px;
    width: 272px;
    margin-left: 534px;
  }
  .CommunityScreen > .layoutFlow > .elEmbed > * {
  width: 272px;
  }

}
@media (min-width: 768px) {
  .CommunityScreen > .layoutFlow > .elEmbed {
    position: relative;
    margin-top: 0px;
    width: 270px;
    margin-left: 530px;
  }
  .CommunityScreen > .layoutFlow > .elEmbed > * {
  width: 270px;
  }

}
@media (min-width: 1024px) {
  .CommunityScreen > .layoutFlow > .elEmbed {
    position: relative;
    margin-top: 150px;
    width: 270px;
    margin-left: 530px;
  }
  .CommunityScreen > .layoutFlow > .elEmbed > * {
  width: 270px;
  }

}

.CommunityScreen .elList li {
  margin-bottom: 5px;
}



/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: -400px;
    width: 320px;
    margin-left: 0px;
  }
  .CommunityScreen > .layoutFlow > .elList > * {
  width: 320px;
  }

@media (min-width: 568px) {
  .CommunityScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: -400px;
    width: 320px;
    margin-left: 0px;
  }
  .CommunityScreen > .layoutFlow > .elList > * {
  width: 320px;
  }

}
@media (min-width: 768px) {
  .CommunityScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: -400px;
    width: 318px;
    margin-left: 0px;
  }
  .CommunityScreen > .layoutFlow > .elList > * {
  width: 318px;
  }

}
@media (min-width: 1024px) {
  .CommunityScreen > .layoutFlow > .elList {
    position: relative;
    margin-top: -550px;
    width: 318px;
    margin-left: 0px;
  }
  .CommunityScreen > .layoutFlow > .elList > * {
  width: 318px;
  }

}

.CommunityScreen .elBkgdOutlineOverall {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.CommunityScreen .elBkgdOutlineOverall:focus {
  box-shadow: 0 0 5px rgba(56, 83, 128, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
@media (min-width: 568px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 1024px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}

@keyframes fadeIn_CommunityScreen_elTextNumberOfItems {
  0% { opacity: 0.0; }
  17% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
.CommunityScreen > .screenFgContainer > .foreground > .elTextNumberOfItems {
  animation: 1.20s fadeIn_CommunityScreen_elTextNumberOfItems;
}

/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .screenFgContainer > .foreground > .elTextNumberOfItems {
    position: fixed;
    right: 69px;
    top: 62px;
    width: 227px;
    height: 22px;
  }
@media (min-width: 568px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elTextNumberOfItems {
    right: 69px;
    top: 62px;
    width: 227px;
    height: 22px;
  }
}
@media (min-width: 768px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elTextNumberOfItems {
    right: 69px;
    top: 62px;
    width: 225px;
    height: 22px;
  }
}
@media (min-width: 1024px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elTextNumberOfItems {
    right: 69px;
    top: 76px;
    width: 225px;
    height: 22px;
  }
}

.circularProgressIndicator {
    position: relative;
    width: 50px;
    height: 50px;
    display: none;
}
.circularProgressIndicator-active {
    animation: rotate 2s linear infinite;
    display: inline;
}
.circularProgress-animatedPath {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
    animation: circularProgress-dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
}
@keyframes rotate{
    100%{
    transform: rotate(360deg);
    }
}
@keyframes circularProgress-dash{
    0%{
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50%{
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100%{
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}



/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .screenFgContainer > .foreground > .elProgressIndicator {
    position: absolute;
    left: calc(50.0% + 0px - 24px);
    top: 138px;
    width: 48px;
    height: 48px;
  }
@media (min-width: 568px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elProgressIndicator {
    left: calc(50.0% + 0px - 24px);
    top: 138px;
    width: 48px;
    height: 48px;
  }
}
@media (min-width: 768px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elProgressIndicator {
    left: calc(50.0% + 0px - 24px);
    top: 137px;
    width: 47px;
    height: 47px;
  }
}
@media (min-width: 1024px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elProgressIndicator {
    left: calc(50.0% + 0px - 24px);
    top: 137px;
    width: 47px;
    height: 47px;
  }
}

@keyframes fadeIn_CommunityScreen_elPickerNumberOfRows {
  0% { opacity: 0.0; }
  17% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
.CommunityScreen > .screenFgContainer > .foreground > .elPickerNumberOfRows {
  animation: 1.20s fadeIn_CommunityScreen_elPickerNumberOfRows;
}

/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .screenFgContainer > .foreground > .elPickerNumberOfRows {
    position: fixed;
    right: 7px;
    top: 56px;
    width: 53px;
    height: 30px;
  }
@media (min-width: 568px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elPickerNumberOfRows {
    right: 7px;
    top: 56px;
    width: 53px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elPickerNumberOfRows {
    right: 7px;
    top: 56px;
    width: 53px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elPickerNumberOfRows {
    right: 7px;
    top: 69px;
    width: 53px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .screenFgContainer > .foreground > .elLogo {
    position: absolute;
    left: 189px;
    top: 7px;
    width: 121px;
    height: 32px;
  }
@media (min-width: 568px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elLogo {
    left: 50px;
    top: 7px;
    width: 121px;
    height: 32px;
  }
}
@media (min-width: 768px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elLogo {
    left: 188px;
    top: 7px;
    width: 120px;
    height: 32px;
  }
}
@media (min-width: 1024px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elLogo {
    left: 188px;
    top: 7px;
    width: 120px;
    height: 32px;
  }
}

.CommunityScreen .elButtonAboutUs {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.CommunityScreen .elButtonAboutUs:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    position: fixed;
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}

.CommunityScreen .elButtonLearnMore {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.CommunityScreen .elButtonLearnMore:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    position: fixed;
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    position: fixed;
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .screenFgContainer > .foreground > .elFb {
    position: fixed;
    right: 101px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elFb {
    right: 101px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elFb {
    right: 100px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elFb {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    position: fixed;
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .screenFgContainer > .foreground > .elIg {
    position: fixed;
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .screenFgContainer > .foreground > .elTwittercircle {
    position: fixed;
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .screenFgContainer > .foreground > .elTwitter {
    position: fixed;
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}

.CommunityScreen .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.CommunityScreen .elIconButton:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .screenFgContainer > .foreground > .elIconButton {
    position: absolute;
    left: 5px;
    top: 4px;
    width: 41px;
    height: 36px;
  }
@media (min-width: 568px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}
@media (min-width: 768px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}
@media (min-width: 1024px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    position: absolute;
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
@media (min-width: 568px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
}
@media (min-width: 768px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}
@media (min-width: 1024px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}


/* Narrowest size for this element's responsive layout */
  .CommunityScreen > .screenFgContainer > .foreground > .elBeta {
    position: absolute;
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
@media (min-width: 568px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elBeta {
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 768px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 1024px) {
  .CommunityScreen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .TCScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .TCScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .TCScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCHeadingText {
    overflow: hidden;
    position: relative;
    margin-top: 80px;
    width: 351px;
    margin-left: 7.0%;
  }
  .TCScreen > .layoutFlow > .elTCHeadingText > * {
  width: 351px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCHeadingText {
    overflow: hidden;
    position: relative;
    margin-top: 80px;
    width: 351px;
    margin-left: 5.1%;
  }
  .TCScreen > .layoutFlow > .elTCHeadingText > * {
  width: 351px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCHeadingText {
    overflow: hidden;
    position: relative;
    margin-top: 80px;
    width: 348px;
    margin-left: 8.5%;
  }
  .TCScreen > .layoutFlow > .elTCHeadingText > * {
  width: 348px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCHeadingText {
    overflow: hidden;
    position: relative;
    margin-top: 80px;
    width: 348px;
    margin-left: 6.6%;
  }
  .TCScreen > .layoutFlow > .elTCHeadingText > * {
  width: 348px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 7.0%;
    margin-right: 9.1%;
    width: calc(83.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 5.1%;
    margin-right: 6.4%;
    width: calc(88.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 8.5%;
    margin-right: 5.4%;
    width: calc(86.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 6.6%;
    margin-right: 4.8%;
    width: calc(88.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCText2 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 7.0%;
    margin-right: 9.1%;
    width: calc(83.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText2 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCText2 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 5.1%;
    margin-right: 6.4%;
    width: calc(88.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText2 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCText2 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 8.5%;
    margin-right: 5.4%;
    width: calc(86.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText2 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCText2 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 6.6%;
    margin-right: 4.8%;
    width: calc(88.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText2 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCText3 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 7.0%;
    margin-right: 9.1%;
    width: calc(83.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText3 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCText3 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 5.1%;
    margin-right: 6.4%;
    width: calc(88.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText3 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCText3 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 8.5%;
    margin-right: 5.4%;
    width: calc(86.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText3 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCText3 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 6.6%;
    margin-right: 4.8%;
    width: calc(88.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText3 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingText > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingText > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingText > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingText > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumbering {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumbering > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumbering {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumbering > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumbering {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumbering > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumbering {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumbering > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletText {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletText > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletText {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletText > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletText {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletText > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletText {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletText > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy2 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy2 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy2 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy2 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy2 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy2 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy2 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy2 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 266px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy > * {
  width: 266px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 266px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy > * {
  width: 266px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 264px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy > * {
  width: 264px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 264px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy > * {
  width: 264px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 9.1%;
    width: calc(74.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy3 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 6.4%;
    width: calc(77.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy3 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 5.4%;
    width: calc(78.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy3 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 4.8%;
    width: calc(79.2% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy3 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 266px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy2 > * {
  width: 266px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 266px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy2 > * {
  width: 266px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 264px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy2 > * {
  width: 264px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy2 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 264px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy2 > * {
  width: 264px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy4 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 9.1%;
    width: calc(74.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy4 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy4 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 6.4%;
    width: calc(77.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy4 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy4 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 5.4%;
    width: calc(78.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy4 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy4 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 4.8%;
    width: calc(79.2% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy4 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy3 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy3 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy3 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy3 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy3 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy3 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy3 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy3 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy3 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy5 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy5 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy5 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy5 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy5 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy5 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy5 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy5 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy4 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy4 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy4 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy4 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy4 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy4 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy4 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy4 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy6 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy6 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy6 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy6 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy6 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy6 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy6 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy6 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy5 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy5 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy5 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy5 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy5 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy5 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy5 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy5 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy7 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy7 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy7 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy7 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy7 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy7 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy7 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy7 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy6 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy6 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy6 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy6 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy6 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy6 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy6 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy6 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy8 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy8 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy8 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy8 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy8 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy8 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy8 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy8 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy7 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy7 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy7 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy7 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy7 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy7 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy7 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy7 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy9 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy9 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy9 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy9 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy9 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy9 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy9 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy9 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy4 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy4 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy4 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy4 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy4 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy4 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy4 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy4 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy8 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy8 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy8 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy8 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy8 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy8 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy8 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy8 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy10 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy10 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy10 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy10 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy10 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy10 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy10 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy10 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy9 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy9 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy9 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy9 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy9 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy9 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy9 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy9 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy11 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy11 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy11 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy11 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy11 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy11 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy11 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy11 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy10 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy10 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy10 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy10 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy10 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy10 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy10 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy10 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy12 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(23.3% + 0px);
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy12 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy12 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(23.3% + 0px);
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy12 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy12 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(23.3% + 0px);
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy12 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy12 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(23.3% + 0px);
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy12 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy11 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy11 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy11 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy11 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy11 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy11 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy11 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy11 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy13 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy13 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy13 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy13 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy13 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy13 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy13 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy13 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy12 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy12 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy12 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy12 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy12 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy12 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy12 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy12 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy15 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(23.3% + 0px);
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy15 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy15 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(23.3% + 0px);
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy15 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy15 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(23.3% + 0px);
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy15 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy15 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(23.3% + 0px);
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy15 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy5 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 310px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy5 > * {
  width: 310px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy5 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 310px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy5 > * {
  width: 310px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy5 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 308px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy5 > * {
  width: 308px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy5 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 308px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy5 > * {
  width: 308px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy14 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 9.1%;
    width: calc(74.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy14 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy14 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 6.4%;
    width: calc(77.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy14 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy14 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 5.4%;
    width: calc(78.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy14 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy14 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 4.8%;
    width: calc(79.2% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy14 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy6 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy6 > * {
  width: 404px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy6 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy6 > * {
  width: 404px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy6 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy6 > * {
  width: 401px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy6 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy6 > * {
  width: 401px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 9.1%;
    width: calc(74.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy19 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 6.4%;
    width: calc(77.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy19 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 5.4%;
    width: calc(78.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy19 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 4.8%;
    width: calc(79.2% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy19 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy13 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy13 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy13 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy13 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy13 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy13 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy13 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy13 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy16 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy16 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy16 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy16 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy16 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy16 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy16 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy16 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy14 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy14 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy14 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy14 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy14 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy14 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy14 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy14 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy17 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy17 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy17 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy17 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy17 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy17 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy17 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy17 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy16 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy16 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy16 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy16 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy16 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy16 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy16 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy16 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy20 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy20 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy20 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy20 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy20 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy20 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy20 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy20 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy17 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy17 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy17 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy17 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy17 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy17 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy17 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy17 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy21 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy21 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy21 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy21 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy21 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy21 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy21 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy21 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy15 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy15 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy15 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy15 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy15 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy15 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy15 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy15 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy18 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy18 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy18 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy18 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy18 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy18 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy18 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy18 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy18 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy18 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy18 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy18 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy18 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy18 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy18 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy18 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy22 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 9.1%;
    width: calc(58.7% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy22 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy22 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 6.4%;
    width: calc(61.5% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy22 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy22 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: calc(32.2% + -0px);
    margin-right: 5.4%;
    width: calc(62.4% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy22 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy22 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: calc(32.2% + -0px);
    margin-right: 4.8%;
    width: calc(63.0% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy22 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy19 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy19 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy19 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy19 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy23 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy23 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy23 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy23 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy23 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy23 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy23 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy23 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy20 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy20 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy20 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy20 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy20 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy20 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy20 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy20 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy24 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 9.1%;
    width: calc(58.7% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy24 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy24 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 6.4%;
    width: calc(61.5% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy24 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy24 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 5.4%;
    width: calc(62.4% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy24 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy24 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 4.8%;
    width: calc(63.0% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy24 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy21 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy21 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy21 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy21 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy21 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy21 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy21 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy21 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy25 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 9.1%;
    width: calc(58.7% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy25 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy25 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 6.4%;
    width: calc(61.5% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy25 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy25 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: calc(32.2% + -0px);
    margin-right: 5.4%;
    width: calc(62.4% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy25 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy25 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: calc(32.2% + -0px);
    margin-right: 4.8%;
    width: calc(63.0% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy25 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy22 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy22 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy22 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy22 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy22 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy22 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy22 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy22 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy26 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy26 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy26 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy26 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy26 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy26 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy26 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy26 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy23 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy23 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy23 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy23 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy23 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy23 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy23 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy23 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy27 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 9.1%;
    width: calc(58.7% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy27 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy27 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 6.4%;
    width: calc(61.5% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy27 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy27 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 5.4%;
    width: calc(62.4% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy27 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy27 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 4.8%;
    width: calc(63.0% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy27 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy24 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy24 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy24 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy24 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy24 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy24 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy24 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy24 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy28 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 9.1%;
    width: calc(58.7% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy28 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy28 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 6.4%;
    width: calc(61.5% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy28 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy28 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: calc(32.2% + -0px);
    margin-right: 5.4%;
    width: calc(62.4% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy28 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy28 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: calc(32.2% + -0px);
    margin-right: 4.8%;
    width: calc(63.0% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy28 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy25 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(27.7% + 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy25 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy25 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(27.7% + 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy25 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy25 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(27.7% + 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy25 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy25 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(27.7% + 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy25 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy29 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy29 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy29 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy29 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy29 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy29 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy29 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy29 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy26 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy26 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy26 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy26 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy26 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy26 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy26 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy26 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy30 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 9.1%;
    width: calc(58.7% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy30 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy30 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 6.4%;
    width: calc(61.5% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy30 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy30 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 5.4%;
    width: calc(62.4% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy30 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy30 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 4.8%;
    width: calc(63.0% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy30 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy27 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy27 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy27 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy27 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy27 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy27 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy27 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy27 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy31 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 9.1%;
    width: calc(58.7% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy31 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy31 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 6.4%;
    width: calc(61.5% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy31 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy31 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: calc(32.2% + -0px);
    margin-right: 5.4%;
    width: calc(62.4% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy31 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy31 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: calc(32.2% + -0px);
    margin-right: 4.8%;
    width: calc(63.0% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy31 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy28 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy28 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy28 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy28 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy28 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy28 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy28 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy28 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy32 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy32 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy32 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy32 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy32 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy32 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy32 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy32 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy29 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy29 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy29 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy29 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy29 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy29 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy29 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy29 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy33 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 9.1%;
    width: calc(58.7% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy33 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy33 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 6.4%;
    width: calc(61.5% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy33 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy33 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 5.4%;
    width: calc(62.4% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy33 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy33 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 4.8%;
    width: calc(63.0% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy33 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy30 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy30 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy30 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy30 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy30 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy30 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy30 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy30 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy35 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 9.1%;
    width: calc(58.7% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy35 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy35 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 6.4%;
    width: calc(61.5% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy35 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy35 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: calc(32.2% + -0px);
    margin-right: 5.4%;
    width: calc(62.4% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy35 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy35 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: calc(32.2% + -0px);
    margin-right: 4.8%;
    width: calc(63.0% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy35 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy31 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy31 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy31 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy31 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy31 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy31 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy31 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy31 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy36 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy36 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy36 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy36 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy36 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy36 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy36 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy36 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy32 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy32 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy32 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy32 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy32 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy32 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy32 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy32 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy37 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 9.1%;
    width: calc(58.7% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy37 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy37 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 6.4%;
    width: calc(61.5% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy37 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy37 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 5.4%;
    width: calc(62.4% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy37 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy37 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 4.8%;
    width: calc(63.0% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy37 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy33 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy33 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy33 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy33 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy33 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy33 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy33 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy33 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy38 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 9.1%;
    width: calc(58.7% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy38 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy38 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 6.4%;
    width: calc(61.5% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy38 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy38 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: calc(32.2% + -0px);
    margin-right: 5.4%;
    width: calc(62.4% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy38 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy38 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: calc(32.2% + -0px);
    margin-right: 4.8%;
    width: calc(63.0% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy38 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy34 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(27.7% + 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy34 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy34 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: calc(27.7% + 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy34 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy34 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(27.7% + 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy34 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy34 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: calc(27.7% + 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy34 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy39 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy39 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy39 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy39 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy39 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy39 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy39 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy39 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy35 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy35 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy35 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy35 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy35 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy35 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy35 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy35 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy40 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 9.1%;
    width: calc(58.7% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy40 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy40 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 6.4%;
    width: calc(61.5% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy40 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy40 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 5.4%;
    width: calc(62.4% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy40 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy40 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 4.8%;
    width: calc(63.0% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy40 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy36 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy36 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy36 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy36 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy36 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy36 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy36 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy36 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy41 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 9.1%;
    width: calc(58.7% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy41 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy41 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 6.4%;
    width: calc(61.5% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy41 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy41 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: calc(32.2% + -0px);
    margin-right: 5.4%;
    width: calc(62.4% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy41 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy41 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: calc(32.2% + -0px);
    margin-right: 4.8%;
    width: calc(63.0% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy41 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy37 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy37 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy37 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy37 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy37 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy37 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy37 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy37 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy42 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy42 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy42 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy42 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy42 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy42 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy42 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy42 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy38 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy38 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy38 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy38 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy38 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy38 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy38 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy38 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy43 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 9.1%;
    width: calc(58.7% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy43 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy43 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 6.4%;
    width: calc(61.5% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy43 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy43 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 5.4%;
    width: calc(62.4% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy43 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy43 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: calc(32.2% + -0px);
    margin-right: 4.8%;
    width: calc(63.0% - -0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy43 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy7 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy7 > * {
  width: 404px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy7 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy7 > * {
  width: 404px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy7 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy7 > * {
  width: 401px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy7 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy7 > * {
  width: 401px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy39 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy39 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy39 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy39 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy39 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy39 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy39 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy39 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy34 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy34 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy34 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy34 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy34 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy34 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy34 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy34 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy40 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy40 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy40 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy40 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy40 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy40 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy40 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy40 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy44 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy44 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy44 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy44 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy44 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy44 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy44 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy44 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy8 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy8 > * {
  width: 404px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy8 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy8 > * {
  width: 404px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy8 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy8 > * {
  width: 401px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy8 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy8 > * {
  width: 401px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy45 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 9.1%;
    width: calc(74.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy45 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy45 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 6.4%;
    width: calc(77.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy45 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy45 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 5.4%;
    width: calc(78.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy45 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy45 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 4.8%;
    width: calc(79.2% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy45 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy9 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy9 > * {
  width: 404px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy9 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy9 > * {
  width: 404px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy9 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy9 > * {
  width: 401px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy9 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy9 > * {
  width: 401px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy41 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy41 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy41 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy41 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy41 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy41 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy41 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy41 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy46 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy46 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy46 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy46 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy46 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy46 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy46 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy46 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy10 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy10 > * {
  width: 404px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy10 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy10 > * {
  width: 404px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy10 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy10 > * {
  width: 401px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy10 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy10 > * {
  width: 401px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy47 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 9.1%;
    width: calc(74.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy47 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy47 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 6.4%;
    width: calc(77.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy47 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy47 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 5.4%;
    width: calc(78.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy47 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy47 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 4.8%;
    width: calc(79.2% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy47 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy11 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy11 > * {
  width: 404px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy11 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy11 > * {
  width: 404px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy11 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy11 > * {
  width: 401px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy11 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy11 > * {
  width: 401px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy48 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 9.1%;
    width: calc(74.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy48 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy48 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 6.4%;
    width: calc(77.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy48 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy48 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 5.4%;
    width: calc(78.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy48 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy48 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 16.0%;
    margin-right: 4.8%;
    width: calc(79.2% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy48 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy42 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy42 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy42 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy42 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy42 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy42 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy42 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy42 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy49 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy49 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy49 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy49 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy49 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy49 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy49 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy49 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy43 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy43 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy43 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy43 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy43 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy43 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy43 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy43 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy50 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy50 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy50 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy50 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy50 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy50 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy50 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy50 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy44 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy44 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy44 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy44 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy44 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy44 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy44 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy44 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy51 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy51 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy51 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy51 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy51 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy51 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy51 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy51 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy45 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy45 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy45 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy45 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy45 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy45 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy45 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy45 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy52 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy52 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy52 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy52 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy52 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy52 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy52 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy52 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy46 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy46 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy46 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy46 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy46 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy46 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy46 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy46 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy53 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy53 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy53 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy53 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy53 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy53 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy53 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy53 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy12 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy12 > * {
  width: 404px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy12 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy12 > * {
  width: 404px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy12 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy12 > * {
  width: 401px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy12 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy12 > * {
  width: 401px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy47 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy47 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy47 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy47 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy47 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy47 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy47 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy47 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy54 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy54 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy54 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy54 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy54 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy54 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy54 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy54 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy48 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy48 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy48 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy48 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy48 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy48 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy48 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy48 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy55 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy55 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy55 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy55 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy55 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy55 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy55 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy55 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy49 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy49 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy49 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy49 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy49 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy49 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy49 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy49 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy56 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy56 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy56 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy56 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy56 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy56 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy56 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy56 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy50 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy50 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy50 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy50 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy50 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy50 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy50 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy50 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy57 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy57 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy57 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy57 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy57 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy57 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy57 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy57 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy51 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy51 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy51 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy51 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy51 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy51 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy51 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy51 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy58 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy58 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy58 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy58 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy58 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy58 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy58 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy58 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy52 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy52 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy52 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy52 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy52 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy52 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy52 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy52 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy59 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy59 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy59 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy59 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy59 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy59 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy59 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy59 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy13 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy13 > * {
  width: 404px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy13 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy13 > * {
  width: 404px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy13 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy13 > * {
  width: 401px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy13 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy13 > * {
  width: 401px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy53 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy53 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy53 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy53 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy53 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy53 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy53 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy53 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy60 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy60 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy60 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy60 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy60 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy60 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy60 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy60 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy54 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy54 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy54 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy54 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy54 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy54 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy54 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy54 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy61 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy61 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy61 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy61 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy61 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy61 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy61 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy61 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy55 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy55 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy55 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy55 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy55 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy55 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy55 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy55 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy62 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy62 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy62 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy62 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy62 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy62 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy62 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy62 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy14 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy14 > * {
  width: 404px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy14 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 404px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy14 > * {
  width: 404px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy14 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy14 > * {
  width: 401px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy14 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 401px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy14 > * {
  width: 401px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy56 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy56 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy56 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy56 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy56 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy56 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy56 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy56 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy63 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy63 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy63 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy63 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy63 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy63 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy63 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy63 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy57 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy57 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy57 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy57 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy57 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy57 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy57 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy57 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy64 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy64 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy64 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy64 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy64 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy64 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy64 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy64 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy58 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy58 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy58 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy58 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy58 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy58 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy58 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy58 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy65 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy65 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy65 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy65 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy65 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy65 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy65 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy65 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy59 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy59 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy59 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy59 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy59 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy59 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy59 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy59 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy66 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy66 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy66 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy66 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy66 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy66 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy66 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy66 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy60 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy60 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy60 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy60 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy60 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy60 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy60 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy60 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy67 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy67 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy67 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy67 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy67 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy67 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy67 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy67 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy61 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy61 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy61 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy61 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy61 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy61 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy61 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy61 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy68 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy68 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy68 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy68 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy68 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy68 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy68 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy68 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy62 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy62 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy62 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy62 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy62 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy62 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy62 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy62 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy69 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy69 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy69 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy69 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy69 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy69 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy69 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy69 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy63 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy63 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy63 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy63 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy63 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy63 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy63 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy63 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy70 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy70 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy70 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy70 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy70 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy70 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy70 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy70 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 20px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .TCScreen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 20px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .TCScreen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 20px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .TCScreen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 20px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .TCScreen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    margin-left: 7.0%;
    margin-right: 9.1%;
    width: calc(83.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCTextCopy > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    margin-left: 5.1%;
    margin-right: 6.4%;
    width: calc(88.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCTextCopy > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    margin-left: 8.5%;
    margin-right: 5.4%;
    width: calc(86.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCTextCopy > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCTextCopy {
    overflow: hidden;
    position: relative;
    margin-top: 30px;
    margin-left: 6.6%;
    margin-right: 4.8%;
    width: calc(88.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCTextCopy > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCText2Copy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 7.0%;
    margin-right: 9.1%;
    width: calc(83.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText2Copy > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCText2Copy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 5.1%;
    margin-right: 6.4%;
    width: calc(88.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText2Copy > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCText2Copy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 8.5%;
    margin-right: 5.4%;
    width: calc(86.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText2Copy > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCText2Copy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 6.6%;
    margin-right: 4.8%;
    width: calc(88.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText2Copy > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCText3Copy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 7.0%;
    margin-right: 9.1%;
    width: calc(83.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText3Copy > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCText3Copy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 5.1%;
    margin-right: 6.4%;
    width: calc(88.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText3Copy > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCText3Copy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 8.5%;
    margin-right: 5.4%;
    width: calc(86.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText3Copy > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCText3Copy {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 6.6%;
    margin-right: 4.8%;
    width: calc(88.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText3Copy > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCText2Copy2 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 7.0%;
    margin-right: 9.1%;
    width: calc(83.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText2Copy2 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCText2Copy2 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 5.1%;
    margin-right: 6.4%;
    width: calc(88.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText2Copy2 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCText2Copy2 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 8.5%;
    margin-right: 5.4%;
    width: calc(86.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText2Copy2 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCText2Copy2 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 6.6%;
    margin-right: 4.8%;
    width: calc(88.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCText2Copy2 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy15 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 544px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy15 > * {
  width: 544px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy15 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 544px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy15 > * {
  width: 544px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy15 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 540px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy15 > * {
  width: 540px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy15 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 540px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy15 > * {
  width: 540px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy64 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy64 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy64 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy64 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy64 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy64 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy64 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy64 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy71 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy71 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy71 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy71 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy71 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy71 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy71 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy71 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy65 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy65 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy65 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy65 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy65 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy65 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy65 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy65 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy72 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy72 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy72 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy72 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy72 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy72 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy72 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy72 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy66 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy66 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy66 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy66 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy66 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy66 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy66 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy66 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy73 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy73 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy73 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy73 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy73 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy73 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy73 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy73 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy67 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy67 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy67 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy67 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy67 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy67 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy67 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy67 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy74 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy74 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy74 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy74 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy74 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy74 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy74 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy74 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy68 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy68 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy68 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy68 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy68 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy68 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy68 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy68 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy75 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy75 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy75 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy75 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy75 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy75 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy75 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy75 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy69 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy69 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy69 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy69 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy69 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy69 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy69 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy69 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy76 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy76 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy76 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy76 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy76 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy76 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy76 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy76 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy70 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy70 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy70 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy70 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy70 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy70 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy70 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy70 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy77 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy77 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy77 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy77 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy77 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy77 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy77 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy77 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy71 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy71 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy71 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy71 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy71 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy71 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy71 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy71 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy78 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy78 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy78 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy78 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy78 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy78 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy78 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy78 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy72 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy72 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy72 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy72 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy72 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy72 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy72 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy72 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy79 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy79 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy79 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy79 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy79 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy79 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy79 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy79 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy73 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy73 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy73 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy73 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy73 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy73 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy73 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy73 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy80 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy80 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy80 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy80 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy80 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy80 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy80 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy80 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy74 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy74 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy74 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy74 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy74 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy74 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy74 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy74 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy81 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy81 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy81 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy81 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy81 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy81 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy81 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy81 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy75 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy75 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy75 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy75 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy75 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy75 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy75 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy75 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy82 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy82 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy82 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy82 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy82 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy82 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy82 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy82 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy76 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy76 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy76 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy76 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy76 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy76 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy76 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy76 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy83 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy83 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy83 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy83 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy83 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy83 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy83 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy83 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy77 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy77 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy77 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy77 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy77 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy77 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy77 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy77 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy84 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy84 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy84 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy84 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy84 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy84 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy84 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy84 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy78 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy78 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy78 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy78 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy78 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy78 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy78 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy78 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy85 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy85 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy85 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy85 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy85 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy85 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy85 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy85 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy79 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy79 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy79 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy79 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy79 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy79 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy79 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy79 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy86 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy86 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy86 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy86 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy86 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy86 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy86 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy86 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy80 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy80 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy80 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy80 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy80 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy80 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy80 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy80 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy87 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy87 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy87 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy87 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy87 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy87 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy87 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy87 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy81 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy81 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy81 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy81 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy81 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy81 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy81 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 27.7%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy81 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy88 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 9.1%;
    width: calc(58.7% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy88 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy88 {
    overflow: hidden;
    position: relative;
    margin-top: -23px;
    margin-left: 32.2%;
    margin-right: 6.4%;
    width: calc(61.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy88 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy88 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 5.4%;
    width: calc(62.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy88 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy88 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 32.2%;
    margin-right: 4.8%;
    width: calc(63.0% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy88 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy82 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy82 > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy82 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 238px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy82 > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy82 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy82 > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy82 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 236px;
    margin-left: 20.3%;
  }
  .TCScreen > .layoutFlow > .elTCBulletNumberingCopy82 > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy89 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy89 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy89 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy89 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy89 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy89 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy89 {
    overflow: hidden;
    position: relative;
    margin-top: -22px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy89 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCBulletTextCopy90 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 23.3%;
    margin-right: 9.1%;
    width: calc(67.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy90 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy90 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 23.3%;
    margin-right: 6.4%;
    width: calc(70.4% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy90 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy90 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 23.3%;
    margin-right: 5.4%;
    width: calc(71.3% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy90 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCBulletTextCopy90 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 23.3%;
    margin-right: 4.8%;
    width: calc(71.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCBulletTextCopy90 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy16 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 544px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy16 > * {
  width: 544px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy16 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 544px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy16 > * {
  width: 544px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy16 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 540px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy16 > * {
  width: 540px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy16 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 540px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy16 > * {
  width: 540px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy17 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 9.1%;
    width: calc(74.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy17 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy17 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 6.4%;
    width: calc(77.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy17 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy17 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 5.4%;
    width: calc(78.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy17 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy17 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 4.8%;
    width: calc(79.2% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy17 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy18 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 9.1%;
    width: calc(74.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy18 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy18 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 6.4%;
    width: calc(77.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy18 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy18 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 5.4%;
    width: calc(78.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy18 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy18 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 4.8%;
    width: calc(79.2% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy18 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 544px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy19 > * {
  width: 544px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 544px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy19 > * {
  width: 544px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 540px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy19 > * {
  width: 540px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy19 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 540px;
    margin-left: 13.5%;
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy19 > * {
  width: 540px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy20 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 9.1%;
    width: calc(74.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy20 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy20 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 6.4%;
    width: calc(77.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy20 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy20 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 5.4%;
    width: calc(78.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy20 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy20 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 4.8%;
    width: calc(79.2% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy20 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy21 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 9.1%;
    width: calc(74.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy21 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy21 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 6.4%;
    width: calc(77.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy21 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy21 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 5.4%;
    width: calc(78.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy21 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy21 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 4.8%;
    width: calc(79.2% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy21 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy22 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 9.1%;
    width: calc(74.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy22 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy22 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 6.4%;
    width: calc(77.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy22 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy22 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 5.4%;
    width: calc(78.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy22 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy22 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 4.8%;
    width: calc(79.2% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy22 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy23 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 9.1%;
    width: calc(74.9% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy23 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy23 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 6.4%;
    width: calc(77.6% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy23 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy23 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 5.4%;
    width: calc(78.5% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy23 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy23 {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 16.0%;
    margin-right: 4.8%;
    width: calc(79.2% - 0px);
  }
  .TCScreen > .layoutFlow > .elTCSmallHeadingTextCopy23 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
  }
  .TCScreen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
  }
  .TCScreen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
  }
  .TCScreen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
  }
  .TCScreen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 20px;
    width: 343px;
    margin-left: 7.0%;
  }
  .TCScreen > .layoutFlow > .elText > * {
  width: 343px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 20px;
    width: 343px;
    margin-left: 5.1%;
  }
  .TCScreen > .layoutFlow > .elText > * {
  width: 343px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 20px;
    width: 341px;
    margin-left: 8.5%;
  }
  .TCScreen > .layoutFlow > .elText > * {
  width: 341px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 20px;
    width: 341px;
    margin-left: 6.6%;
  }
  .TCScreen > .layoutFlow > .elText > * {
  width: 341px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .elNameText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 7.0%;
  }
  .TCScreen > .layoutFlow > .elNameText > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .elNameText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: 5.1%;
  }
  .TCScreen > .layoutFlow > .elNameText > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .elNameText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 236px;
    margin-left: 8.5%;
  }
  .TCScreen > .layoutFlow > .elNameText > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .elNameText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 236px;
    margin-left: 6.6%;
  }
  .TCScreen > .layoutFlow > .elNameText > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .layoutFlow > .flowRow > .elField {
    position: absolute;
    margin-top: 0px;
    width: 226px;
    margin-left: 330px;
  }
  .TCScreen > .layoutFlow > .flowRow > .elField > * {
  width: 226px;
  height: 34px;
  }

  .flowRow_TCScreen_elField_1010406279 {
  }

@media (min-width: 568px) {
  .TCScreen > .layoutFlow > .flowRow > .elField {
    position: absolute;
    margin-top: 0px;
    width: 226px;
    margin-left: 330px;
  }
  .TCScreen > .layoutFlow > .flowRow > .elField > * {
  width: 226px;
  height: 34px;
  }

  .flowRow_TCScreen_elField_1010406279 {
  }

}
@media (min-width: 768px) {
  .TCScreen > .layoutFlow > .flowRow > .elField {
    position: absolute;
    margin-top: 0px;
    width: 225px;
    margin-left: 328px;
  }
  .TCScreen > .layoutFlow > .flowRow > .elField > * {
  width: 225px;
  height: 34px;
  }

  .flowRow_TCScreen_elField_1010406279 {
  }

}
@media (min-width: 1024px) {
  .TCScreen > .layoutFlow > .flowRow > .elField {
    position: absolute;
    margin-top: 0px;
    width: 225px;
    margin-left: 328px;
  }
  .TCScreen > .layoutFlow > .flowRow > .elField > * {
  width: 225px;
  height: 34px;
  }

  .flowRow_TCScreen_elField_1010406279 {
  }

}

.TCScreen .elBkgdOutlineOverall {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.TCScreen .elBkgdOutlineOverall:focus {
  box-shadow: 0 0 5px rgba(56, 83, 128, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .TCScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
@media (min-width: 568px) {
  .TCScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .TCScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 1024px) {
  .TCScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}

.TCScreen .elFab {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.TCScreen .elFab:focus {
  box-shadow: 0 0 6px rgba(66, 66, 66, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .TCScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    position: fixed;
    right: 23px;
    bottom: 23px;
    width: 68px;
    height: 68px;
  }
@media (min-width: 568px) {
  .TCScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    right: 23px;
    bottom: 23px;
    width: 68px;
    height: 68px;
  }
}
@media (min-width: 768px) {
  .TCScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    right: 22px;
    bottom: 22px;
    width: 68px;
    height: 68px;
  }
}
@media (min-width: 1024px) {
  .TCScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    right: 22px;
    bottom: 22px;
    width: 68px;
    height: 68px;
  }
}

.TCScreen .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.TCScreen .elIconButton:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .TCScreen > .screenFgContainer > .foreground > .elIconButton {
    position: absolute;
    left: 5px;
    top: 4px;
    width: 41px;
    height: 36px;
  }
@media (min-width: 568px) {
  .TCScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}
@media (min-width: 768px) {
  .TCScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}
@media (min-width: 1024px) {
  .TCScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .screenFgContainer > .foreground > .elTwittercircle {
    position: fixed;
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .TCScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .TCScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .TCScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .screenFgContainer > .foreground > .elTwitter {
    position: fixed;
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .TCScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .TCScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .TCScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    position: fixed;
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .TCScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .TCScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .TCScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .screenFgContainer > .foreground > .elIg {
    position: fixed;
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .TCScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .TCScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .TCScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    position: fixed;
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .TCScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .TCScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .TCScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .screenFgContainer > .foreground > .elFb {
    position: fixed;
    right: 101px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .TCScreen > .screenFgContainer > .foreground > .elFb {
    right: 101px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .TCScreen > .screenFgContainer > .foreground > .elFb {
    right: 100px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .TCScreen > .screenFgContainer > .foreground > .elFb {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}

.TCScreen .elButtonLearnMore {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.TCScreen .elButtonLearnMore:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .TCScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    position: fixed;
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .TCScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .TCScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .TCScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}

.TCScreen .elButtonAboutUs {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.TCScreen .elButtonAboutUs:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .TCScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    position: fixed;
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .TCScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .TCScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .TCScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .screenFgContainer > .foreground > .elLogo {
    position: absolute;
    left: 189px;
    top: 7px;
    width: 121px;
    height: 32px;
  }
@media (min-width: 568px) {
  .TCScreen > .screenFgContainer > .foreground > .elLogo {
    left: 50px;
    top: 7px;
    width: 121px;
    height: 32px;
  }
}
@media (min-width: 768px) {
  .TCScreen > .screenFgContainer > .foreground > .elLogo {
    left: 188px;
    top: 7px;
    width: 120px;
    height: 32px;
  }
}
@media (min-width: 1024px) {
  .TCScreen > .screenFgContainer > .foreground > .elLogo {
    left: 188px;
    top: 7px;
    width: 120px;
    height: 32px;
  }
}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    position: absolute;
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
@media (min-width: 568px) {
  .TCScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
}
@media (min-width: 768px) {
  .TCScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}
@media (min-width: 1024px) {
  .TCScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}


/* Narrowest size for this element's responsive layout */
  .TCScreen > .screenFgContainer > .foreground > .elBeta {
    position: absolute;
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
@media (min-width: 568px) {
  .TCScreen > .screenFgContainer > .foreground > .elBeta {
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 768px) {
  .TCScreen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 1024px) {
  .TCScreen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}

/* This component has a fixed-size layout */
  .UserInfo {
    min-height: 36px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 188px */
    height: 100%;
  }
  .UserInfo > .background > .containerMinHeight {
    min-height: 36px;
  }


  .UserInfo > .foreground > .state0_elTextBlock {
    overflow: hidden;
    position: absolute;
    left: 120px;
    top: 8px;
    width: 62px;
    height: 22px;
  }

/* This component has a fixed-size layout */
  .UserInfo {
    min-height: 36px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 188px */
    height: 100%;
  }
  .UserInfo > .background > .containerMinHeight {
    min-height: 36px;
  }


  .UserInfo > .foreground > .state1_elImage {
    position: absolute;
    left: 154px;
    top: 7px;
    width: 22px;
    height: 22px;
  }


  .UserInfo > .foreground > .state1_elTextBlock {
    overflow: hidden;
    position: absolute;
    left: 79px;
    top: 11px;
    width: 68px;
    height: 22px;
  }

/* This component has a fixed-size layout */
  .Drawer1 {
    min-height: 113px;  /* Minimum height to ensure layout flow is visible */
    width: 181px; /* This component has a fixed size */
  }
  .Drawer1 > .background > .containerMinHeight {
    min-height: 113px;
  }


  .Drawer1 > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

.Drawer1 .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer1 .elButton button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



  .Drawer1 > .layoutFlow > .elButton {
    line-height: 40px;
    position: relative;
    margin-top: 18px;
    width: 144px;
    margin-left: 20px;
  }
  .Drawer1 > .layoutFlow > .elButton > * {
  width: 144px;
  height: 40px;
  }


.Drawer1 .elButtonCopy button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Drawer1 .elButtonCopy button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



  .Drawer1 > .layoutFlow > .elButtonCopy {
    line-height: 40px;
    position: relative;
    margin-top: 18px;
    width: 144px;
    margin-left: 20px;
  }
  .Drawer1 > .layoutFlow > .elButtonCopy > * {
  width: 144px;
  height: 40px;
  }



/* Narrowest size for this element's responsive layout */
  .LoginHomeScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .LoginHomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .LoginHomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .LoginHomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginHomeScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 121px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginHomeScreen > .layoutFlow > .elText > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .LoginHomeScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 120px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginHomeScreen > .layoutFlow > .elText > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .LoginHomeScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 120px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginHomeScreen > .layoutFlow > .elText > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .LoginHomeScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 120px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginHomeScreen > .layoutFlow > .elText > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .LoginHomeScreen > .layoutFlow > .elTextCopy2 {
    position: relative;
    margin-top: 20px;
    width: 282px;
    margin-left: calc(50.0% - 141px);
  }
  .LoginHomeScreen > .layoutFlow > .elTextCopy2 > * {
  width: 282px;
  }

@media (min-width: 568px) {
  .LoginHomeScreen > .layoutFlow > .elTextCopy2 {
    position: relative;
    margin-top: 20px;
    width: 282px;
    margin-left: calc(50.0% - 141px);
  }
  .LoginHomeScreen > .layoutFlow > .elTextCopy2 > * {
  width: 282px;
  }

}
@media (min-width: 768px) {
  .LoginHomeScreen > .layoutFlow > .elTextCopy2 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LoginHomeScreen > .layoutFlow > .elTextCopy2 > * {
  width: 280px;
  }

}
@media (min-width: 1024px) {
  .LoginHomeScreen > .layoutFlow > .elTextCopy2 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LoginHomeScreen > .layoutFlow > .elTextCopy2 > * {
  width: 280px;
  }

}


/* Narrowest size for this element's responsive layout */
  .LoginHomeScreen > .layoutFlow > .elTextCopy3 {
    position: relative;
    margin-top: 20px;
    width: 282px;
    margin-left: calc(50.0% - 141px);
  }
  .LoginHomeScreen > .layoutFlow > .elTextCopy3 > * {
  width: 282px;
  }

@media (min-width: 568px) {
  .LoginHomeScreen > .layoutFlow > .elTextCopy3 {
    position: relative;
    margin-top: 20px;
    width: 282px;
    margin-left: calc(50.0% - 141px);
  }
  .LoginHomeScreen > .layoutFlow > .elTextCopy3 > * {
  width: 282px;
  }

}
@media (min-width: 768px) {
  .LoginHomeScreen > .layoutFlow > .elTextCopy3 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LoginHomeScreen > .layoutFlow > .elTextCopy3 > * {
  width: 280px;
  }

}
@media (min-width: 1024px) {
  .LoginHomeScreen > .layoutFlow > .elTextCopy3 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LoginHomeScreen > .layoutFlow > .elTextCopy3 > * {
  width: 280px;
  }

}


/* Narrowest size for this element's responsive layout */
  .LoginHomeScreen > .layoutFlow > .elText2 {
    position: relative;
    margin-top: 20px;
    width: 282px;
    margin-left: calc(50.0% - 141px);
  }
  .LoginHomeScreen > .layoutFlow > .elText2 > * {
  width: 282px;
  }

@media (min-width: 568px) {
  .LoginHomeScreen > .layoutFlow > .elText2 {
    position: relative;
    margin-top: 20px;
    width: 282px;
    margin-left: calc(50.0% - 141px);
  }
  .LoginHomeScreen > .layoutFlow > .elText2 > * {
  width: 282px;
  }

}
@media (min-width: 768px) {
  .LoginHomeScreen > .layoutFlow > .elText2 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LoginHomeScreen > .layoutFlow > .elText2 > * {
  width: 280px;
  }

}
@media (min-width: 1024px) {
  .LoginHomeScreen > .layoutFlow > .elText2 {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LoginHomeScreen > .layoutFlow > .elText2 > * {
  width: 280px;
  }

}


/* Narrowest size for this element's responsive layout */
  .LoginHomeScreen > .layoutFlow > .elTextCopy {
    position: relative;
    margin-top: 5px;
    width: 282px;
    margin-left: calc(50.0% - 141px);
  }
  .LoginHomeScreen > .layoutFlow > .elTextCopy > * {
  width: 282px;
  }

@media (min-width: 568px) {
  .LoginHomeScreen > .layoutFlow > .elTextCopy {
    position: relative;
    margin-top: 5px;
    width: 282px;
    margin-left: calc(50.0% - 141px);
  }
  .LoginHomeScreen > .layoutFlow > .elTextCopy > * {
  width: 282px;
  }

}
@media (min-width: 768px) {
  .LoginHomeScreen > .layoutFlow > .elTextCopy {
    position: relative;
    margin-top: 5px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LoginHomeScreen > .layoutFlow > .elTextCopy > * {
  width: 280px;
  }

}
@media (min-width: 1024px) {
  .LoginHomeScreen > .layoutFlow > .elTextCopy {
    position: relative;
    margin-top: 5px;
    width: 280px;
    margin-left: calc(50.0% - 140px);
  }
  .LoginHomeScreen > .layoutFlow > .elTextCopy > * {
  width: 280px;
  }

}


/* Narrowest size for this element's responsive layout */
  .LoginHomeScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 50px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginHomeScreen > .layoutFlow > .elFirebaseLogin > * {
    width: 100%;
  height: 83px;
  }

@media (min-width: 568px) {
  .LoginHomeScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 50px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginHomeScreen > .layoutFlow > .elFirebaseLogin > * {
    width: 100%;
  height: 83px;
  }

}
@media (min-width: 768px) {
  .LoginHomeScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 50px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginHomeScreen > .layoutFlow > .elFirebaseLogin > * {
    width: 100%;
  height: 82px;
  }

}
@media (min-width: 1024px) {
  .LoginHomeScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 50px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginHomeScreen > .layoutFlow > .elFirebaseLogin > * {
    width: 100%;
  height: 82px;
  }

}

.LoginHomeScreen .elBkgdOutlineOverall {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.LoginHomeScreen .elBkgdOutlineOverall:focus {
  box-shadow: 0 0 5px rgba(56, 83, 128, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .LoginHomeScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
@media (min-width: 568px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 1024px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginHomeScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    position: absolute;
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
@media (min-width: 568px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
}
@media (min-width: 768px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}
@media (min-width: 1024px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginHomeScreen > .screenFgContainer > .foreground > .elBeta {
    position: absolute;
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
@media (min-width: 568px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elBeta {
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 768px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 1024px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginHomeScreen > .screenFgContainer > .foreground > .elTwittercircle {
    position: fixed;
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginHomeScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    position: fixed;
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginHomeScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    position: fixed;
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}

.LoginHomeScreen .elButtonAboutUs {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.LoginHomeScreen .elButtonAboutUs:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .LoginHomeScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    position: fixed;
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}

.LoginHomeScreen .elButtonLearnMore {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.LoginHomeScreen .elButtonLearnMore:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .LoginHomeScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    position: fixed;
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .LoginHomeScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .background > .elBackground {
    position: absolute;
    left: -54px;
    right: 54px;
    top: 0px;
    width: calc(100.0% - -54px - 54px);
    height: 1409px;
  }
@media (min-width: 568px) {
  .HomeScreen > .background > .elBackground {
    left: -54px;
    right: 54px;
    top: 0px;
    width: calc(100.0% - -54px - 54px);
    height: 1409px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .background > .elBackground {
    left: 0px;
    right: 54px;
    top: 0px;
    width: calc(100.0% - 0px - 54px);
    height: 1400px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .background > .elBackground {
    left: -54px;
    right: 54px;
    top: 0px;
    width: calc(100.0% - -54px - 54px);
    height: 1400px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .HomeScreen > .layoutFlow > .elSpacer > * {
  width: 10px;
  height: 50px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .HomeScreen > .layoutFlow > .elSpacer > * {
  width: 10px;
  height: 50px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .HomeScreen > .layoutFlow > .elSpacer > * {
  width: 10px;
  height: 50px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .HomeScreen > .layoutFlow > .elSpacer > * {
  width: 10px;
  height: 50px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elCardTopSectionCopy {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elCardTopSectionCopy > * {
    width: 100%;
  height: 0px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elCardTopSectionCopy {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elCardTopSectionCopy > * {
    width: 100%;
  height: 0px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elCardTopSectionCopy {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elCardTopSectionCopy > * {
    width: 100%;
  height: 0px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elCardTopSectionCopy {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 1px;
    width: calc(100.0% - 1px);
  }
  .HomeScreen > .layoutFlow > .elCardTopSectionCopy > * {
    width: 100%;
  height: 0px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elCardTopSection {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elCardTopSection > * {
    width: 100%;
  height: 650px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elCardTopSection {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elCardTopSection > * {
    width: 100%;
  height: 504px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elCardTopSection {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elCardTopSection > * {
    width: 100%;
  height: 504px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elCardTopSection {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 1px;
    width: calc(100.0% - 1px);
  }
  .HomeScreen > .layoutFlow > .elCardTopSection > * {
    width: 100%;
  height: 504px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .flowRow > .elImage1 {
    position: absolute;
    margin-top: -508px;
    width: 100%;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elImage1 > * {
  margin-left: auto;
  width: 0px;
  margin-right: 0px;
  height: 0px;
  }

  .flowRow_HomeScreen_elImage1_1298597997 {
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .flowRow > .elImage1 {
    position: absolute;
    margin-top: -508px;
    width: 100%;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elImage1 > * {
  margin-left: auto;
  width: 380px;
  margin-right: 83px;
  height: 190px;
  }

  .flowRow_HomeScreen_elImage1_1298597997 {
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .flowRow > .elImage1 {
    position: absolute;
    margin-top: -504px;
    width: 100%;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elImage1 > * {
  margin-left: auto;
  width: 378px;
  margin-right: 82px;
  height: 189px;
  }

  .flowRow_HomeScreen_elImage1_1298597997 {
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .flowRow > .elImage1 {
    position: absolute;
    margin-top: -504px;
    width: 100%;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elImage1 > * {
  margin-left: auto;
  width: 378px;
  /* margin-right: 82px; */
  margin-right: calc(50.0% - 430px);
  height: 189px;
  }

  .flowRow_HomeScreen_elImage1_1298597997 {
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .flowRow > .elImage2 {
    position: absolute;
    top: -508px;
    width: 100%;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elImage2 > * {
  margin-left: auto;
  width: 0px;
  margin-right: 0px;
  height: 0px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .flowRow > .elImage2 {
    position: absolute;
    top: -508px;
    width: 100%;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elImage2 > * {
  margin-left: auto;
  width: 190px;
  margin-right: 0px;
  height: 306px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .flowRow > .elImage2 {
    position: absolute;
    top: -504px;
    width: 100%;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elImage2 > * {
  margin-left: auto;
  width: 188px;
  margin-right: 0px;
  height: 304px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .flowRow > .elImage2 {
    position: absolute;
    top: -504px;
    width: 100%;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elImage2 > * {
  margin-left: auto;
  width: 188px;
  /* margin-right: 0px; */
  margin-right: calc(50.0% - 512px);
  height: 304px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .flowRow > .elImage3 {
    position: absolute;
    top: 400px;
    width: 100%;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elImage3 > * {
  margin-left: auto;
  width: 1px;
  margin-right: 0px;
  height: 0px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .flowRow > .elImage3 {
    position: absolute;
    top: -386px;
    width: 100%;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elImage3 > * {
  margin-left: auto;
  width: 498px;
  margin-right: 0px;
  height: 386px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .flowRow > .elImage3 {
    position: absolute;
    top: -384px;
    width: 100%;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elImage3 > * {
  margin-left: auto;
  width: 496px;
  margin-right: 0px;
  height: 384px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .flowRow > .elImage3 {
    position: absolute;
    top: -384px;
    width: 100%;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elImage3 > * {
  margin-left: auto;
  width: 496px;
  /* margin-right: 0px; */
  margin-right: calc(50.0% - 512px);
  height: 384px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .flowRow > .elImage4 {
    position: absolute;
    top: 194px;
    width: 239px;
    margin-left: 494px;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elImage4 > * {
  width: 0px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .flowRow > .elImage4 {
    position: absolute;
    top: -184px;
    width: 228px;
    margin-left: 0px;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elImage4 > * {
  width: 228px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .flowRow > .elImage4 {
    position: absolute;
    top: -184px;
    width: 228px;
    margin-left: 0px;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elImage4 > * {
  width: 228px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .flowRow > .elImage4 {
    /* position: absolute; */
    top: -184px;
    width: 228px;
    /* margin-left: 0px; */
    /* margin-right: -512px; */
    /* text-align: center;
    padding: -512px; */
    position: absolute;
    margin-left: calc(50.0% - 512px);
  }
  .HomeScreen > .layoutFlow > .flowRow > .elImage4 > * {
  width: 228px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elImage5 {
    position: relative;
    margin-top: -350px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elImage5 > * {
    width: 100%;
  height: 500px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elImage5 {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elImage5 > * {
    width: 100%;
  height: 0px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elImage5 {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elImage5 > * {
    width: 100%;
  height: 0px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elImage5 {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elImage5 > * {
    width: 100%;
  height: 0px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextMain {
    position: relative;
    margin-top: -770px;
    width: 280px;
    margin-left: 15px;
  }
  .HomeScreen > .layoutFlow > .elTextMain > * {
  width: 280px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextMain {
    position: relative;
    margin-top: -450px;
    width: 374px;
    margin-left: 20px;
  }
  .HomeScreen > .layoutFlow > .elTextMain > * {
  width: 374px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextMain {
    position: relative;
    margin-top: -465px;
    width: 371px;
    margin-left: 70px;
  }
  .HomeScreen > .layoutFlow > .elTextMain > * {
  width: 371px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextMain {
    position: relative;
    margin-top: -447px;
    width: 371px;
    /* margin-left: 188px; */
    /* position: absolute; */
    margin-left: calc(50.0% - 324px);
  }
  .HomeScreen > .layoutFlow > .elTextMain > * {
  width: 371px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextSub {
    position: relative;
    margin-top: 20px;
    width: 280px;
    margin-left: 15px;
  }
  .HomeScreen > .layoutFlow > .elTextSub > * {
  width: 280px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextSub {
    position: relative;
    margin-top: 11px;
    width: 385px;
    margin-left: 20px;
  }
  .HomeScreen > .layoutFlow > .elTextSub > * {
  width: 385px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextSub {
    position: relative;
    margin-top: 11px;
    width: 383px;
    margin-left: 70px;
  }
  .HomeScreen > .layoutFlow > .elTextSub > * {
  width: 383px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextSub {
    position: relative;
    margin-top: 11px;
    width: 383px;
    /* margin-left: 188px; */
    margin-left: calc(50.0% - 324px);
  }
  .HomeScreen > .layoutFlow > .elTextSub > * {
  width: 383px;
  }

}


.HomeScreen > .layoutFlow > .elZipCodeExplore input {
  color: #919191;
}

.HomeScreen > .layoutFlow > .elZipCodeExplore input::input-placeholder {
  color: rgba(145, 145, 145, 0.5000);
}

.HomeScreen > .layoutFlow > .elZipCodeExplore input::placeholder {
  color: rgba(145, 145, 145, 0.5000);
}

/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elZipCodeExplore {
    position: relative;
    margin-top: 20px;
    width: 340px;
    margin-left: 15px;
  }
  .HomeScreen > .layoutFlow > .elZipCodeExplore > * {
  width: 340px;
  height: 34px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elZipCodeExplore {
    position: relative;
    margin-top: 25px;
    width: 367px;
    margin-left: 20px;
  }
  .HomeScreen > .layoutFlow > .elZipCodeExplore > * {
  width: 367px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elZipCodeExplore {
    position: relative;
    margin-top: 25px;
    width: 320px;
    margin-left: 70px;
  }
  .HomeScreen > .layoutFlow > .elZipCodeExplore > * {
  width: 320px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elZipCodeExplore {
    position: relative;
    margin-top: 25px;
    width: 365px;
    /* margin-left: 188px; */
    margin-left: calc(50.0% - 324px);
  }
  .HomeScreen > .layoutFlow > .elZipCodeExplore > * {
  width: 365px;
  height: 34px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .flowRow > .elZipAutoComplete {
    position: absolute;
    margin-top: 0px;
    width: 320px;
    margin-left: 15px;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elZipAutoComplete > * {
  width: 320px;
  }

  .flowRow_HomeScreen_elZipAutoComplete_758871 {
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .flowRow > .elZipAutoComplete {
    position: absolute;
    margin-top: 0px;
    width: 320px;
    margin-left: 20px;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elZipAutoComplete > * {
  width: 320px;
  }

  .flowRow_HomeScreen_elZipAutoComplete_758871 {
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .flowRow > .elZipAutoComplete {
    position: absolute;
    margin-top: 0px;
    width: 318px;
    margin-left: 70px;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elZipAutoComplete > * {
  width: 318px;
  }

  .flowRow_HomeScreen_elZipAutoComplete_758871 {
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .flowRow > .elZipAutoComplete {
    position: absolute;
    margin-top: 0px;
    width: 365px;
    margin-left: 188px;
  }
  .HomeScreen > .layoutFlow > .flowRow > .elZipAutoComplete > * {
  width: 365px;
  }

  .flowRow_HomeScreen_elZipAutoComplete_758871 {
  }

}

.HomeScreen .elSearchButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.HomeScreen .elSearchButton button:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elSearchButton {
    position: relative;
    margin-top: -34px;
    width: 70px;
    margin-left: 295px;
  }
  .HomeScreen > .layoutFlow > .elSearchButton > * {
  width: 70px;
  height: 35px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elSearchButton {
    position: relative;
    margin-top: -34px;
    width: 70px;
    margin-left: 377px;
  }
  .HomeScreen > .layoutFlow > .elSearchButton > * {
  width: 70px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elSearchButton {
    position: relative;
    margin-top: -35px;
    width: 70px;
    margin-left: 390px;
  }
  .HomeScreen > .layoutFlow > .elSearchButton > * {
  width: 70px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elSearchButton {
    position: relative;
    margin-top: -34px;
    width: 70px;
    /* margin-left: 553px; */

    margin-left: calc(50.0% + 41px);
  }
  .HomeScreen > .layoutFlow > .elSearchButton > * {
  width: 70px;
  height: 34px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextSubSub {
    position: relative;
    margin-top: 10px;
    width: 323px;
    margin-left: 70px;
  }
  .HomeScreen > .layoutFlow > .elTextSubSub > * {
  width: 323px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextSubSub {
    position: relative;
    margin-top: 10px;
    width: 323px;
    margin-left: 40px;
  }
  .HomeScreen > .layoutFlow > .elTextSubSub > * {
  width: 323px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextSubSub {
    position: relative;
    margin-top: 10px;
    width: 321px;
    margin-left: 90px;
  }
  .HomeScreen > .layoutFlow > .elTextSubSub > * {
  width: 321px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextSubSub {
    position: relative;
    margin-top: 10px;
    width: 321px;
    /* margin-left: 270px; */
    margin-left: calc(50.0% - 242px);
  }
  .HomeScreen > .layoutFlow > .elTextSubSub > * {
  width: 321px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextAlreadyLoggedIn {
    position: relative;
    margin-top: 130px;
    width: 280px;
    margin-left: 15px;
  }
  .HomeScreen > .layoutFlow > .elTextAlreadyLoggedIn > * {
  width: 280px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextAlreadyLoggedIn {
    position: relative;
    margin-top: 50px;
    width: 385px;
    margin-left: 20px;
  }
  .HomeScreen > .layoutFlow > .elTextAlreadyLoggedIn > * {
  width: 385px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextAlreadyLoggedIn {
    position: relative;
    margin-top: 50px;
    width: 383px;
    margin-left: 70px;
  }
  .HomeScreen > .layoutFlow > .elTextAlreadyLoggedIn > * {
  width: 383px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextAlreadyLoggedIn {
    position: relative;
    margin-top: 50px;
    width: 383px;
    margin-left: 188px;
  }
  .HomeScreen > .layoutFlow > .elTextAlreadyLoggedIn > * {
  width: 383px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 30px;
    width: 207px;
    margin-left: 252px;
  }
  .HomeScreen > .layoutFlow > .elFirebaseLogin > * {
  width: 207px;
  height: 48px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 30px;
    width: 207px;
    margin-left: 252px;
  }
  .HomeScreen > .layoutFlow > .elFirebaseLogin > * {
  width: 207px;
  height: 48px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 30px;
    width: 206px;
    margin-left: 250px;
  }
  .HomeScreen > .layoutFlow > .elFirebaseLogin > * {
  width: 206px;
  height: 47px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 30px;
    width: 206px;
    margin-left: 250px;
  }
  .HomeScreen > .layoutFlow > .elFirebaseLogin > * {
  width: 206px;
  height: 47px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextNoZipMatch {
    position: relative;
    margin-top: 130px;
    width: 280px;
    margin-left: 15px;
  }
  .HomeScreen > .layoutFlow > .elTextNoZipMatch > * {
  width: 280px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextNoZipMatch {
    position: relative;
    margin-top: 5px;
    width: 282px;
    margin-left: 20px;
  }
  .HomeScreen > .layoutFlow > .elTextNoZipMatch > * {
  width: 282px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextNoZipMatch {
    position: relative;
    margin-top: 5px;
    width: 280px;
    margin-left: 70px;
  }
  .HomeScreen > .layoutFlow > .elTextNoZipMatch > * {
  width: 280px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextNoZipMatch {
    position: relative;
    margin-top: 5px;
    width: 280px;
    margin-left: 220px;
  }
  .HomeScreen > .layoutFlow > .elTextNoZipMatch > * {
  width: 280px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elEmailThankYou {
    position: relative;
    margin-top: 130px;
    width: 280px;
    margin-left: 15px;
  }
  .HomeScreen > .layoutFlow > .elEmailThankYou > * {
  width: 280px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elEmailThankYou {
    position: relative;
    margin-top: 20px;
    width: 385px;
    margin-left: 20px;
  }
  .HomeScreen > .layoutFlow > .elEmailThankYou > * {
  width: 385px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elEmailThankYou {
    position: relative;
    margin-top: 20px;
    width: 383px;
    margin-left: 70px;
  }
  .HomeScreen > .layoutFlow > .elEmailThankYou > * {
  width: 383px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elEmailThankYou {
    position: relative;
    margin-top: 20px;
    width: 383px;
    margin-left: 188px;
  }
  .HomeScreen > .layoutFlow > .elEmailThankYou > * {
  width: 383px;
  }

}


.HomeScreen > .layoutFlow > .elEmailWaitList input {
  color: #919191;
}

.HomeScreen > .layoutFlow > .elEmailWaitList input::input-placeholder {
  color: rgba(145, 145, 145, 0.5000);
}

.HomeScreen > .layoutFlow > .elEmailWaitList input::placeholder {
  color: rgba(145, 145, 145, 0.5000);
}

/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elEmailWaitList {
    position: relative;
    margin-top: 90px;
    width: 250px;
    margin-left: 15px;
  }
  .HomeScreen > .layoutFlow > .elEmailWaitList > * {
  width: 250px;
  height: 34px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elEmailWaitList {
    position: relative;
    margin-top: 10px;
    width: 252px;
    margin-left: 20px;
  }
  .HomeScreen > .layoutFlow > .elEmailWaitList > * {
  width: 252px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elEmailWaitList {
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: 70px;
  }
  .HomeScreen > .layoutFlow > .elEmailWaitList > * {
  width: 320px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elEmailWaitList {
    position: relative;
    margin-top: 10px;
    width: 250px;
    margin-left: 188px;
  }
  .HomeScreen > .layoutFlow > .elEmailWaitList > * {
  width: 250px;
  height: 34px;
  }

}

.HomeScreen .elEmailSubmitButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.HomeScreen .elEmailSubmitButton button:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elEmailSubmitButton {
    position: relative;
    margin-top: -34px;
    width: 70px;
    margin-left: 265px;
  }
  .HomeScreen > .layoutFlow > .elEmailSubmitButton > * {
  width: 70px;
  height: 35px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elEmailSubmitButton {
    position: relative;
    margin-top: -34px;
    width: 70px;
    margin-left: 272px;
  }
  .HomeScreen > .layoutFlow > .elEmailSubmitButton > * {
  width: 70px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elEmailSubmitButton {
    position: relative;
    margin-top: -35px;
    width: 70px;
    margin-left: 390px;
  }
  .HomeScreen > .layoutFlow > .elEmailSubmitButton > * {
  width: 70px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elEmailSubmitButton {
    position: relative;
    margin-top: -34px;
    width: 70px;
    margin-left: 438px;
  }
  .HomeScreen > .layoutFlow > .elEmailSubmitButton > * {
  width: 70px;
  height: 34px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 5px;
    width: 238px;
    margin-left: 189px;
  }
  .HomeScreen > .layoutFlow > .elValidator > * {
  width: 238px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 5px;
    width: 238px;
    margin-left: 189px;
  }
  .HomeScreen > .layoutFlow > .elValidator > * {
  width: 238px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 5px;
    width: 236px;
    margin-left: 188px;
  }
  .HomeScreen > .layoutFlow > .elValidator > * {
  width: 236px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: 5px;
    width: 236px;
    margin-left: 188px;
  }
  .HomeScreen > .layoutFlow > .elValidator > * {
  width: 236px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextTypesOfCare {
    position: relative;
    margin-top: 550px;
    width: 252px;
    margin-left: calc(50.0% + -126px);
  }
  .HomeScreen > .layoutFlow > .elTextTypesOfCare > * {
  width: 252px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextTypesOfCare {
    position: relative;
    margin-top: 252px;
    width: 252px;
    margin-left: calc(50.0% + -126px);
  }
  .HomeScreen > .layoutFlow > .elTextTypesOfCare > * {
  width: 252px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextTypesOfCare {
    position: relative;
    margin-top: 268px;
    width: 250px;
    margin-left: calc(50.0% + -125px);
  }
  .HomeScreen > .layoutFlow > .elTextTypesOfCare > * {
  width: 250px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextTypesOfCare {
    position: relative;
    margin-top: 250px;
    width: 250px;
    margin-left: calc(50.0% + -125px);
  }
  .HomeScreen > .layoutFlow > .elTextTypesOfCare > * {
  width: 250px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elImageIL {
    position: relative;
    margin-top: 0px;
    width: 36px;
    margin-left: calc(50.0% - 18px);
  }
  .HomeScreen > .layoutFlow > .elImageIL > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elImageIL {
    position: relative;
    margin-top: 35px;
    width: 36px;
    margin-left: calc(50.0% - 18px);
  }
  .HomeScreen > .layoutFlow > .elImageIL > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elImageIL {
    position: relative;
    margin-top: 25px;
    width: 36px;
    margin-left: calc(22.0% - 18px);
  }
  .HomeScreen > .layoutFlow > .elImageIL > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elImageIL {
    position: relative;
    /* margin-top: 25px; */
    margin-top: 0px;
    width: 35px;
    /* The following = 225 - 18 */
    /* margin-left: calc(22.0% - 18px); */
    /* The following = 190 = 225 - 35 */
    margin-left: calc(50.0% - 322px);
  }
  .HomeScreen > .layoutFlow > .elImageIL > * {
  width: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elImageAL {
    position: relative;
    margin-top: 0px;
    width: 36px;
    margin-left: calc(50.0% - 18px);
  }
  .HomeScreen > .layoutFlow > .elImageAL > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elImageAL {
    position: relative;
    margin-top: 120px;
    width: 36px;
    margin-left: calc(50.0% - 18px);
  }
  .HomeScreen > .layoutFlow > .elImageAL > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elImageAL {
    position: relative;
    margin-top: -41px;
    width: 36px;
    margin-left: calc(40.0% - 18px);
  }
  .HomeScreen > .layoutFlow > .elImageAL > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elImageAL {
    position: relative;
    margin-top: -40px;
    width: 35px;
    /* The following = 409 - 18 */
    margin-left: calc(40.0% - 18px);
    /* The following = 409 - 35 = 374 */
    margin-left: calc(50.0% - 138px);
    /* margin-left: calc(50.0% - 103px); */
  }
  .HomeScreen > .layoutFlow > .elImageAL > * {
  width: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elImageMC {
    position: relative;
    margin-top: 0px;
    width: 36px;
    margin-left: calc(50.0% - 18px);
  }
  .HomeScreen > .layoutFlow > .elImageMC > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elImageMC {
    position: relative;
    margin-top: 120px;
    width: 36px;
    margin-left: calc(50.0% - 18px);
  }
  .HomeScreen > .layoutFlow > .elImageMC > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elImageMC {
    position: relative;
    margin-top: -41px;
    width: 36px;
    margin-left: calc(58.0% - 18px);
  }
  .HomeScreen > .layoutFlow > .elImageMC > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elImageMC {
    position: relative;
    margin-top: -40px;
    width: 35px;
    /* The following = 594 - 18 */
    /* margin-left: calc(58.0% - 18px); */
    /* The following = 594 - 35 = 559 */
    margin-left: calc(50.0% + 47px);
  }
  .HomeScreen > .layoutFlow > .elImageMC > * {
  width: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elImageSN {
    position: relative;
    margin-top: 0px;
    width: 36px;
    margin-left: calc(50.0% - 18px);
  }
  .HomeScreen > .layoutFlow > .elImageSN > * {
  width: 36px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elImageSN {
    position: relative;
    margin-top: 120px;
    width: 36px;
    margin-left: calc(50.0% - 18px);
  }
  .HomeScreen > .layoutFlow > .elImageSN > * {
  width: 36px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elImageSN {
    position: relative;
    margin-top: -41px;
    width: 36px;
    margin-left: calc(75.1% - 18px);
  }
  .HomeScreen > .layoutFlow > .elImageSN > * {
  width: 36px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elImageSN {
    position: relative;
    margin-top: -40px;
    width: 35px;
    /* The following = 768 - 18 */
    margin-left: calc(75.0% - 18px);
    /* = (1024 * 75%) - 512 */
    margin-left: calc(50.0% + 256px);
    /* The following = 768 - 35 = 733 */
    margin-left: calc(50.0% + 221px);
  }
  .HomeScreen > .layoutFlow > .elImageSN > * {
  width: 35px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextIL {
    position: relative;
    margin-top: 15px;
    width: 252px;
    margin-left: calc(50.0% - 126px);
  }
  .HomeScreen > .layoutFlow > .elTextIL > * {
  width: 252px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextIL {
    position: relative;
    margin-top: -470px;
    width: 252px;
    margin-left: calc(50.0% - 126px);
  }
  .HomeScreen > .layoutFlow > .elTextIL > * {
  width: 252px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextIL {
    position: relative;
    margin-top: 15px;
    width: 250px;
    margin-left: calc(22.0% - 125px);
  }
  .HomeScreen > .layoutFlow > .elTextIL > * {
  width: 250px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextIL {
    position: relative;
    /* margin-top: 15px; */
    margin-top: -27px;
    /* margin-top: -45px; */
    width: 250px;
    /* The following = 100 px */
    /* margin-left: calc(22.0% - 125px); */
    /* 512 - 100 + 18 */
    margin-left: calc(50.0% - 430px);
    /* margin-left: calc(50.0% - 447px); */
  }
  .HomeScreen > .layoutFlow > .elTextIL > * {
  width: 250px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextAL {
    position: relative;
    margin-top: 60px;
    width: 252px;
    margin-left: calc(50.0% - 126px);
  }
  .HomeScreen > .layoutFlow > .elTextAL > * {
  width: 252px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextAL {
    position: relative;
    margin-top: 130px;
    width: 252px;
    margin-left: calc(50.0% - 126px);
  }
  .HomeScreen > .layoutFlow > .elTextAL > * {
  width: 252px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextAL {
    position: relative;
    margin-top: -27px;
    width: 250px;
    margin-left: calc(40.0% - 125px);
  }
  .HomeScreen > .layoutFlow > .elTextAL > * {
  width: 250px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextAL {
    position: relative;
    margin-top: -27px;
    width: 250px;
    margin-left: calc(50.0% - 245px);
  }
  .HomeScreen > .layoutFlow > .elTextAL > * {
  width: 250px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextMC {
    position: relative;
    margin-top: 60px;
    width: 252px;
    margin-left: calc(50.0% - 126px);
  }
  .HomeScreen > .layoutFlow > .elTextMC > * {
  width: 252px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextMC {
    position: relative;
    margin-top: 130px;
    width: 252px;
    margin-left: calc(50.0% - 126px);
  }
  .HomeScreen > .layoutFlow > .elTextMC > * {
  width: 252px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextMC {
    position: relative;
    margin-top: -27px;
    width: 250px;
    margin-left: calc(58.0% - 125px);
  }
  .HomeScreen > .layoutFlow > .elTextMC > * {
  width: 250px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextMC {
    position: relative;
    margin-top: -27px;
    width: 250px;
    /* margin-left: calc(58.0% - 125px); */
    margin-left: calc(50.0% - 54px);
  }
  .HomeScreen > .layoutFlow > .elTextMC > * {
  width: 250px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextSN {
    position: relative;
    margin-top: 60px;
    width: 252px;
    margin-left: calc(50.0% - 126px);
  }
  .HomeScreen > .layoutFlow > .elTextSN > * {
  width: 252px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextSN {
    position: relative;
    margin-top: 130px;
    width: 252px;
    margin-left: calc(50.0% - 126px);
  }
  .HomeScreen > .layoutFlow > .elTextSN > * {
  width: 252px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextSN {
    position: relative;
    margin-top: -27px;
    width: 250px;
    margin-left: calc(75.1% - 125px);
  }
  .HomeScreen > .layoutFlow > .elTextSN > * {
  width: 250px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextSN {
    position: relative;
    margin-top: -27px;
    width: 250px;
    /* The following = 768 - 125 = 643 */
    /* margin-left: calc(75.0% - 125px); */

    margin-left: calc(50.0% + 115px);

  }
  .HomeScreen > .layoutFlow > .elTextSN > * {
  width: 250px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextILText {
    position: relative;
    margin-top: -255px;
    width: 171px;
    margin-left: calc(50.0% - 86px);
  }
  .HomeScreen > .layoutFlow > .elTextILText > * {
  width: 171px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextILText {
    position: relative;
    margin-top: -465px;
    width: 171px;
    margin-left: calc(50.0% - 86px);
  }
  .HomeScreen > .layoutFlow > .elTextILText > * {
  width: 171px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextILText {
    position: relative;
    margin-top: 20px;
    width: 140px;
    margin-left: calc(22.0% - 70px);
  }
  .HomeScreen > .layoutFlow > .elTextILText > * {
  width: 140px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextILText {
    position: relative;
    margin-top: 0px;
    width: 170px;
    /* margin-left: calc(22.0% - 85px); */
    /* margin-left: calc(50.0% - 371px); */
    /* = (1024 * 22%) - 85 WIP */
    margin-left: calc(50.0% - 390px);
  }
  .HomeScreen > .layoutFlow > .elTextILText > * {
  width: 170px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextALText {
    position: relative;
    margin-top: 45px;
    width: 171px;
    margin-left: calc(50.0% - 86px);
  }
  .HomeScreen > .layoutFlow > .elTextALText > * {
  width: 171px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextALText {
    position: relative;
    margin-top: 120px;
    width: 171px;
    margin-left: calc(50.0% - 86px);
  }
  .HomeScreen > .layoutFlow > .elTextALText > * {
  width: 171px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextALText {
    position: relative;
    margin-top: -52px;
    width: 140px;
    margin-left: calc(40.0% - 70px);
  }
  .HomeScreen > .layoutFlow > .elTextALText > * {
  width: 140px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextALText {
    position: relative;
    margin-top: -39px;
    width: 170px;
    margin-left: calc(50.0% - 200px);

  }
  .HomeScreen > .layoutFlow > .elTextALText > * {
  width: 170px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextMCText {
    position: relative;
    margin-top: 45px;
    width: 171px;
    margin-left: calc(50.0% - 86px);
  }
  .HomeScreen > .layoutFlow > .elTextMCText > * {
  width: 171px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextMCText {
    position: relative;
    margin-top: 120px;
    width: 171px;
    margin-left: calc(50.0% - 86px);
  }
  .HomeScreen > .layoutFlow > .elTextMCText > * {
  width: 171px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextMCText {
    position: relative;
    margin-top: -52px;
    width: 140px;
    margin-left: calc(58.0% - 70px);
  }
  .HomeScreen > .layoutFlow > .elTextMCText > * {
  width: 140px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextMCText {
    position: relative;
    margin-top: -39px;
    width: 170px;
    margin-left: calc(50.0% - 15px);
  }
  .HomeScreen > .layoutFlow > .elTextMCText > * {
  width: 170px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextSNText {
    position: relative;
    margin-top: 50px;
    width: 171px;
    margin-left: calc(50.0% - 86px);
  }
  .HomeScreen > .layoutFlow > .elTextSNText > * {
  width: 171px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextSNText {
    position: relative;
    margin-top: 120px;
    width: 171px;
    margin-left: calc(50.0% - 86px);
  }
  .HomeScreen > .layoutFlow > .elTextSNText > * {
  width: 171px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextSNText {
    position: relative;
    margin-top: -39px;
    width: 170px;
    margin-left: calc(75.1% - 85px);
  }
  .HomeScreen > .layoutFlow > .elTextSNText > * {
  width: 170px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextSNText {
    position: relative;
    margin-top: -39px;
    width: 170px;
    /* margin-left: calc(75.0% - 85px); */
    margin-left: calc(50.0% + 157px);
  }
  .HomeScreen > .layoutFlow > .elTextSNText > * {
  width: 170px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elImageOpenData {
    position: relative;
    margin-top: 30px;
    width: 338px;
    margin-left: 5.0%;
  }
  .HomeScreen > .layoutFlow > .elImageOpenData > * {
  width: 338px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elImageOpenData {
    position: relative;
    margin-top: 60px;
    width: 504px;
    margin-left: 5.0%;
  }
  .HomeScreen > .layoutFlow > .elImageOpenData > * {
  width: 504px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elImageOpenData {
    position: relative;
    margin-top: 80px;
    width: 500px;
    margin-left: 5.0%;
  }
  .HomeScreen > .layoutFlow > .elImageOpenData > * {
  width: 500px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elImageOpenData {
    position: relative;
    margin-top: 80px;
    width: 500px;
    /* margin-left: 5.0%; */
    /* margin-left: calc(50% - 461px); */
    /* position: absolute; */
    margin-left: calc(50.0% - 512px);
  }
  .HomeScreen > .layoutFlow > .elImageOpenData > * {
  width: 500px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextOpenData {
    position: relative;
    margin-top: 30px;
    width: 252px;
    margin-left: calc(50.0% - 126px);
  }
  .HomeScreen > .layoutFlow > .elTextOpenData > * {
  width: 252px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextOpenData {
    position: relative;
    margin-top: 40px;
    width: 252px;
    margin-left: calc(50.0% - 126px);
  }
  .HomeScreen > .layoutFlow > .elTextOpenData > * {
  width: 252px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextOpenData {
    position: relative;
    margin-top: -250px;
    width: 250px;
    margin-left: calc(81.8% - 125px);
  }
  .HomeScreen > .layoutFlow > .elTextOpenData > * {
  width: 250px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextOpenData {
    position: relative;
    margin-top: -220px;
    width: 250px;
    /* margin-left: calc(69.5% - 125px); */
    /* margin-left: calc(50% + 387px); */
    margin-left: calc(50% + 80px);
  }
  .HomeScreen > .layoutFlow > .elTextOpenData > * {
  width: 250px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextOpenDataText {
    position: relative;
    margin-top: 11px;
    width: 268px;
    margin-left: calc(50.0% - 134px);
  }
  .HomeScreen > .layoutFlow > .elTextOpenDataText > * {
  width: 268px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextOpenDataText {
    position: relative;
    margin-top: 11px;
    width: 268px;
    margin-left: calc(50.0% - 134px);
  }
  .HomeScreen > .layoutFlow > .elTextOpenDataText > * {
  width: 268px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextOpenDataText {
    position: relative;
    margin-top: 11px;
    width: 150px;
    margin-left: calc(81.8% - 75px);
  }
  .HomeScreen > .layoutFlow > .elTextOpenDataText > * {
  width: 150px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextOpenDataText {
    position: relative;
    margin-top: 11px;
    width: 266px;
    /* margin-left: calc(69.5% - 133px); */
    margin-left: calc(50% + 80px);
    /* margin-right: calc(50.0% - 400px); */
    /* margin-right: 556px; */
  }
  .HomeScreen > .layoutFlow > .elTextOpenDataText > * {
  width: 266px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextWhyAcct {
    position: relative;
    margin-top: 30px;
    width: 252px;
    margin-left: calc(50.0% + -126px);
  }
  .HomeScreen > .layoutFlow > .elTextWhyAcct > * {
  width: 252px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextWhyAcct {
    position: relative;
    margin-top: 50px;
    width: 252px;
    margin-left: calc(50.0% + -126px);
  }
  .HomeScreen > .layoutFlow > .elTextWhyAcct > * {
  width: 252px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextWhyAcct {
    position: relative;
    margin-top: 120px;
    width: 250px;
    margin-left: calc(50.0% + -125px);
  }
  .HomeScreen > .layoutFlow > .elTextWhyAcct > * {
  width: 250px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextWhyAcct {
    position: relative;
    margin-top: 150px;
    width: 250px;
    margin-left: calc(50.0% + -125px);
  }
  .HomeScreen > .layoutFlow > .elTextWhyAcct > * {
  width: 250px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTextWhyAcctText {
    position: relative;
    margin-top: 11px;
    width: 268px;
    margin-left: calc(50.0% - 134px);
  }
  .HomeScreen > .layoutFlow > .elTextWhyAcctText > * {
  width: 268px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTextWhyAcctText {
    position: relative;
    margin-top: 11px;
    width: 402px;
    margin-left: calc(50.0% + -201px);
  }
  .HomeScreen > .layoutFlow > .elTextWhyAcctText > * {
  width: 402px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTextWhyAcctText {
    position: relative;
    margin-top: 11px;
    width: 400px;
    margin-left: calc(50.0% + -200px);
  }
  .HomeScreen > .layoutFlow > .elTextWhyAcctText > * {
  width: 400px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTextWhyAcctText {
    position: relative;
    margin-top: 11px;
    width: 400px;
    margin-left: calc(50.0% + -200px);
  }
  .HomeScreen > .layoutFlow > .elTextWhyAcctText > * {
  width: 400px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elCardFooter {
    position: relative;
    margin-top: 100px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elCardFooter > * {
    width: 100%;
  height: 1px;
  }

@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elCardFooter {
    position: relative;
    margin-top: 100px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elCardFooter > * {
    width: 100%;
  height: 1px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elCardFooter {
    position: relative;
    margin-top: 100px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elCardFooter > * {
    width: 100%;
  height: 1px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elCardFooter {
    position: relative;
    margin-top: 100px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elCardFooter > * {
    width: 100%;
  height: 1px;
  }

}

.HomeScreen .elBkgdOutlineOverall {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.HomeScreen .elBkgdOutlineOverall:focus {
  box-shadow: 0 0 5px rgba(56, 83, 128, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}

.HomeScreen .elUserInfo {
  overflow: hidden;
}

.HomeScreen .elUserInfo.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elUserInfo {
    position: fixed;
    right: 151px;
    top: 6px;
    width: 189px;
    height: 36px;
  }
  .HomeScreen > .screenFgContainer > .foreground > .elUserInfo > .background > .containerMinHeight {
    min-height: 36px;
  }

@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elUserInfo {
    right: 151px;
    top: 6px;
    width: 189px;
    height: 36px;
  }
  .HomeScreen > .screenFgContainer > .foreground > .elUserInfo > .background > .containerMinHeight {
    min-height: 36px;
  }

}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elUserInfo {
    right: 150px;
    top: 6px;
    width: 188px;
    height: 36px;
  }
  .HomeScreen > .screenFgContainer > .foreground > .elUserInfo > .background > .containerMinHeight {
    min-height: 36px;
  }

}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elUserInfo {
    right: 150px;
    top: 6px;
    width: 188px;
    height: 36px;
  }
  .HomeScreen > .screenFgContainer > .foreground > .elUserInfo > .background > .containerMinHeight {
    min-height: 36px;
  }

}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elFb {
    position: fixed;
    right: 101px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elFb {
    right: 101px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elFb {
    right: 100px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elFb {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    position: fixed;
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
    visibility: hidden;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
    visibility: visible;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
    visibility: visible;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elIg {
    position: fixed;
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    position: fixed;
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
    visibility: hidden;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
    visibility: visible;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
    visibility: visible;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elTwitter {
    position: fixed;
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elTwittercircle {
    position: fixed;
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
    visibility: hidden;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
    visibility: visible;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
    visibility: visible;
  }
}

.HomeScreen .elButtonSignUp {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.HomeScreen .elButtonSignUp:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elButtonSignUp {
    position: fixed;
    right: 141px;
    top: 6px;
    padding:8px 17px;
    visibility: hidden;
    border:1px solid #fff,


  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elButtonSignUp {
    right: 141px;
    top: 6px;
    padding:8px 17px;
    visibility: visible;
    border:1px solid #fff

  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elButtonSignUp {
    right: 140px;
    top: 6px;
    padding:8px 17px;
    visibility: visible;
    border:1px solid #fff

  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elButtonSignUp {
    top: 10px;
    padding:8px 15px;
    border:1px solid #fff

  }
}

.HomeScreen .elButtonLogIn {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.HomeScreen .elButtonLogIn:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elButtonLogIn {
    position: fixed;
    top: 6px;
    visibility: hidden;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elButtonLogIn {
    top: 6px;
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elButtonLogIn {
    top: 6px;
    visibility: visible;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elButtonLogIn {
    top: 6px;
    visibility: visible;
  }
}

.HomeScreen .elButtonLearnMore {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.HomeScreen .elButtonLearnMore:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    position: fixed;
    top: 6px;
    visibility: hidden;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    top: 6px;
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    top: 6px;
    visibility: visible;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    top: 6px;
    visibility: visible;
  }
}

.HomeScreen .elButtonAboutUs {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.HomeScreen .elButtonAboutUs:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    top: 6px;
    visibility: hidden;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    top: 6px;
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    top: 6px;
    visibility: visible;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    top: 6px;
    visibility: visible;
  }
}

.HomeScreen .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.HomeScreen .elIconButton:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elIconButton {
    position: absolute;
    left: 5px;
    top: 4px;
    width: 41px;
    height: 36px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}

.HomeScreen .elAntiTooltipHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elAntiTooltipHotspot {
    position: absolute;
    left: 131px;
    top: 52px;
    width: 567px;
    height: 187px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elAntiTooltipHotspot {
    left: 131px;
    top: 52px;
    width: 567px;
    height: 187px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elAntiTooltipHotspot {
    left: 130px;
    top: 52px;
    width: 564px;
    height: 186px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elAntiTooltipHotspot {
    left: 130px;
    top: 52px;
    width: 564px;
    height: 186px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elIcontooltip02 {
    top: 8px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elIcontooltip02 {
    top: 8px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elIcontooltip02 {
    top: 8px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elIcontooltip02 {
    top: 8px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elBetaCard {
    top: 35px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elBetaCard {
    top: 35px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elBetaCard {
    top: 35px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elBetaCard {
    top: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elBetaText {
    top: 36px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elBetaText {
    top: 36px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elBetaText {
    top: 36px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elBetaText {
    top: 36px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elBeta {
    top: -24px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elBeta {
    top: -24px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elBeta {
    top: -24px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elBeta {
    top: -24px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    top: 0px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    top: 0px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    top: 0px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    top: 0px;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginpasswordScreen > .background > .elBackground {
    top: 0px;
  }
@media (min-width: 568px) {
  .LoginpasswordScreen > .background > .elBackground {
    top: 0px;
  }
}
@media (min-width: 768px) {
  .LoginpasswordScreen > .background > .elBackground {
    top: 0px;
  }
}
@media (min-width: 1024px) {
  .LoginpasswordScreen > .background > .elBackground {
    top: 0px;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginpasswordScreen > .layoutFlow > .elPasswordField {
    position: relative;
    margin-top: 166px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .LoginpasswordScreen > .layoutFlow > .elPasswordField > * {
  width: 226px;
  height: 34px;
  }

@media (min-width: 568px) {
  .LoginpasswordScreen > .layoutFlow > .elPasswordField {
    position: relative;
    margin-top: 100px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .LoginpasswordScreen > .layoutFlow > .elPasswordField > * {
  width: 226px;
  height: 34px;
  }

}
@media (min-width: 768px) {
  .LoginpasswordScreen > .layoutFlow > .elPasswordField {
    position: relative;
    margin-top: 164px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .LoginpasswordScreen > .layoutFlow > .elPasswordField > * {
  width: 225px;
  height: 34px;
  }

}
@media (min-width: 1024px) {
  .LoginpasswordScreen > .layoutFlow > .elPasswordField {
    position: relative;
    margin-top: 254px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .LoginpasswordScreen > .layoutFlow > .elPasswordField > * {
  width: 225px;
  height: 34px;
  }

}

.LoginpasswordScreen .elButtonPassword button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.LoginpasswordScreen .elButtonPassword button:focus {
  box-shadow: 0 0 5px rgba(66, 66, 66, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .LoginpasswordScreen > .layoutFlow > .elButtonPassword {
    line-height: 35px;
    position: relative;
    margin-top: 60px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .LoginpasswordScreen > .layoutFlow > .elButtonPassword > * {
  width: 145px;
  height: 35px;
  }

@media (min-width: 568px) {
  .LoginpasswordScreen > .layoutFlow > .elButtonPassword {
    line-height: 35px;
    position: relative;
    margin-top: 60px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .LoginpasswordScreen > .layoutFlow > .elButtonPassword > * {
  width: 145px;
  height: 35px;
  }

}
@media (min-width: 768px) {
  .LoginpasswordScreen > .layoutFlow > .elButtonPassword {
    line-height: 35px;
    position: relative;
    margin-top: 60px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .LoginpasswordScreen > .layoutFlow > .elButtonPassword > * {
  width: 144px;
  height: 35px;
  }

}
@media (min-width: 1024px) {
  .LoginpasswordScreen > .layoutFlow > .elButtonPassword {
    line-height: 35px;
    position: relative;
    margin-top: 60px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .LoginpasswordScreen > .layoutFlow > .elButtonPassword > * {
  width: 144px;
  height: 35px;
  }

}

/* This component has a fixed-size layout */
  .ProfilePicName {
    min-width: 102px; /* This component has a fixed size */
  }


  .ProfilePicName > .layoutFlow > .elTextCopy {
    position: relative;
    margin-top: 0px;
    width: 100px;
    margin-left: 0px;
  }
  .ProfilePicName > .layoutFlow > .elTextCopy > * {
  width: 100px;
  }



  .ProfilePicName > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    margin-top: 7px;
    width: 67px;
    margin-left: calc(50.0% - 34px);
  }
  .ProfilePicName > .layoutFlow > .flowRow > .elImage > * {
  width: 67px;
  height: 61px;
  }

  .flowRow_ProfilePicName_elImage_667697 {
  }



/* Narrowest size for this element's responsive layout */
  .Login2HomeScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .Login2HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .Login2HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .Login2HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .Login2HomeScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 130px;
    width: 207px;
    margin-left: 423px;
  }
  .Login2HomeScreen > .layoutFlow > .elFirebaseLogin > * {
  width: 207px;
  height: 48px;
  }

@media (min-width: 568px) {
  .Login2HomeScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 130px;
    width: 207px;
    margin-left: 423px;
  }
  .Login2HomeScreen > .layoutFlow > .elFirebaseLogin > * {
  width: 207px;
  height: 48px;
  }

}
@media (min-width: 768px) {
  .Login2HomeScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 130px;
    width: 206px;
    margin-left: 420px;
  }
  .Login2HomeScreen > .layoutFlow > .elFirebaseLogin > * {
  width: 206px;
  height: 47px;
  }

}
@media (min-width: 1024px) {
  .Login2HomeScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 130px;
    width: 206px;
    margin-left: 420px;
  }
  .Login2HomeScreen > .layoutFlow > .elFirebaseLogin > * {
  width: 206px;
  height: 47px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .ContributorsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .ContributorsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .ContributorsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elContributorsHdgText {
    overflow: hidden;
    position: relative;
    margin-top: 80px;
    width: 1030px;
    margin-left: calc(50.0% - 515px);
  }
  .ContributorsScreen > .layoutFlow > .elContributorsHdgText > * {
  width: 1030px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elContributorsHdgText {
    overflow: hidden;
    position: relative;
    margin-top: 80px;
    width: 1030px;
    margin-left: calc(50.0% - 515px);
  }
  .ContributorsScreen > .layoutFlow > .elContributorsHdgText > * {
  width: 1030px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elContributorsHdgText {
    overflow: hidden;
    position: relative;
    margin-top: 80px;
    width: 1024px;
    margin-left: calc(50.0% - 512px);
  }
  .ContributorsScreen > .layoutFlow > .elContributorsHdgText > * {
  width: 1024px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elContributorsHdgText {
    overflow: hidden;
    position: relative;
    margin-top: 80px;
    width: 1024px;
    margin-left: calc(50.0% - 512px);
  }
  .ContributorsScreen > .layoutFlow > .elContributorsHdgText > * {
  width: 1024px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elLineTop {
    position: relative;
    margin-top: 40px;
    width: 50px;
    margin-left: calc(50.0% - 25px);
  }
  .ContributorsScreen > .layoutFlow > .elLineTop > * {
  width: 50px;
  height: 2px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elLineTop {
    position: relative;
    margin-top: 40px;
    width: 50px;
    margin-left: calc(50.0% - 25px);
  }
  .ContributorsScreen > .layoutFlow > .elLineTop > * {
  width: 50px;
  height: 2px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elLineTop {
    position: relative;
    margin-top: 40px;
    width: 50px;
    margin-left: calc(50.0% - 25px);
  }
  .ContributorsScreen > .layoutFlow > .elLineTop > * {
  width: 50px;
  height: 2px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elLineTop {
    position: relative;
    margin-top: 40px;
    width: 50px;
    margin-left: calc(50.0% - 25px);
  }
  .ContributorsScreen > .layoutFlow > .elLineTop > * {
  width: 50px;
  height: 2px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 30px;
    width: 121px;
    margin-left: calc(-19.2% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse > * {
  width: 121px;
  height: 121px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 30px;
    width: 121px;
    margin-left: calc(11.0% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse > * {
  width: 121px;
  height: 121px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 30px;
    width: 120px;
    margin-left: calc(21.4% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse > * {
  width: 120px;
  height: 120px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elEllipse {
    position: relative;
    margin-top: 30px;
    width: 120px;
    margin-left: calc(28.5% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse > * {
  width: 120px;
  height: 120px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elCategoryText {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(-19.2% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText > * {
  width: 75px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elCategoryText {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(11.0% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText > * {
  width: 75px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elCategoryText {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(21.4% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText > * {
  width: 75px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elCategoryText {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(28.5% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText > * {
  width: 75px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elAmountText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 75px;
    margin-left: calc(-19.2% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText > * {
  width: 75px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elAmountText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 75px;
    margin-left: calc(11.0% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText > * {
  width: 75px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elAmountText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 75px;
    margin-left: calc(21.4% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText > * {
  width: 75px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elAmountText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 75px;
    margin-left: calc(28.5% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText > * {
  width: 75px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elEllipse2 {
    position: relative;
    margin-top: -95px;
    width: 121px;
    margin-left: calc(15.4% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse2 > * {
  width: 121px;
  height: 121px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elEllipse2 {
    position: relative;
    margin-top: -85px;
    width: 121px;
    margin-left: calc(30.5% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse2 > * {
  width: 121px;
  height: 121px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elEllipse2 {
    position: relative;
    margin-top: -85px;
    width: 120px;
    margin-left: calc(35.7% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse2 > * {
  width: 120px;
  height: 120px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elEllipse2 {
    position: relative;
    margin-top: -85px;
    width: 120px;
    margin-left: calc(39.3% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse2 > * {
  width: 120px;
  height: 120px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elCategoryText2 {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(15.4% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText2 > * {
  width: 75px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elCategoryText2 {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(30.5% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText2 > * {
  width: 75px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elCategoryText2 {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(35.7% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText2 > * {
  width: 75px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elCategoryText2 {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(39.3% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText2 > * {
  width: 75px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elAmountText2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 121px;
    margin-left: calc(15.4% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText2 > * {
  width: 121px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elAmountText2 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 121px;
    margin-left: calc(30.5% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText2 > * {
  width: 121px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elAmountText2 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 120px;
    margin-left: calc(35.7% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText2 > * {
  width: 120px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elAmountText2 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 120px;
    margin-left: calc(39.3% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText2 > * {
  width: 120px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elEllipse3 {
    position: relative;
    margin-top: -95px;
    width: 121px;
    margin-left: calc(50.0% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse3 > * {
  width: 121px;
  height: 121px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elEllipse3 {
    position: relative;
    margin-top: -85px;
    width: 121px;
    margin-left: calc(50.0% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse3 > * {
  width: 121px;
  height: 121px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elEllipse3 {
    position: relative;
    margin-top: -85px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse3 > * {
  width: 120px;
  height: 120px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elEllipse3 {
    position: relative;
    margin-top: -85px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse3 > * {
  width: 120px;
  height: 120px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elCategoryText3 {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(50.0% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText3 > * {
  width: 75px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elCategoryText3 {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(50.0% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText3 > * {
  width: 75px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elCategoryText3 {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(50.0% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText3 > * {
  width: 75px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elCategoryText3 {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(50.0% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText3 > * {
  width: 75px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elAmountText3 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 101px;
    margin-left: calc(50.0% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText3 > * {
  width: 101px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elAmountText3 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 101px;
    margin-left: calc(50.0% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText3 > * {
  width: 101px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elAmountText3 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText3 > * {
  width: 100px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elAmountText3 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText3 > * {
  width: 100px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elEllipse4 {
    position: relative;
    margin-top: -95px;
    width: 121px;
    margin-left: calc(84.6% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse4 > * {
  width: 121px;
  height: 121px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elEllipse4 {
    position: relative;
    margin-top: -85px;
    width: 121px;
    margin-left: calc(69.5% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse4 > * {
  width: 121px;
  height: 121px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elEllipse4 {
    position: relative;
    margin-top: -85px;
    width: 120px;
    margin-left: calc(64.3% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse4 > * {
  width: 120px;
  height: 120px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elEllipse4 {
    position: relative;
    margin-top: -85px;
    width: 120px;
    margin-left: calc(60.7% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse4 > * {
  width: 120px;
  height: 120px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elCategoryText4 {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(84.6% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText4 > * {
  width: 75px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elCategoryText4 {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(69.5% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText4 > * {
  width: 75px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elCategoryText4 {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(64.3% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText4 > * {
  width: 75px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elCategoryText4 {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(60.7% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText4 > * {
  width: 75px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elAmountText4 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 91px;
    margin-left: calc(84.6% - 45px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText4 > * {
  width: 91px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elAmountText4 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 91px;
    margin-left: calc(69.5% - 45px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText4 > * {
  width: 91px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elAmountText4 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 90px;
    margin-left: calc(64.3% - 45px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText4 > * {
  width: 90px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elAmountText4 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 90px;
    margin-left: calc(60.7% - 45px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText4 > * {
  width: 90px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elEllipse5 {
    position: relative;
    margin-top: -95px;
    width: 121px;
    margin-left: calc(119.2% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse5 > * {
  width: 121px;
  height: 121px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elEllipse5 {
    position: relative;
    margin-top: -85px;
    width: 121px;
    margin-left: calc(89.0% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse5 > * {
  width: 121px;
  height: 121px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elEllipse5 {
    position: relative;
    margin-top: -85px;
    width: 120px;
    margin-left: calc(78.6% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse5 > * {
  width: 120px;
  height: 120px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elEllipse5 {
    position: relative;
    margin-top: -85px;
    width: 120px;
    margin-left: calc(71.5% - 60px);
  }
  .ContributorsScreen > .layoutFlow > .elEllipse5 > * {
  width: 120px;
  height: 120px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elCategoryText5 {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(119.2% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText5 > * {
  width: 75px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elCategoryText5 {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(89.0% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText5 > * {
  width: 75px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elCategoryText5 {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(78.6% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText5 > * {
  width: 75px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elCategoryText5 {
    overflow: hidden;
    position: relative;
    margin-top: -80px;
    width: 75px;
    margin-left: calc(71.5% - 38px);
  }
  .ContributorsScreen > .layoutFlow > .elCategoryText5 > * {
  width: 75px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elAmountText5 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 101px;
    margin-left: calc(119.2% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText5 > * {
  width: 101px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elAmountText5 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 101px;
    margin-left: calc(89.0% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText5 > * {
  width: 101px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elAmountText5 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100px;
    margin-left: calc(78.6% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText5 > * {
  width: 100px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elAmountText5 {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100px;
    margin-left: calc(71.5% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elAmountText5 > * {
  width: 100px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elDiamondContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 900px;
    margin-left: 7.0%;
  }
  .ContributorsScreen > .layoutFlow > .elDiamondContribText > * {
  width: 900px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elDiamondContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 900px;
    margin-left: 5.1%;
  }
  .ContributorsScreen > .layoutFlow > .elDiamondContribText > * {
  width: 900px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elDiamondContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 900px;
    margin-left: 8.5%;
  }
  .ContributorsScreen > .layoutFlow > .elDiamondContribText > * {
  width: 900px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elDiamondContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 900px;
    margin-left: 6.6%;
  }
  .ContributorsScreen > .layoutFlow > .elDiamondContribText > * {
  width: 900px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elDiamondContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 7.0%;
    margin-right: 0px;
    width: calc(93.0% - 0px);
  }
  .ContributorsScreen > .layoutFlow > .elDiamondContribLine > * {
    width: 100%;
  height: 2px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elDiamondContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 5.1%;
    margin-right: 50px;
    width: calc(94.9% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elDiamondContribLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elDiamondContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 8.5%;
    margin-right: 50px;
    width: calc(91.5% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elDiamondContribLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elDiamondContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 6.6%;
    margin-right: 50px;
    width: calc(93.4% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elDiamondContribLine > * {
    width: 100%;
  height: 2px;
  }

}

.ContributorsScreen > .layoutFlow > .elDiamondContribList > div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 4px;
  grid-column-gap: 4px;  /* old name still required by some browsers */
}

.ContributorsScreen > .layoutFlow > .elDiamondContribList > div > * {
  display: inline-block;
  position: relative;
  min-width: 181px;
}

  @media (min-width: 1024px) {
    .ContributorsScreen > .layoutFlow > .elDiamondContribList > div {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 4px;
      grid-column-gap: 4px;  /* old name still required by some browsers */
    }

  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .ContributorsScreen > .layoutFlow > .elDiamondContribList > div {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }

  }

  @media (min-width: 568px) and (max-width: 767px) {
    .ContributorsScreen > .layoutFlow > .elDiamondContribList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }

  }

  @media (max-width: 567px) {
    .ContributorsScreen > .layoutFlow > .elDiamondContribList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }

  }



/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elDiamondContribList {
    position: relative;
    margin-top: 38px;
    width: 250px;
    margin-left: 172px;
  }
  .ContributorsScreen > .layoutFlow > .elDiamondContribList > * {
  width: 250px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elDiamondContribList {
    position: relative;
    margin-top: 38px;
    width: 250px;
    margin-left: 172px;
  }
  .ContributorsScreen > .layoutFlow > .elDiamondContribList > * {
  width: 250px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elDiamondContribList {
    position: relative;
    margin-top: 38px;
    width: 248px;
    margin-left: 170px;
  }
  .ContributorsScreen > .layoutFlow > .elDiamondContribList > * {
  width: 248px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elDiamondContribList {
    position: relative;
    margin-top: 38px;
    width: 248px;
    margin-left: 170px;
  }
  .ContributorsScreen > .layoutFlow > .elDiamondContribList > * {
  width: 248px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elPlatinumContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 900px;
    margin-left: 7.0%;
  }
  .ContributorsScreen > .layoutFlow > .elPlatinumContribText > * {
  width: 900px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elPlatinumContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 900px;
    margin-left: 5.1%;
  }
  .ContributorsScreen > .layoutFlow > .elPlatinumContribText > * {
  width: 900px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elPlatinumContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 900px;
    margin-left: 8.5%;
  }
  .ContributorsScreen > .layoutFlow > .elPlatinumContribText > * {
  width: 900px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elPlatinumContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 900px;
    margin-left: 6.6%;
  }
  .ContributorsScreen > .layoutFlow > .elPlatinumContribText > * {
  width: 900px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elPlatinumContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 7.0%;
    margin-right: 0px;
    width: calc(93.0% - 0px);
  }
  .ContributorsScreen > .layoutFlow > .elPlatinumContribLine > * {
    width: 100%;
  height: 2px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elPlatinumContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 5.1%;
    margin-right: 50px;
    width: calc(94.9% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elPlatinumContribLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elPlatinumContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 8.5%;
    margin-right: 50px;
    width: calc(91.5% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elPlatinumContribLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elPlatinumContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 6.6%;
    margin-right: 50px;
    width: calc(93.4% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elPlatinumContribLine > * {
    width: 100%;
  height: 2px;
  }

}

.ContributorsScreen > .layoutFlow > .elPlatinumContribList > div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 4px;
  grid-column-gap: 4px;  /* old name still required by some browsers */
}

.ContributorsScreen > .layoutFlow > .elPlatinumContribList > div > * {
  display: inline-block;
  position: relative;
  min-width: 181px;
}

  @media (min-width: 1024px) {
    .ContributorsScreen > .layoutFlow > .elPlatinumContribList > div {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 4px;
      grid-column-gap: 4px;  /* old name still required by some browsers */
    }

  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .ContributorsScreen > .layoutFlow > .elPlatinumContribList > div {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }

  }

  @media (min-width: 568px) and (max-width: 767px) {
    .ContributorsScreen > .layoutFlow > .elPlatinumContribList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }

  }

  @media (max-width: 567px) {
    .ContributorsScreen > .layoutFlow > .elPlatinumContribList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }

  }



/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elPlatinumContribList {
    position: relative;
    margin-top: 38px;
    width: 250px;
    margin-left: 172px;
  }
  .ContributorsScreen > .layoutFlow > .elPlatinumContribList > * {
  width: 250px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elPlatinumContribList {
    position: relative;
    margin-top: 38px;
    width: 250px;
    margin-left: 172px;
  }
  .ContributorsScreen > .layoutFlow > .elPlatinumContribList > * {
  width: 250px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elPlatinumContribList {
    position: relative;
    margin-top: 38px;
    width: 248px;
    margin-left: 170px;
  }
  .ContributorsScreen > .layoutFlow > .elPlatinumContribList > * {
  width: 248px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elPlatinumContribList {
    position: relative;
    margin-top: 38px;
    width: 248px;
    margin-left: 170px;
  }
  .ContributorsScreen > .layoutFlow > .elPlatinumContribList > * {
  width: 248px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elGoldContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 342px;
    margin-left: 7.0%;
  }
  .ContributorsScreen > .layoutFlow > .elGoldContribText > * {
  width: 342px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elGoldContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 342px;
    margin-left: 5.1%;
  }
  .ContributorsScreen > .layoutFlow > .elGoldContribText > * {
  width: 342px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elGoldContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 340px;
    margin-left: 8.5%;
  }
  .ContributorsScreen > .layoutFlow > .elGoldContribText > * {
  width: 340px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elGoldContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 340px;
    margin-left: 6.6%;
  }
  .ContributorsScreen > .layoutFlow > .elGoldContribText > * {
  width: 340px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elGoldContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 7.0%;
    margin-right: 0px;
    width: calc(93.0% - 0px);
  }
  .ContributorsScreen > .layoutFlow > .elGoldContribLine > * {
    width: 100%;
  height: 2px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elGoldContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 5.1%;
    margin-right: 50px;
    width: calc(94.9% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elGoldContribLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elGoldContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 8.5%;
    margin-right: 50px;
    width: calc(91.5% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elGoldContribLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elGoldContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 6.6%;
    margin-right: 50px;
    width: calc(93.4% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elGoldContribLine > * {
    width: 100%;
  height: 2px;
  }

}

.ContributorsScreen > .layoutFlow > .elGoldContribList > div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 4px;
  grid-column-gap: 4px;  /* old name still required by some browsers */
}

.ContributorsScreen > .layoutFlow > .elGoldContribList > div > * {
  display: inline-block;
  position: relative;
  min-width: 181px;
}

  @media (min-width: 1024px) {
    .ContributorsScreen > .layoutFlow > .elGoldContribList > div {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 4px;
      grid-column-gap: 4px;  /* old name still required by some browsers */
    }

  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .ContributorsScreen > .layoutFlow > .elGoldContribList > div {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }

  }

  @media (min-width: 568px) and (max-width: 767px) {
    .ContributorsScreen > .layoutFlow > .elGoldContribList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }

  }

  @media (max-width: 567px) {
    .ContributorsScreen > .layoutFlow > .elGoldContribList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }

  }



/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elGoldContribList {
    position: relative;
    margin-top: 38px;
    width: 250px;
    margin-left: 172px;
  }
  .ContributorsScreen > .layoutFlow > .elGoldContribList > * {
  width: 250px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elGoldContribList {
    position: relative;
    margin-top: 38px;
    width: 250px;
    margin-left: 172px;
  }
  .ContributorsScreen > .layoutFlow > .elGoldContribList > * {
  width: 250px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elGoldContribList {
    position: relative;
    margin-top: 38px;
    width: 248px;
    margin-left: 170px;
  }
  .ContributorsScreen > .layoutFlow > .elGoldContribList > * {
  width: 248px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elGoldContribList {
    position: relative;
    margin-top: 38px;
    width: 248px;
    margin-left: 170px;
  }
  .ContributorsScreen > .layoutFlow > .elGoldContribList > * {
  width: 248px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elSilverContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 342px;
    margin-left: 7.0%;
  }
  .ContributorsScreen > .layoutFlow > .elSilverContribText > * {
  width: 342px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elSilverContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 342px;
    margin-left: 5.1%;
  }
  .ContributorsScreen > .layoutFlow > .elSilverContribText > * {
  width: 342px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elSilverContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 340px;
    margin-left: 8.5%;
  }
  .ContributorsScreen > .layoutFlow > .elSilverContribText > * {
  width: 340px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elSilverContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 340px;
    margin-left: 6.6%;
  }
  .ContributorsScreen > .layoutFlow > .elSilverContribText > * {
  width: 340px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elSilverContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 7.0%;
    margin-right: 0px;
    width: calc(93.0% - 0px);
  }
  .ContributorsScreen > .layoutFlow > .elSilverContribLine > * {
    width: 100%;
  height: 2px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elSilverContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 5.1%;
    margin-right: 50px;
    width: calc(94.9% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elSilverContribLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elSilverContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 8.5%;
    margin-right: 50px;
    width: calc(91.5% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elSilverContribLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elSilverContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 6.6%;
    margin-right: 50px;
    width: calc(93.4% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elSilverContribLine > * {
    width: 100%;
  height: 2px;
  }

}

.ContributorsScreen > .layoutFlow > .elSilverContribList > div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 4px;
  grid-column-gap: 4px;  /* old name still required by some browsers */
}

.ContributorsScreen > .layoutFlow > .elSilverContribList > div > * {
  display: inline-block;
  position: relative;
  min-width: 181px;
}

  @media (min-width: 1024px) {
    .ContributorsScreen > .layoutFlow > .elSilverContribList > div {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 4px;
      grid-column-gap: 4px;  /* old name still required by some browsers */
    }

  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .ContributorsScreen > .layoutFlow > .elSilverContribList > div {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
      color: #ff0000;
    }

  }

  @media (min-width: 568px) and (max-width: 767px) {
    .ContributorsScreen > .layoutFlow > .elSilverContribList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }

  }

  @media (max-width: 567px) {
    .ContributorsScreen > .layoutFlow > .elSilverContribList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }

  }



/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elSilverContribList {
    position: relative;
    margin-top: 38px;
    width: 250px;
    margin-left: 172px;
  }
  .ContributorsScreen > .layoutFlow > .elSilverContribList > * {
  width: 250px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elSilverContribList {
    position: relative;
    margin-top: 38px;
    width: 250px;
    margin-left: 172px;
  }
  .ContributorsScreen > .layoutFlow > .elSilverContribList > * {
  width: 250px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elSilverContribList {
    position: relative;
    margin-top: 38px;
    width: 248px;
    margin-left: 170px;
  }
  .ContributorsScreen > .layoutFlow > .elSilverContribList > * {
  width: 248px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elSilverContribList {
    position: relative;
    margin-top: 38px;
    width: 248px;
    margin-left: 170px;
  }
  .ContributorsScreen > .layoutFlow > .elSilverContribList > * {
  width: 248px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elBronzeContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 342px;
    margin-left: 7.0%;
  }
  .ContributorsScreen > .layoutFlow > .elBronzeContribText > * {
  width: 342px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elBronzeContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 342px;
    margin-left: 5.1%;
  }
  .ContributorsScreen > .layoutFlow > .elBronzeContribText > * {
  width: 342px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elBronzeContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 340px;
    margin-left: 8.5%;
  }
  .ContributorsScreen > .layoutFlow > .elBronzeContribText > * {
  width: 340px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elBronzeContribText {
    overflow: hidden;
    position: relative;
    margin-top: 70px;
    width: 340px;
    margin-left: 6.6%;
  }
  .ContributorsScreen > .layoutFlow > .elBronzeContribText > * {
  width: 340px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elBronzeContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 7.0%;
    margin-right: 0px;
    width: calc(93.0% - 0px);
  }
  .ContributorsScreen > .layoutFlow > .elBronzeContribLine > * {
    width: 100%;
  height: 2px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elBronzeContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 5.1%;
    margin-right: 50px;
    width: calc(94.9% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elBronzeContribLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elBronzeContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 8.5%;
    margin-right: 50px;
    width: calc(91.5% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elBronzeContribLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elBronzeContribLine {
    position: relative;
    margin-top: 10px;
    margin-left: 6.6%;
    margin-right: 50px;
    width: calc(93.4% - 50px);
  }
  .ContributorsScreen > .layoutFlow > .elBronzeContribLine > * {
    width: 100%;
  height: 2px;
  }

}

.ContributorsScreen > .layoutFlow > .elBronzeContribList > div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 4px;
  grid-column-gap: 4px;  /* old name still required by some browsers */
}

.ContributorsScreen > .layoutFlow > .elBronzeContribList > div > * {
  display: inline-block;
  position: relative;
  min-width: 181px;
}

  @media (min-width: 1024px) {
    .ContributorsScreen > .layoutFlow > .elBronzeContribList > div {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 4px;
      grid-column-gap: 4px;  /* old name still required by some browsers */
    }

  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .ContributorsScreen > .layoutFlow > .elBronzeContribList > div {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }

  }

  @media (min-width: 568px) and (max-width: 767px) {
    .ContributorsScreen > .layoutFlow > .elBronzeContribList > div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }

  }

  @media (max-width: 567px) {
    .ContributorsScreen > .layoutFlow > .elBronzeContribList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }

  }



/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elBronzeContribList {
    position: relative;
    margin-top: 38px;
    width: 250px;
    margin-left: 172px;
  }
  .ContributorsScreen > .layoutFlow > .elBronzeContribList > * {
  width: 250px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elBronzeContribList {
    position: relative;
    margin-top: 38px;
    width: 250px;
    margin-left: 172px;
  }
  .ContributorsScreen > .layoutFlow > .elBronzeContribList > * {
  width: 250px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elBronzeContribList {
    position: relative;
    margin-top: 38px;
    width: 248px;
    margin-left: 170px;
  }
  .ContributorsScreen > .layoutFlow > .elBronzeContribList > * {
  width: 248px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elBronzeContribList {
    position: relative;
    margin-top: 38px;
    width: 248px;
    margin-left: 170px;
  }
  .ContributorsScreen > .layoutFlow > .elBronzeContribList > * {
  width: 248px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 202px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .ContributorsScreen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 201px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .ContributorsScreen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 200px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .ContributorsScreen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elLine {
    position: relative;
    margin-top: 200px;
    margin-left: 12px;
    margin-right: 11px;
    width: calc(100.0% - 23px);
  }
  .ContributorsScreen > .layoutFlow > .elLine > * {
    width: 100%;
  height: 2px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .flowRow > .elField {
    position: absolute;
    margin-top: 0px;
    width: 226px;
    margin-left: 330px;
  }
  .ContributorsScreen > .layoutFlow > .flowRow > .elField > * {
  width: 226px;
  height: 34px;
  }

  .flowRow_ContributorsScreen_elField_2109979299 {
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .flowRow > .elField {
    position: absolute;
    margin-top: 0px;
    width: 226px;
    margin-left: 330px;
  }
  .ContributorsScreen > .layoutFlow > .flowRow > .elField > * {
  width: 226px;
  height: 34px;
  }

  .flowRow_ContributorsScreen_elField_2109979299 {
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .flowRow > .elField {
    position: absolute;
    margin-top: 0px;
    width: 225px;
    margin-left: 328px;
  }
  .ContributorsScreen > .layoutFlow > .flowRow > .elField > * {
  width: 225px;
  height: 34px;
  }

  .flowRow_ContributorsScreen_elField_2109979299 {
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .flowRow > .elField {
    position: absolute;
    margin-top: 0px;
    width: 225px;
    margin-left: 328px;
  }
  .ContributorsScreen > .layoutFlow > .flowRow > .elField > * {
  width: 225px;
  height: 34px;
  }

  .flowRow_ContributorsScreen_elField_2109979299 {
  }

}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
  }
  .ContributorsScreen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

@media (min-width: 568px) {
  .ContributorsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
  }
  .ContributorsScreen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

}
@media (min-width: 768px) {
  .ContributorsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
  }
  .ContributorsScreen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

}
@media (min-width: 1024px) {
  .ContributorsScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 800px;
  }
  .ContributorsScreen > .layoutFlow > .elSpacer > * {
  width: 800px;
  height: 100px;
  }

}

.ContributorsScreen .elBkgdOutlineOverall {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.ContributorsScreen .elBkgdOutlineOverall:focus {
  box-shadow: 0 0 5px rgba(56, 83, 128, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
@media (min-width: 568px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}
@media (min-width: 1024px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elBkgdOutlineOverall {
    line-height: 50px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 50px;
  }
}

.ContributorsScreen .elFab {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.ContributorsScreen .elFab:focus {
  box-shadow: 0 0 6px rgba(66, 66, 66, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    position: fixed;
    right: 23px;
    bottom: 23px;
    width: 68px;
    height: 68px;
  }
@media (min-width: 568px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    right: 23px;
    bottom: 23px;
    width: 68px;
    height: 68px;
  }
}
@media (min-width: 768px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    right: 22px;
    bottom: 22px;
    width: 68px;
    height: 68px;
  }
}
@media (min-width: 1024px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elFab {
    line-height: 68px;
    right: 22px;
    bottom: 22px;
    width: 68px;
    height: 68px;
  }
}

.ContributorsScreen .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.ContributorsScreen .elIconButton:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .screenFgContainer > .foreground > .elIconButton {
    position: absolute;
    left: 5px;
    top: 4px;
    width: 41px;
    height: 36px;
  }
@media (min-width: 568px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}
@media (min-width: 768px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}
@media (min-width: 1024px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elIconButton {
    left: 5px;
    top: 0px;
    width: 36px;
    height: 0px;
  }
}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .screenFgContainer > .foreground > .elTwittercircle {
    position: fixed;
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elTwittercircle {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .screenFgContainer > .foreground > .elTwitter {
    position: fixed;
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elTwitter {
    right: 20px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    position: fixed;
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elInstagramcircle {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .screenFgContainer > .foreground > .elIg {
    position: fixed;
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elIg {
    right: 60px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    position: fixed;
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 101px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elFacebookcircle {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .screenFgContainer > .foreground > .elFb {
    position: fixed;
    right: 101px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
@media (min-width: 568px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elFb {
    right: 101px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elFb {
    right: 100px;
    top: 6px;
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elFb {
    right: 100px;
    top: 6px;
    width: 35px;
    height: 35px;
  }
}

.ContributorsScreen .elButtonLearnMore {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.ContributorsScreen .elButtonLearnMore:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    position: fixed;
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 151px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elButtonLearnMore {
    right: 150px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}

.ContributorsScreen .elButtonAboutUs {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.ContributorsScreen .elButtonAboutUs:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    position: fixed;
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
@media (min-width: 568px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 231px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elButtonAboutUs {
    right: 230px;
    top: 6px;
    width: 85px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .screenFgContainer > .foreground > .elLogo {
    position: absolute;
    left: 189px;
    top: 7px;
    width: 121px;
    height: 32px;
  }
@media (min-width: 568px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elLogo {
    left: 50px;
    top: 7px;
    width: 121px;
    height: 32px;
  }
}
@media (min-width: 768px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elLogo {
    left: 188px;
    top: 7px;
    width: 120px;
    height: 32px;
  }
}
@media (min-width: 1024px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elLogo {
    left: 188px;
    top: 7px;
    width: 120px;
    height: 32px;
  }
}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    position: absolute;
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
@media (min-width: 568px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 189px;
    top: 0px;
    width: 163px;
    height: 52px;
  }
}
@media (min-width: 768px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}
@media (min-width: 1024px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elLogoznesthorizwhite {
    left: 188px;
    top: 0px;
    width: 162px;
    height: 51px;
  }
}


/* Narrowest size for this element's responsive layout */
  .ContributorsScreen > .screenFgContainer > .foreground > .elBeta {
    position: absolute;
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
@media (min-width: 568px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elBeta {
    left: 362px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 768px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}
@media (min-width: 1024px) {
  .ContributorsScreen > .screenFgContainer > .foreground > .elBeta {
    left: 360px;
    top: -24px;
    width: 39px;
    height: 82px;
  }
}

/* This component has a fixed-size layout */
  .ContributorsList {
    width: 100%; /* This component was designed using a width of 181px */
    height: 100%;
  }


  .ContributorsList > .layoutFlow > .elContributorName {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 236px;
    /* margin-left: 10px; */
    background-color: #ffffff;
  }
  .ContributorsList > .layoutFlow > .elContributorName > * {
  width: 236px;
  }

/* }  */


/* Narrowest size for this element's responsive layout */
  .LoginMain2Screen > .layoutFlow > .flowRow > .elContinueWoLogin {
    position: absolute;
    margin-top: 6px;
    width: 226px;
  }
  .LoginMain2Screen > .layoutFlow > .flowRow > .elContinueWoLogin > * {
  width: 226px;
  height: 34px;
  }

  .flowRow_LoginMain2Screen_elContinueWoLogin_376818140 {
  }

@media (min-width: 568px) {
  .LoginMain2Screen > .layoutFlow > .flowRow > .elContinueWoLogin {
    position: absolute;
    margin-top: -0px;
    width: 226px;
  }
  .LoginMain2Screen > .layoutFlow > .flowRow > .elContinueWoLogin > * {
  width: 226px;
  height: 34px;
  }

  .flowRow_LoginMain2Screen_elContinueWoLogin_376818140 {
  }

}
@media (min-width: 768px) {
  .LoginMain2Screen > .layoutFlow > .flowRow > .elContinueWoLogin {
    position: absolute;
    margin-top: 38px;
    width: 225px;
  }
  .LoginMain2Screen > .layoutFlow > .flowRow > .elContinueWoLogin > * {
  width: 225px;
  height: 34px;
  }

  .flowRow_LoginMain2Screen_elContinueWoLogin_376818140 {
  }

}
@media (min-width: 1024px) {
  .LoginMain2Screen > .layoutFlow > .flowRow > .elContinueWoLogin {
    position: absolute;
    margin-top: 20px;
    width: 225px;
  }
  .LoginMain2Screen > .layoutFlow > .flowRow > .elContinueWoLogin > * {
  width: 225px;
  height: 34px;
  }

  .flowRow_LoginMain2Screen_elContinueWoLogin_376818140 {
  }

}


/* Narrowest size for this element's responsive layout */
  .LoginMain2Screen > .layoutFlow > .flowRow > .elTextContinueWOlogin {
    position: absolute;
    top: -26px;
    width: 274px;
  }
  .LoginMain2Screen > .layoutFlow > .flowRow > .elTextContinueWOlogin > * {
  width: 274px;
  }

@media (min-width: 568px) {
  .LoginMain2Screen > .layoutFlow > .flowRow > .elTextContinueWOlogin {
    position: absolute;
    top: -26px;
    width: 274px;
  }
  .LoginMain2Screen > .layoutFlow > .flowRow > .elTextContinueWOlogin > * {
  width: 274px;
  }

}
@media (min-width: 768px) {
  .LoginMain2Screen > .layoutFlow > .flowRow > .elTextContinueWOlogin {
    position: absolute;
    top: -27px;
    width: 273px;
  }
  .LoginMain2Screen > .layoutFlow > .flowRow > .elTextContinueWOlogin > * {
  width: 273px;
  }

}
@media (min-width: 1024px) {
  .LoginMain2Screen > .layoutFlow > .flowRow > .elTextContinueWOlogin {
    position: absolute;
    top: -26px;
    width: 272px;
  }
  .LoginMain2Screen > .layoutFlow > .flowRow > .elTextContinueWOlogin > * {
  width: 272px;
  }

}


/* Narrowest size for this element's responsive layout */
  .LoginMain2Screen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    top: -4px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginMain2Screen > .layoutFlow > .flowRow > .elCard > * {
    width: 100%;
  height: 307px;
  }

@media (min-width: 568px) {
  .LoginMain2Screen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    top: -4px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginMain2Screen > .layoutFlow > .flowRow > .elCard > * {
    width: 100%;
  height: 307px;
  }

}
@media (min-width: 768px) {
  .LoginMain2Screen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    top: -4px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginMain2Screen > .layoutFlow > .flowRow > .elCard > * {
    width: 100%;
  height: 305px;
  }

}
@media (min-width: 1024px) {
  .LoginMain2Screen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    top: -4px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginMain2Screen > .layoutFlow > .flowRow > .elCard > * {
    width: 100%;
  height: 305px;
  }

}


/* Narrowest size for this element's responsive layout */
  .LoginMain2Screen > .screenFgContainer > .foreground > .elWikiLivinglogo {
    position: absolute;
    left: 35px;
    top: 0px;
    width: 137px;
    height: 69px;
  }
@media (min-width: 568px) {
  .LoginMain2Screen > .screenFgContainer > .foreground > .elWikiLivinglogo {
    left: 53px;
    top: 59px;
    width: 137px;
    height: 69px;
  }
}
@media (min-width: 768px) {
  .LoginMain2Screen > .screenFgContainer > .foreground > .elWikiLivinglogo {
    left: 35px;
    top: 0px;
    width: 136px;
    height: 68px;
  }
}
@media (min-width: 1024px) {
  .LoginMain2Screen > .screenFgContainer > .foreground > .elWikiLivinglogo {
    left: 35px;
    top: 0px;
    width: 136px;
    height: 68px;
  }
}


/* Narrowest size for this element's responsive layout */



  .Login2HomeScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .Login2HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .Login2HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .Login2HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .Login2HomeScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 130px;
    width: 207px;
    margin-left: 423px;
  }
  .Login2HomeScreen > .layoutFlow > .elFirebaseLogin > * {
  width: 207px;
  height: 48px;
  }

@media (min-width: 568px) {
  .Login2HomeScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 130px;
    width: 207px;
    margin-left: 423px;
  }
  .Login2HomeScreen > .layoutFlow > .elFirebaseLogin > * {
  width: 207px;
  height: 48px;
  }

}
@media (min-width: 768px) {
  .Login2HomeScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 130px;
    width: 206px;
    margin-left: 420px;
  }
  .Login2HomeScreen > .layoutFlow > .elFirebaseLogin > * {
  width: 206px;
  height: 47px;
  }

}
@media (min-width: 1024px) {
  .Login2HomeScreen > .layoutFlow > .elFirebaseLogin {
    position: relative;
    margin-top: 130px;
    width: 206px;
    margin-left: 420px;
  }
  .Login2HomeScreen > .layoutFlow > .elFirebaseLogin > * {
  width: 206px;
  height: 47px;
  }

}

/* Custom css Start All Pages Included in the Websites */

.bg-header{background-color: #395280;}
.navbar-dark .navbar-nav .nav-link{color:#fff}
.display-4,.display-3,.display-5{font-weight:700;color:#395280}
.display-2{font-family: 'Montserrat-Bold';color:#395280}
.pt-15{padding-top: 5rem}
.pt-10{padding-top: 10rem}
.pl-5{padding-left: 5rem}
.pl-3{padding-left: 3rem}
.pl-1{padding-left: 1rem}
.borderless td, .borderless th {
  border: none;
}
iframe {width:520px;height:400px}
.banner{
  height:800px;
  background-image: url('./images/home/splash-desktop.png');
  background-position: center;
  background-size: cover;
}
td.double{width: 27%}
td.single{width:19%}

.TextMain{color:#395280}
.TextSub{color:#333333;}
/* .title{color:#333333;font-family: 'Montserrat-Bold';} */
.title{color:#333333;font-family: 'Montserrat-Bold';margin-top: 0px;}
.title2{color:#333333;font-family: 'Montserrat-Bold';margin-top: -5px;}
.beta{color:#eb5b00;font-family: 'Montserrat-Bold';}
.beta-alert{background-color:#eb5b00;color:white;padding: 10px ;}
.subtitle{color:#707070}
.title-bold{color:#000000}
.footer{
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.footer a{color:grey}
.bg-grey{background-color: #e2e8f0;}
.style_elZipCodeExplore  {
  background-color: white;
  padding: 15px 160px;
  box-sizing: border-box;
  color: #919191;
  text-align: left;
 }
 .style_elEmailCodeExplore  {
  background-color: white;
  padding: 15px 140px;
  box-sizing: border-box;
  color: #919191;
  text-align: left;
 }
 .c  {
  background-color: white;
  padding: 15px 190px;
  box-sizing: border-box;
  color: #919191;
  text-align: left;
 }
 .style_popup  {
  background-color: white;
  padding: 10px 12px;
  box-sizing: border-box;
  color: #919191;
 }
 .style_ZipCodeExplore,.style_ZipCodeExploreHow,.style_ZipCodeExploreCommunity {
  background-color: white;
  padding: 17px 60px;
  box-sizing: border-box;
  color: #919191;
  text-align: left
 }
 .cursorPointer {
   cursor:pointer;
 }
 .popover{
   height:50px;
   margin: 5px;
   font-size: 15px;
  }
 .style_button_popup{
  padding: 10px 10px;
  color: #feffff;
  text-align:center;
  background-color:#315283;
  cursor:pointer;
  pointer-events:auto
 }
 .style_elSearchButton {
  padding: 17px 25px;
  color: #feffff;
  text-align:center;
  background-color:#315283;
  cursor:pointer;
  pointer-events:auto
 }
 .style_elImage {
  width:80px;
  height:80px
  }
  .btn-search{
    background-color: #395280;
    padding: 5px 22px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .btn-signup{
    padding: 5px 16px;
    font-weight: 600;
    font-size: 12px;
  }
  .btn-details{
    text-decoration: underline;
    color:#395280
  }
  .list-details li{
    border-bottom:1px solid #ddd;
    margin-bottom: 20px !important;
  }

  .bottom-border{
    border:2px solid orange;
    width: 5%;
    margin: 0 auto;}
  .haeder-beta{margin:5px}
/* About section */
.About {
  text-align: center;
}

.About-header {
  color: #395280;
  font-family: 'Montserrat-Bold';
}

.About-line {
  border: 2px solid #ff9721;
  border-radius: 10px;
  width: 35px;
}

.About-subheader {
  color: #395280;
  font-family: 'Montserrat-Bold';
}

.About-section {
  display: flex;
  justify-content: center;
}


.About-description {
  font-size: 14px;
  color:#333333
}

.About-video {
  /* height: 420px; */
}
.blue{
  background-color: #4990E2;
  width:30px;
  height:30px;
  border-radius: 50%;
  text-align: center;
  display: table;
}
.yellow{
  background-color: #F8E81C;
  width:30px;
  height:30px;
  border-radius: 50%;
  text-align: center;
  display: table;
}
.orange{
  background-color: #F6A623;
  width:30px;
  height:30px;
  border-radius: 50%;
  text-align: center;
  display: table;
}
.orange-large{
  background-color: #F6A623;
  font-size:24px;
}
.red{
  background-color: #D63A4E;
  width:30px;
  height:30px;
  border-radius: 50%;
  text-align: center;
  display: table;
}
.span-light{
  color:white;
  font-weight: 600;
  vertical-align: middle;
  display: table-cell;
}
.space{margin-top:70vh}
/* .text-right{text-align: right;} */
.text-right{text-align: right; padding-right: 120px;}
.push-right{float: right;
  margin-right: 20px;}
/* Contributors section */

.Contributors-header {
  color: #395280;
  font-family: 'Montserrat-Bold';
}

.Contributors-text {
  font-size: 0.8rem;
}

.Contributors-donation-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/* Donation section */

.Donation-level {
  background-color: #395280;
  height: 110px;
  width: 110px;
  border-radius: 50%;
}

.Donation-text {
  padding: 32px 5px;
}

.Donation-title {
  color: #fff;
  font-family: 'Montserrat-Bold';
  margin: 0;
  text-align: center;
  vertical-align: middle;
}

.Donation-amount {
  color: #fff;
  margin: 0;
  font-size: 13px;
}

/* Letter section */

.Letter-container {
  height: auto;
  width: 50%;
}

.Letter-header {
  color: #395280;
  font-family: 'Montserrat-Bold';
}

.Letter-content {
  width: 80%;
}

.Letter-content p {
  line-height: 2;
  text-align: left;
  font-size: 15px;
}

/* Leadership section */
.Leader-header {
  color: #395280;
  font-family: 'Montserrat-Bold';
}

.Leader-container {
  width: 700px;
  height: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.Leader-single {
  margin: 15px 50px;
}

.Leader-img img {
  height: 125px;
  width: 125px;
}

.Leader-name {
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.Leader-title {
  font-size: 1rem;
  font-weight: 500;
  color: grey;
}

.elImageIL{
  margin:5px
 }
/* Screen Size 992 Min */
@media (min-width: 1200px)
{
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1rem
}
.navbar-expand-lg .navbar-nav {
  padding-right: 8rem
  }
}

/* Screen Size 1024 Max */

@media screen and (min-device-width: 1024px) and (max-device-width: 1199px)
{
  .pt-15,.mt-15,.pt-10{padding-top: 0.5rem;}
  .pt-5,.mt-5{padding-top: 0.1rem;}
  .pb-5,.mb-5{padding-bottom: 0.1rem;}

  .style_elEmailCodeExplore  {
    background-color: white;
    padding: 15px 90px !important;
    box-sizing: border-box;
    color: #919191;
    text-align: left
   }
  .style_elZipCodeExplore  {
    background-color: white;
    padding: 15px 110px;
    box-sizing: border-box;
    color: #919191;
    text-align: left
   }


   .style_ZipCodeExplore  {
    background-color: white;
    padding: 17px 65px;
    box-sizing: border-box;
    color: #919191;
    text-align: left
   }
   .style_ZipCodeExploreCommunity{
    background-color: white;
    padding: 17px 100px;
    box-sizing: border-box;
    color: #919191;
    text-align: left
   }
   iframe {
    width: 400px;
    height: 350px;
   }
   .h2, h2 {font-size: 22px;}
   .display-2{font-size:56px}
   .h4, h4{font-size: 19px;}
}

/* Screen Size 1024 Max */




/* Screen Size 768 Max */


@media screen and (min-device-width: 768px) and (max-device-width: 1023px)
{
  .pt-15,.mt-15,.pt-10{padding-top: 2rem !important;}
  .pt-5,.mt-5{padding-top: 2rem !important;}
  .pb-5,.mb-5{padding-bottom: 3rem !important;}
  .beta-alert{font-size: 18px;}

  .style_elZipCodeExplore  {
    background-color: white;
    padding: 15px 230px;
    box-sizing: border-box;
    color: #919191;
    text-align: left
   }
   .style_elEmailCodeExplore  {
    background-color: white;
    padding: 15px 180px;
    box-sizing: border-box;
    color: #919191;
    text-align: left
   }
   .style_ZipCodeExplore  {
    background-color: white;
    padding: 17px 230px;
    box-sizing: border-box;
    color: #919191;
    text-align: left
   }
   .h2, h2 {font-size: 26px;}
   .display-2{font-size:52px}
   .h4, h4{font-size: 25px;}
   iframe {width:650px;height:400px}
   .col-md-10 {width:90%}

}

/* Screen Size 568 Max */


@media screen and (min-device-width: 568px) and (max-device-width: 767px)
  {
  .pt-15,.mt-15,.pt-10{padding-top: 0.5rem;}
  .pt-5,.mt-5{padding-top: 0.1rem;}
  .pb-5,.mb-5{padding-bottom: 0.1rem;}
  iframe {width:535px;height:400px}

  .style_elZipCodeExplore  {
    background-color: white;
    padding: 15px 152px;
    box-sizing: border-box;
    color: #919191;
    text-align: left;
   }

   .style_elEmailCodeExplore  {
    background-color: white;
    padding: 15px 132px;
    box-sizing: border-box;
    color: #919191;
    text-align: left;
   }
   .style_ZipCodeExplore  {
    background-color: white;
    padding: 17px 152px;
    box-sizing: border-box;
    color: #919191;
    text-align: left
   }
   .h2, h2 {font-size: 30px;}
   .display-2{font-size:50px}
   .h4, h4{font-size: 15px;}

}

/* Screen Size 414 Max */


@media screen and (min-device-width: 410px) and (max-device-width: 567px)
{
  .pt-15,.mt-15,.pt-10{padding-top: 0.5rem;}
  .pt-5,.mt-5{padding-top: 0.1rem;}
  .pb-5,.mb-5{padding-bottom: 0.1rem;}
  .beta-alert{font-size: 15px;}

  .style_elZipCodeExplore  {
    background-color: white;
    padding: 15px 76px;
    box-sizing: border-box;
    color: #919191;
    text-align: left;
   }
   .style_elEmailCodeExplore  {
    background-color: white;
    padding: 15px 52px;
    box-sizing: border-box;
    color: #919191;
    text-align: left
   }
   .style_ZipCodeExplore  {
    background-color: white;
    padding: 17px 85px;
    box-sizing: border-box;
    color: #919191;
    text-align: left
   }
   .style_ZipCodeExploreHow  {
    background-color: white;
    padding: 17px 60px;
    box-sizing: border-box;
    color: #919191;
    text-align: left
   }
   .h2, h2 {font-size: 22px;}
   .display-2{font-size:42px}
   .h4, h4{font-size: 14px;}
   .Donation-level {
    background-color: #395280;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    }
   .Donation-text{padding:55px 10px}
   iframe {width:390px !important;height:300px !important}
   .Letter-container{height:auto;width: 100%}

   .image-hide{display:none}
   .padding-hide{padding-left:10px ;padding-right:10px ;}
   .border-hide{border-top: 0 !important;}
   .span-table{display: contents !important;font-size: 10px !important;}
   .table-hide{display: none;}
   .text-height{height:100px !important;font-size: 10px !important;width:29% !important}

}

/* Screen Size below 414 */

@media screen and (min-device-width: 360px) and (max-device-width: 409px)
{
  .pt-15,.mt-15,.pt-10{padding-top: 0.5rem;}
  .pt-5,.mt-5{padding-top: 0.1rem;}
  .pb-5,.mb-5{padding-bottom: 0.1rem;}
  .beta-alert{font-size: 15px;}
  .style_elZipCodeExplore  {
    background-color: white;
    padding: 15px 60px;
    box-sizing: border-box;
    color: #919191;
    text-align: left
   }

   .style_elEmailCodeExplore  {
    background-color: white;
    padding: 15px 25px;
    box-sizing: border-box;
    color: #919191;
    text-align: left
   }
   .style_ZipCodeExplore  {
    background-color: white;
    padding: 17px 85px;
    box-sizing: border-box;
    color: #919191;
    text-align: left
   }
   .style_ZipCodeExploreHow  {
    background-color: white;
    padding: 17px 60px;
    box-sizing: border-box;
    color: #919191;
    text-align: left
   }
   .h2, h2 {font-size: 22px;}
   .display-2{font-size:42px}
   .h4, h4{font-size: 14px;}
   .Donation-level {
    background-color: #395280;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    }
   .Donation-text{padding:55px 10px}
   iframe {width:350px ;height:280px }
   .Letter-container{height:auto;width: 100%}

   .image-hide{display:none}
   .padding-hide{padding-left:10px ;padding-right:10px ;}
   .border-hide{border-top: 0 !important;}
   .span-table{display: contents !important;font-size: 10px !important;}
   .table-hide{display: none;}
   .text-height{height:75px !important;font-size: 10px !important;width:29% !important}

}
.border-bottom {
  border-bottom: 2px solid #212529!important;
}
.upload{
  background-color: #395280;
  border: none;
  color: white;
  font-size: 16px;
  padding: 10px;
  opacity: 0.5;
}
.editprice{
  border:1px solid  #395280;
  color: #395280;
  font-size: 12px;
  padding: 6px 12px;
  background-color: #FFFFFF;
}
.submit{
  border:none;
  color: #FFFFFF;
  font-size: 10px;
  padding: 6px 12px;
  background-color: #395280;
  margin:2px
}
.input-field{
  width: 80px;
  margin:2px
}
.table-space{
  justify-content: space-between;
  border-top: solid;
}
.text-height{
  height:50px;
  font-size: 13px;
  line-height: 1.5;
  border-bottom: 1px solid #CCCCCC;
  width:25%;
}
.text-heights{height:25px}
.span-table{display: none;font-size: 12px;}
.padding-hide{padding: 0;}
.span-username{color:#8a8a8b}
.spacer {
   padding-top: 40px;
   padding-bottom: ""
}
.coloumns{
width:100%
}
#lightyellow{
  background-color: #FEF6E3;
  padding: 5px;
}
.checkbox{
  margin:3px;
  width: 20px;
  height: 20px;
}
